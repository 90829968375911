import { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';

import PaySchedule from '../menu-components/paySchedule'
import AllEmployees from '../menu-components/all-employees'
import FlexiblePayment from '../menu-components/flexibelPayment'
import FlexibleSetup from '../menu-components/flexible-setup'
import LeaveAttend from '../menu-components/leave-attend-payroll'
import ProcessPayroll from '../menu-components/payroll-processing'
import RolesAndPermissions from '../menu-components/roles-and-permissions'
import SalaryChanges from '../menu-components/salary-changes'
import PayrollExpenses from '../menu-components/payroll-expenses'
import HistoricalData from '../menu-components/historical-data'
import Insights from '../menu-components/insights'
import Compliance from '../menu-components/compliance'
import UpTodate from '../menu-components/up-to-date'
import ExportFiles from '../menu-components/exportable-files'
import {ReactComponent as SlideIcon} from '../../../assets/logos/prod-slide-icon.svg'
import {ReactComponent as LeftArrow} from '../../../assets/logos/hr-left-arrow.svg'

import './index.scss';


const menu = [
    {id:0, name:"Salary Structure", salaryStruct:[{id:"1", head:<p className='m-0'>Customizable <span className='col-secondary'>Payroll</span> Schedules</p>, title:"Set unique pay dates for different employee groups effortlessly.", widget:PaySchedule}, {id:"2", head:<p className='m-0'>Flexible <span className='col-secondary'>Payment</span> Frequencies</p>, title:"Easily manage both monthly and weekly pay cycles within one system.", widget:FlexiblePayment}, {id:"3", head:<p className='m-0'>Comprehensive Support for <span className='col-secondary'>All Employee</span> Types</p>, title:"Handle payroll seamlessly for full-time, part-time, contract workers, and interns.", widget:AllEmployees},]},
    {id:1, name:"Statutory Compliance", salaryStruct:[{id:"1", head:<p className='m-0'>Automated <span className='col-secondary'>Compliance</span></p>, title:"Automate PF, ESI, LWF, and PT processes so you can focus on your people.", widget:Compliance}, {id:"2", head:<p className='m-0'>Always <span className='col-secondary'>Up-to-Date</span></p>, title:"Stay compliant with the latest statutory changes automatically.", widget:UpTodate}, {id:"3", head:<p className='m-0'>Flexible <span className='col-secondary'>Setup</span></p>, title:"Customize settings to ensure compliance across multiple establishment IDs effortlessly.", widget:FlexibleSetup}, {id:"4", head:<p className='m-0'>Exportable <span className='col-secondary'>Files</span></p>, title:"Easily generate and upload files directly to compliance portals for smooth processing.", widget:ExportFiles},]},
    {id:2, name:"Process Payroll", salaryStruct:[{id:"1", head:<p className='m-0'>Easy <span className='col-secondary'>Payroll</span> Processing</p>,title:"Complete payroll in under an hour with our fast, intuitive system for hassle-free management.", widget:ProcessPayroll}, {id:"2", head:<p className='m-0'>Integrated <span className='col-secondary'>Leave, Attendance,</span> and Payroll</p>, title:"Combine leave, attendance, and payroll in one system with real-time syncing and automated calculations.", widget:LeaveAttend}, {id:"3", head:<p className='m-0'>Roles and <span className='col-secondary'>Payroll </span>Permissions</p>, title:"Secure payroll processing with customizable roles and access controls.", widget:RolesAndPermissions},{id:"4", head:<p className='m-0'>Salary <span className='col-secondary'>Changes & ESS</span></p>, title:"Enable employees to view payslips, track salary history, and manage appraisals via a simple self-service portal.", widget:SalaryChanges}]},
    {id:3, name:"Analytics and Reports", salaryStruct:[{id:"1", head:<p className='m-0'>Manage Payroll <span className='col-secondary'>Expenses</span> Processing</p>, title:"Clarify cash needs and track employee deductions before payroll processing.", widget:PayrollExpenses}, {id:"2", head:<p className='m-0'>Access <span className='col-secondary'> Historical Data</span></p>, title:"Review payroll data from previous months at any time.", widget:HistoricalData}, {id:"3", head:<p className='m-0'>Department-Wise <span className='col-secondary'>Insights</span></p>, title:"Analyze payroll summaries and analytics by department for informed decisions.", widget:Insights}, ]},
] 

const SalaryStructure = () => {

    const [activeMenu, setActiveMenu] = useState(0)

    const ref = useRef<(HTMLDivElement | null)[]>([]);

    const location = useLocation(); 
    const navigate = useNavigate();

    useEffect(() => {
    if (location.hash) {
        const elementId = location.hash.replace('#', '');
        const element = document.getElementById(elementId);
        if (element) {
        element.scrollIntoView({ behavior: 'smooth' }); 
        }
        navigate('/payroll', { replace: true });
    }
    }, [location,navigate]); 

    useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                const visibleSectionId = entry.target.id;
                const activeIndex = menu.findIndex(
                  (each: any) => `section${each.id}` === visibleSectionId
                );
                setActiveMenu(activeIndex);
              }
            });
          },
          {
            threshold: 0.25,
          }
        );
    
        ref.current.forEach((section) => {
          if (section) {
            observer.observe(section);
          }
        });
    
        return () => {
          ref.current.forEach((section) => {
            if (section) {
              observer.unobserve(section);
            }
          });
        };
      }, []);

      const scrollToView = (id:number, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          e.preventDefault(); 
            setActiveMenu(id);  
            const element = document.getElementById(`section${id}`); 
            if (element) 
              {
                element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest'});
              }
      }


    return(
        <>
           <Helmet>
              <meta name="description" content="Process payroll effortlessly with a secure, automated system for accurate, timely payments, flexible salary structures, and real-time insights. Stay compliant with legal requirements, tax filings, and labor laws" />
              <meta name="keywords" content="salary structuring, employee pay grades, payroll scheduling, customized salary plans, HR compensation management,: Gain deep payroll insights with real-time analytics, detailed reports, and smarter financial planning.payroll compliance, statutory payroll automation, tax deduction software, HR labor law compliance, automated tax filings" />
            </Helmet>
        <div className='d-flex flex-column justify-content-center align-items-center menu-components-wrapper'>
            <div className='d-flex flex-column justify-content-center align-items-center sticky-menu-bar'>
                <div className='d-flex flex-row justify-content-start align-items-center payroll-menu'>
                    <div className='d-flex flex-row justify-content-between align-items-center lexend-deca font-weight-3 text-14 payroll-nav'>
                        {menu.map((each:any) => (
                            <div key={each.id} className='payroll-menu-items'>
                                <a style={{ color: activeMenu === each.id ? "#F45600" : "#000000" , textDecoration:'none'}} href={`#section${each.id}`} onClick={(e)=>{ scrollToView(each.id, e) }}>{each.name}</a>
                            </div>
                        ))}
                        <div className='mob-payroll-nav'>
                            <a style={{color:"#F45600", textDecoration:"none"}}>{menu[activeMenu].name}</a>
                        </div>
                    </div>
                </div>
            </div>
            <motion.div className='d-flex flex-column justify-content-center align-items-center payroll-menu-data'>
                {menu.map((each:any,index:number) => (
                    <>

                    <motion.div 
                    initial={{opacity:0 }} // Start with a rotated, hidden element
                    whileInView={{opacity:1}} // Flip to normal and fade in
                    transition={{
                      duration: 1.3,
                      type: "spring",
                      damping: 20,
                      stiffness: 120,
                      ease: "easeInOut"
                    }}
                    viewport={{ once:false , amount:0.2}}
                     ref={(el) => (ref.current[index] = el)} id={`section${each.id}`} className='d-flex section-id flex-column justify-content-between align-items-center each-menu'>
                      <LeftArrow style={{rotate:"-90deg"}} className='payroll-left-arrow'/>
                        {each.salaryStruct.map((each:any) => (
                          <>
                            <div className='d-flex flex-row justify-content-center align-items-center payroll-menu-content'>
                                <motion.div key={each.id} className='d-flex flex-row justify-content-between align-items-center pr-top'>
                                    <motion.div className='d-flex flex-row justify-content-start align-items-center sora font-weight-6 mob-payroll-head'><span className='payroll-slideicon'><SlideIcon/></span>{each.head}</motion.div>
                                    <motion.div className='d-flex flex-column justify-content-center align-items-start payroll-menu-title'>
                                        <motion.div className='d-flex flex-row justify-content-start align-items-center sora font-weight-6 payroll-head'><span className='payroll-slideicon'><SlideIcon/></span>{each.head}</motion.div>
                                        <motion.p className='lexend-deca font-weight-3 w-90 pt-3'>{each.title}</motion.p>
                                    </motion.div>
                                    <motion.div className='d-flex flex-row justify-content-end align-items-center payroll-menu-widget'>
                                        <each.widget/>
                                        <div className='orange-right'></div>
                                        <div className='green'></div>
                                        <div className='dotted-oval'></div>
                                        <div className='dotted-oval' style={{rotate:'50deg'}}></div>
                                        <div className='dotted-oval' style={{rotate:'130deg'}}></div>
                                    </motion.div>
                                </motion.div>
                            </div>
                          </>  
                        ))}
                    </motion.div>
                    </>
                ))}
            </motion.div>
        </div>
        </>
    )
}
export default SalaryStructure