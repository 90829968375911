import { useEffect, useState } from "react"
import { TailSpin } from "react-loader-spinner"
import "./index.scss"
import Bussiness from "../business"
import Platform from "../platforms"
import Workforce from "../workforce"
import LeaveManagement from "../leave"
import Faqs from "../faqs"
import RecentBlogs from "../recent-blogs"
import Card from "../card"
import AutoPlay from "../../layout/industry-slide"
import MobMenu from "../../layout/mob-menu"
import { Helmet } from 'react-helmet-async';
import {ReactComponent as Education} from "../../../assets/logos/education.svg"
import {ReactComponent as Healthcare} from "../../../assets/logos/health-care.svg"
import {ReactComponent as Crane} from "../../../assets/logos/crane.svg"
import {ReactComponent as Clean} from "../../../assets/logos/clean.svg"
import {ReactComponent as Bus} from "../../../assets/logos/bus.svg"
import {ReactComponent as Food} from "../../../assets/logos/food.svg"
import {ReactComponent as Media} from "../../../assets/logos/media.svg"
import {ReactComponent as Flight} from "../../../assets/logos/flight.svg"
import {ReactComponent as Shopping} from "../../../assets/logos/shopping.svg"
import {ReactComponent as Financial} from "../../../assets/logos/financial.svg"
import {ReactComponent as Computer} from "../../../assets/logos/computer.svg"

export const data = [
    {id:'field1', icon:<Education className="mr" />, name:'Education'},
    {id:'field2', icon:<Healthcare className="mr"/>, name:'Healthcare'},
    {id:'field3', icon:<Bus className="mr"/>, name:'Automobile'},
    {id:'field4', icon:<Crane className="mr"/>, name:'Manufacturing'},
    {id:'field5', icon:<Food className="mr"/>, name:'Food'},
    {id:'field6', icon:<Clean className="mr"/>, name:'Textiles'},
    {id:'field7', icon:<Computer className="mr"/>, name:'Information\u00A0Technology'},
    {id:'field8', icon:<Financial className="mr"/>, name:'Financial\u00A0Services'},
    {id:'field9', icon:<Shopping className="mr"/>, name:'FMCG'},
    {id:'field10', icon:<Flight className="mr"/>, name:'Transportation/Logistics'},
    {id:'field11', icon:<Media className="mr"/>, name:'Media'},
]

export const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed:0,
    cssEase: "linear",
    centerMode:true,
    pauseOnHover:false,
};


type FaqData = {
    faq:{
        question:string 
        answer:string 
        order: number
    }[]
}

const initialData = {
    faq:[]
}


const Home = () => {

    const [faqs, setFaqs] = useState<FaqData>(initialData);
    const [isLoading, setIsLoading] = useState(true)

    const getFaqs = async() => {
        setIsLoading(true)
        const url = `${process.env.REACT_APP_API_URL}/faq?page=home`;
        const options = {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            }
        }

        try{
            const response = await fetch(url, options)
            if(response.ok){
                setIsLoading(false)
                const faqs = await response.json()
                setFaqs(faqs.data)
            }
        }
        catch(error:any){
            console.error(`error in fetching faqs: ${error.message}`)
        }
    }



    useEffect(() => {
        getFaqs()
    }, [])

    return(
        <>
            <Helmet>
                <title>Walkzero: India’s Unified HR Platform | Payroll Software </title>
            </Helmet>
            <Bussiness />
            <AutoPlay data={data} settings={settings} />
            <Platform />
            <Workforce />
            <LeaveManagement />
            {isLoading? <div className="align-self-center m-t-100 m-b-100"><TailSpin  visible={true} height="30" width="30" color="#f45600" ariaLabel="tail-spin-loading" radius="1" /></div>:<Faqs faqs={faqs} setFaqs={setFaqs} />}
            <RecentBlogs />
            <Card />
        </>
    )
}

export default Home