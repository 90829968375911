
import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {useAnimation, motion} from "framer-motion"
import { useInView } from "react-intersection-observer";
import { TailSpin } from "react-loader-spinner";
import { TextField, Button, Checkbox, FormControlLabel, Box, Avatar ,IconButton,Rating} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import imageCompression from 'browser-image-compression';
import CameraAltIcon from '@mui/icons-material/CameraAlt'; 
import { BaseBtn } from "../../../styled-components"
import { container2, child2  } from "../../../variants"
import {ReactComponent as Left} from "../../../assets/logos/logo-left.svg"
import {ReactComponent as TopW} from "../../../assets/logos/top-w-curve.svg"
import {ReactComponent as BottomW} from "../../../assets/logos/bottom-w-curve.svg"
import './index.scss'
import { MenuContext } from '../../../app';


interface FormErrors {
  customerName?: string;
  companyName?: string;
  position?: string;
  feedback?: string;
  rating?:string;
}


const TestimonialForm = () => {
    const params = useParams(); 
    const [isLoading, setIsLoading] = useState(false);
    const [res, setResponse] = useState("");

    const [formData, setFormData] = useState({
      profilePic: null as any,
      customerName: '',
      companyName: '',
      position: '',
      feedback: '',
      rating: 0
  });
  const [errors, setErrors] = useState<FormErrors>({
    customerName: '',
    companyName: '',
    position: '',
    feedback: '',
    rating:''
});

    const unique_link =params.uniqueLink
    console.log(unique_link,"link")

    const url= `${process.env.REACT_APP_API_URL}/submit-testimonial/${unique_link}`


    const onDrop = useCallback(async (acceptedFiles:any) => {
        const file = acceptedFiles[0];

        if (!file) {
            console.error('No file selected.');
            return;
        }

        const options = {
            maxSizeMB: 1, 
            maxWidthOrHeight: 800, 
            useWebWorker: true 
        };

        try {
            const compressedFile = await imageCompression(file, options);
            // const compressedFileURL = URL.createObjectURL(compressedFile);
            setFormData({ ...formData, profilePic: compressedFile });
        } catch (error) {
            console.error('Error compressing image:', error);
        }
    }, [formData]);

    useEffect(() => {
        const fetchTestimonialData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/submit-testimonial/${unique_link}`);
                if (!response.ok) {
                    console.error('Failed to fetch testimonial data');
                    return;
                }
    
                const data = await response.json();
                console.log(data,"data from frontend")
    
                setFormData((prevData) => ({
                    ...prevData,
                    customerName: data.customer_name || '',
                    companyName: data.company_name || '',
                    position: data.position || '',
                    feedback: data.feedback || '',
                    rating: data.rating || 0,
                    profilePic:data.profile_pic || '',
                }));
            } catch (error) {
                console.error('Error fetching testimonial data:', error);
            }
        };
    
        if (unique_link) {
            fetchTestimonialData();
        }
    }, [unique_link]);
    
    const ctrls = useAnimation();
        
    const { isOn } = useContext(MenuContext)

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: false,
      });

    useEffect(() => {
        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView])


    const child2w = {
        visible: {
          x:0,
          y:0,
          rotate:0,
          transition: {
            duration: 1.5,
            type:'tween'
          }
        },
        hidden: {
          x:-1700,
          y:350,
          rotate:90,
        }
      }
      const Top = {
        visible: {
          x:0,
          y:0,
          transition: {
            duration: 1,
            delay: 1,
            type:'tween'
          }
        },
        hidden: {
          x:0,
          y:-500,
        }
      }
    
      const Bottom = {
        visible: {
          x:0,
          y:0,
          transition: {
            duration: 1,
            delay: 1,
            type:'tween'
          }
        },
        hidden: {
          x:0,
          y:200,
        }
      }

      const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
    
        const newErrors: FormErrors = {};
        if (!formData.customerName.trim()) newErrors.customerName = 'Customer Name is required';
        if (!formData.companyName.trim()) newErrors.companyName = 'Company Name is required';
        if (!formData.position.trim()) newErrors.position = 'Position is required';
        if (!formData.feedback.trim()) newErrors.feedback = 'Feedback is required';
        if (formData.rating < 1) newErrors.rating = 'Please rate us';
    
        setErrors(newErrors);
    
  
        if (Object.keys(newErrors).length > 0) {
            console.error('Form contains errors');
            return; 
        }
    
     
        
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('profile_pic', formData.profilePic); 
            formDataToSend.append('customer_name', formData.customerName);
            formDataToSend.append('company_name', formData.companyName);
            formDataToSend.append('position', formData.position);
            formDataToSend.append('feedback', formData.feedback);
            formDataToSend.append('rating', formData.rating.toString());

            const response = await fetch(url, {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'multipart/form-data'
                // },
                body: formDataToSend
            });
            setIsLoading(true)
            const responseData = await response.json();
            setIsLoading(false);

            if (response.ok) {
                console.log('Testimonial submitted successfully!');
                setResponse(responseData.success)
                setFormData({
                    profilePic: null,
                    customerName: '',
                    companyName: '',
                    position: '',
                    feedback: '',
                    rating:0,
                });
            } else {
                console.error(setResponse(responseData.error));
            }
        } catch (error) {
            console.error('Network error or server issue:', error);
            setIsLoading(false)
        }
    };
    
    const handleChange = (e:any) => {
      const { name, value, type, checked, files } = e.target;

      if (type === 'checkbox') {
          setFormData({ ...formData, [name]: checked });
      } else if (type === 'file') {
          const file = files[0];
          const reader = new FileReader();
          reader.onloadend = () => {
              setFormData({ ...formData, profilePic: file});
          };
          reader.readAsDataURL(file);
      } else {
          setFormData({ ...formData, [name]: value });
      }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'image/*': [] }
});
 return (
    <>
    <div>
        {
            res ? (
                <motion.div style={{display:'flex',justifyContent:'center',alignItems:'center'}} ref={ref} variants={container2} initial="hidden" animate={ctrls} id="hrMain" className="hr-excellence d-flex flex-column position-relative bg-col-new-light-green">
                    <div className="custom-shape-divider-bottom-1715923248">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                    </svg>
                </div>
                   <div className='response-container'>
                   <div className="response-message">
                        {res}
                    </div>
                   </div>
                   
                </motion.div>
            ) : (
                <>
                <motion.div ref={ref} variants={container2} initial="hidden" animate={ctrls} id="hrMain" className="hr-excellence d-flex flex-column position-relative bg-col-new-light-green">
                <motion.div  className="product-inner-cont-terms d-flex flex-row justify-content-center align-items-center align-self-center">
                
                    <motion.div style={{height:'400px'}} variants={child2} className="d-flex flex-column justify-content-center align-items-center position-relative second-tc-s mt-20">
                        <motion.div style={{zIndex:1}} initial={{opacity: 0}} animate={{opacity: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 1]}} transition={{duration: 3, delay: 2, ease: 'easeOut', type:"keyframes"}} className="d-flex flex-row justify-content-center align-items-center align-self-center pt-0">
                    <motion.div   className="step-into-card-ts d-flex flex-column position-relative justify-content-center align-items-center">
                 
        
                        <h3  className='product-tandc-heading col-container-font h1-size text-center'>Feedback  <span className='col-secondary'> Form </span> </h3>
                        <h4  className='font font-weight-3  p-size text-center combined-word'>Together, We Grow – Share Your Experience!</h4>
                     </motion.div>
                    </motion.div>
        
        
        
        
                        <div className="position-absolute bgnd-prod z-index-0 rounded-circle">
                        </div>
                        <motion.div variants={child2w} initial="hidden" animate="visible" className="position-absolute p-0 z-index-0">
                            <Left style={{opacity:0.6}} id="Leftw"/>
                        </motion.div>
                        <motion.div variants={Top} initial="hidden" animate="visible" className="position-absolute hr-top-pr">
                            <TopW id='Payroll-top' style={{opacity:0.6}}/>
                        </motion.div>
                        <motion.div variants={Bottom} initial="hidden" animate="visible" className="position-absolute p-0 hr-bottom-pr">
                            <BottomW id='Payroll-bottom' style={{opacity:0.6}}/>
                        </motion.div>
                    </motion.div>
                </motion.div>
                <div className="custom-shape-divider-bottom-1715923248">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                    </svg>
                </div>
            </motion.div>
             <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
             <div className="testimonial-form-container">
             <form onSubmit={handleSubmit} className="testimonial-form">
             <Box className="profile-pic-section" sx={{ textAlign: 'center', position: 'relative' }}>
     
     <Box sx={{ position: 'relative', display: 'inline-block' }}>
         <Avatar
            src={
                typeof formData.profilePic === 'string' 
                    ? formData.profilePic 
                    : formData.profilePic 
                    ? URL.createObjectURL(formData.profilePic)  
                    : ''
            }
             alt="Profile Preview"
             sx={{
                 width: 120,
                 height: 120,
                 bgcolor: '#E0E0E0',
                 margin: 'auto',
             }}
         />
     
         <IconButton
             {...getRootProps()}
             sx={{
                 position: 'absolute',
                 bottom: 0,
                 right: 0,
                 bgcolor: '#F45600',
                 color: 'white',
                 width: 30,
                 height: 30,
                 border: '2px solid white',
                 '&:hover': {
                     bgcolor: '#F45600',  
                     color: 'white' 
                 }
             }}
         >
             <CameraAltIcon fontSize="small" />
             <input {...getInputProps()} />
         </IconButton>
         </Box>
         </Box>
             <TextField
         className={`inputs ${formData.customerName && 'has-value'}`}
         sx={{ mt: 1.5 }}
         id="outlined-basic"
         label="Customer Name"
         name="customerName"
         variant="outlined"
         size="small"
         type="text"
         value={formData.customerName}
         onChange={handleChange}
     
         />
         <div
         className='text-12 font-1 align-self-start m-l-10 col-error msg-height'
     
         >
         {errors.customerName}
         </div>
     
         <TextField
         className={`inputs ${formData.companyName && 'has-value'}`}
         sx={{ mt: 1.5 }}
         label="Company Name"
         name="companyName"
         variant="outlined"
         size="small"
         type="text"
         value={formData.companyName}
         onChange={handleChange}
         // onBlur={validateForm}
         />
         <div
         className='text-12 font-1 align-self-start m-l-10 col-error msg-height'
         >
         {errors.companyName}
         </div>
     
         <TextField
         className={`inputs ${formData.position && 'has-value'}`}
         sx={{ mt: 1.5 }}
         label="Position"
         name="position"
         variant="outlined"
         size="small"
         type="text"
         value={formData.position}
         onChange={handleChange}
         // onBlur={validateForm}
         />
         <div 
         className='text-12 font-1 align-self-start m-l-10 col-error msg-height'
         >
         {errors.position}
         </div>
     
         <TextField
         className={`inputs ${formData.feedback && 'has-value'}`}
         sx={{ mt: 1.5 }}
         label="Feedback"
         name="feedback"
         variant="outlined"
         multiline
         rows={4}
         value={formData.feedback}
         onChange={handleChange}
         // onBlur={validateForm}
         />
         <div
         className='text-12 font-1 align-self-start m-l-10 col-error msg-height'
         >
         {errors.feedback}
         </div>
         <div>
         <p className='font font-weight-3  p-size ml-6'>How would you rate your overall experience with Walkzero’s HR as a Service?</p>
         <Rating
             name="rating"
             value={formData.rating || 0} 
             onChange={(event, newValue) => handleChange({ target: { name: 'rating', value: newValue } })}
             sx={{ color: '#F45600' }}
         />
         <div 
           className='text-12 font-1 align-self-start m-l-10 col-error msg-height'
         >
             {errors.rating}
         </div>
     </div>
     
                 <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                  <BaseBtn isOn={isOn} type="submit" className='btn-4 rounded-9 text-12 font-weight-6 demo-btn w-px-190 align-self-center m-t-15 m-b-10'>Submit Testimonial </BaseBtn>
                 
       
                 
                 </div>
                 {isLoading && <div className="align-self-center"><TailSpin  visible={true} height="30" width="30" color="#f45600" ariaLabel="tail-spin-loading" radius="1" /></div>}
             </form>
         </div>
     
      
         </div>
         </>
            )
        }
   
   

    </div>
 
    </>
  )
}

export default TestimonialForm;