import './index.scss'
import Slider from "react-slick";
import {ReactComponent as Chip} from '../../../assets/logos/chip.svg'
import { CSSProperties } from 'react';

type SlideItemProps = {
    data:{
    id: string 
    name: string}[]
    settings:any
    width:CSSProperties
}

const SlideItem = (props:SlideItemProps) => {

    const {data, settings, width} = props

    return(
        <div style={width} className="slider-container m-t-40 slide-size1 align-self-center mask m-b--3">
            <Slider {...settings}>
            {data.map(each => {
                return <span key={each.id} className='d-flex flex-row justify-content-center align-items-center h-px-35 box-size p-y-15 p-x-20 rounded-8 m-3 text-12 font-1 font-weight-4'><Chip className="m-r-10"/>{each.name}</span>
            })}
            </Slider>
      </div>
    )
}

export default SlideItem
