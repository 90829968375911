import "./index.scss"
import {ReactComponent as Quotes} from "../../../assets/logos/quotes.svg"
import Avatar from '../../../assets/images/avatar.png';
import {useAnimation, motion} from "framer-motion"
import { useInView } from "react-intersection-observer";
import { container, child} from "../../../variants"
import { useEffect, useState } from "react";

const data = [
    {id:"task1", name:"Rajasekaran Arul", img:Avatar, company:"GM / Page Industries", title:"Amazing product with cutting-edge interface and optimized user flow!", content:"Walkzero has revolutionized the way we handle HR tasks, simplifying complexities and significantly reducing time investments. Its user-friendly interface and comprehensive features have made managing our workforce a breeze, resulting in increased productivity and efficiency across the board."},
    // {id:"task2", name:"Rajasekaran Arul", img:Avatar, company:"GM / Page Industries", title:"Amazing product with cutting-edge interface and optimized user flow!", content:"Walkzero has revolutionized the way we handle HR tasks, simplifying complexities and significantly reducing time investments. Its user-friendly interface and comprehensive features have made managing our workforce a breeze, resulting in increased productivity and efficiency across the board."},
]

interface Testimonial_type {
    photo: string | null;
    customer_name: string;
    company_name: string;
    position: string;
    feedback: string;
}

const Testimonial = () => {


    const [testimonials, setTestimonials] = useState([]);
    const [loading, setLoading] = useState(true);


    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: false,
    });
    useEffect(() => {
        const fetchTestimonials = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/publish-testimonial/`);
                if (!response.ok) throw new Error('Failed to fetch testimonials');
                
                const data = await response.json();
                console.log(data,'testimonial data');
                setTestimonials(data);
            } catch (err) {
            console.error(err)
            } finally {
                setLoading(false);
            }
        };
    
        fetchTestimonials();
    }, []);

    useEffect(() => {
        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView])


    return (
        <motion.div ref={ref} variants={container} initial="hidden" animate={ctrls}  className="d-flex flex-column hr-task-cont align-self-center p-t-60 p-b-60">
            <motion.h1 variants={child} className="col-container-font small-font  hide-mb   our-testimonial col-quaternary text-center mr-70">TAILORED PRICING TO SUIT YOUR REQUIREMENTS  </motion.h1>
            <motion.h1 className="col-container-font small-font text-center show-mb col-quaternary"> TAILORED PRICING TO SUIT YOUR REQUIREMENTS   </motion.h1>
            <motion.h1 variants={child} className="col-container-font h2-size  hide-mb    our-testimonial text-center mr-70">Our valuable <span className="col-secondary">testimonials</span>  </motion.h1>
            <motion.h1 className="col-container-font h2-size text-center show-mb">Our valuable  <span className="col-secondary">testimonial</span>  </motion.h1>
            <motion.div className="d-center flex-row flex-wrap hr-avatar-cont m-t-40">
                {testimonials.map((each:Testimonial_type) => {
                    return <motion.div variants={child}  className="inner-hr-task-cont d-center flex-column">
                        <div className='bg-col-light-orange rounded-21 d-flex flex-row justify-content-center align-self-center m-b--25 avatar-cont'>
                        {each.photo && (
                            <img 
    className='z-index-1 m-t--20' 
    src={each.photo} 
    alt="avatar" 
/>

)}

                        </div>
                        <div className='p-3 rounded-15 bg-col-white d-flex flex-column w-95 align-self-center task-border'>
                            <p className='text-12 m-b-0 text-center'>{each.customer_name}</p>
                            <p className='font font-weight-3 text-12 m-t-0 text-center'>{each.company_name}</p>
                            <h3 className='font font-weight-6 text-12 text-center'>{each.position}</h3>
                            <Quotes className="m-l-20" />
                            <p className='font font-weight-3 text-12 w-85 align-self-center'>{each.feedback}</p>
                            <Quotes style={{rotate:"180deg"}} className="align-self-end m-t--20 m-r-20"/>
                        </div>
                    </motion.div>
                })}
            </motion.div>
        </motion.div>
    )
}

export default  Testimonial;