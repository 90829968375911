    import { Link } from "react-router-dom"
    import { TailSpin } from "react-loader-spinner"
    import { useState, useEffect, useContext } from "react"
    import {useAnimation, motion} from "framer-motion"
    import { useInView } from "react-intersection-observer";
    import { container2, child1, child2 } from "../../../../variants"
    import { Helmet } from 'react-helmet-async'

    import  LandingImage from "../../../../assets/logos/dashboard-hr.svg"
    import {ReactComponent as Left} from "../../../../assets/logos/logo-left.svg"
    import {ReactComponent as TopW} from "../../../../assets/logos/top-w-curve.svg"
    import {ReactComponent as BottomW} from "../../../../assets/logos/bottom-w-curve.svg"
    import {ReactComponent as ButtonLogo} from "../../../../assets/logos/button-logo.svg"
    import AutoPlay from '../../../layout/industry-slide'
    import {data, settings} from "../../../landing-page/home"
    import HrMerits from '../hr-hub-merits'
    import Hrslide from '../hr-slide'
    import SecureHub from "../secure-hub"
    import Faqs from "../../../landing-page/faqs"
    import HrTasks from "../../hr-hub/hr-tasks"
    import Modules from "../modules"
    import SlideItem from "../../../landing-page/slide-items"
    import { settings1, settings2 } from "../../../landing-page/leave"
    import Card from "../../../landing-page/card"
    import './index.scss'
    import { BaseBtn } from "../../../../styled-components";
    import { MenuContext } from "../../../../app";

    type FaqData = {
        faq:{
            question:string 
            answer:string 
            order: number
        }[]
    }

    const initialData = {
        faq:[]
    }

    const hrKeywords1 =[
        {id:"slide0", name:"Employee database management"},
        {id:"slide1", name:"Organizational structure visualization"},
        {id:"slide2", name:"Organization policies implementation"},
        {id:"slide3", name:"Bulk employee onboarding"},
        {id:"slide4", name:"Employee management platform"},
        {id:"slide5", name:"Streamlined HR operations"},
        {id:"slide6", name:"HR software solutions"},
        {id:"slide7", name:"Dynamic organization charts"},
        {id:"slide8", name:"Policy management software"},
        {id:"slide9", name:"Efficient employee onboarding"},
        {id:"slide10", name:"Centralized employee information"},
        {id:"slide11", name:"HR process automation"},
    ]

    const hrKeywords2 =[
        {id:"slide0", name:"Simplified employee management"},
        {id:"slide1", name:"Employee record keeping"},
        {id:"slide2", name:"Employee data security"},
        {id:"slide3", name:"HR analytics dashboard"},
        {id:"slide4", name:"Employee profile management"},
        {id:"slide5", name:"Organizational hierarchy visualization"},
        {id:"slide6", name:"Compliance management tools"},
        {id:"slide7", name:"Employee lifecycle management"},
        {id:"slide8", name:"Customizable HR policies"},
        {id:"slide9", name:"Employee productivity tracking"},
        {id:"slide10", name:"Bulk data exports"},
    ]

    const HrHub = () => {

        const [faqs, setFaqs] = useState<FaqData>(initialData);
        const [isLoading, setIsLoading] = useState(true)

        const {isOn} = useContext(MenuContext)

        const getFaqs = async() => {
            setIsLoading(true)
            const url = `${process.env.REACT_APP_API_URL}/faq?page=Hr hub`;
            const options = {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                }
            }

            try{
                const response = await fetch(url, options)
                if(response.ok){
                    setIsLoading(false)
                    const faqs = await response.json()
                    setFaqs(faqs.data)
                }
            }
            catch(error:any){
                console.error(`error in fetching faqs: ${error.message}`)
            }
        }

        const ctrls = useAnimation();

        const { ref, inView } = useInView({
            threshold: 0.2,
            triggerOnce: false,
        });

        useEffect(() => {
            getFaqs()

            if (inView) {
                ctrls.start("visible");
            }
            if (!inView) {
                ctrls.start("hidden");
            }
        }, [ctrls, inView])

        const child2w = {
            visible: {
            x:0,
            y:0,
            rotate:0,
            transition: {
                duration: 1.5,
                type:'tween'
            }
            },
            hidden: {
            x:-1700,
            y:350,
            rotate:90,
            }
        }
        const Top = {
            visible: {
            x:0,
            y:0,
            transition: {
                duration: 1,
                delay: 1,
                type:'tween'
            }
            },
            hidden: {
            x:0,
            y:-500,
            }
        }
        
        const Bottom = {
            visible: {
            x:0,
            y:0,
            transition: {
                duration: 1,
                delay: 1,
                type:'tween'
            }
            },
            hidden: {
            x:0,
            y:200,
            }
        }



    return (
        <>
        <Helmet>
            <title>HR HUB | Walkzero Technologies Private Limited</title>
            <meta name="description" content="Streamline and strengthen your HR operations with a single, centralized platform for efficient workforce management." />
            <meta name="keywords" content="HR hub software, centralized HR platform, HR management system, employee data, HR automation" />
        </Helmet>
        <motion.div ref={ref} variants={container2} initial="hidden" animate={ctrls} id="hrMain" className="hr-excellence d-flex flex-column position-relative bg-col-new-light-green">
            <motion.div className="product-inner-cont d-flex flex-row justify-content-between align-items-center align-self-center">
                <motion.div variants={child1} className="step-into-card d-flex flex-column">
                    <p className='text-12 col-quaternary font-weight-5 text-12 mb-1 text-uppercase'>STEP INTO HR EXCELLENCE:WALKZERO LEADING THE WAY!</p>
                    <h3 className='product-heading col-container-font h1-size'>Struggling with HR tasks? Discover how <span className='col-secondary'> Walkzero </span> simplifies it all!</h3>
                    <p className='font font-weight-3 m-b-20 p-size'>Welcome to ultimate HR hub! Manage your team effortlessly with our Employee Database and dynamic organization charts. Ensure compliance and simplify onboarding with bulk employee additions-all in one streamlined platform</p>
                    <div className="m-r-20 position-relative mob-width z-index-1">
                        <Link to="/sign-up" className="decoration">
                        <BaseBtn isOn={isOn} className="position-relative border-0 p-y-10 rounded-10 d-flex flex-row justify-content-center align-items-center w-100 btn-4">
                            <ButtonLogo className="me-1 mb-1" /> 
                            <span className="col-white font-bold text-14 font-weight-700 z-index-0">Sign up for Free</span>
                        </BaseBtn>
                        </Link>
                    </div>
                </motion.div>
                <motion.div variants={child2} className="d-flex flex-column justify-content-center align-items-center position-relative second-s">
                    <motion.img initial={{opacity: 0}} animate={{opacity: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 1]}} transition={{duration: 3, delay: 2, ease: 'easeOut', type:"keyframes"}} src={LandingImage} className='product-img' alt="calendar" />
                    <div className="position-absolute bgnd-prod z-index-0 rounded-circle">
                    </div>
                    <motion.div variants={child2w} initial="hidden" animate="visible" className="position-absolute p-0 z-index-0">
                        <Left style={{opacity:0.6}} id="Leftw"/>
                    </motion.div>
                    <motion.div variants={Top} initial="hidden" animate="visible" className="position-absolute hr-top">
                        <TopW id='Payroll-top' style={{opacity:0.6}}/>
                    </motion.div>
                    <motion.div variants={Bottom} initial="hidden" animate="visible" className="position-absolute p-0 hr-bottom">
                        <BottomW id='Payroll-bottom' style={{opacity:0.6}}/>
                    </motion.div>
                </motion.div>
            </motion.div>
            <div className="custom-shape-divider-bottom-1715923248">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                </svg>
            </div>
        </motion.div>
        <AutoPlay data={data} settings={settings} />
        <Hrslide />
        <HrMerits />
        <div className="position-relative d-block">
            <SecureHub />
        </div>
        {isLoading? <div className="align-self-center m-t-100 m-b-100"><TailSpin  visible={true} height="30" width="30" color="#f45600" ariaLabel="tail-spin-loading" radius="1" /></div>:<Faqs faqs={faqs} setFaqs={setFaqs} />}
        <HrTasks />
        <Modules />
        <div className="d-flex flex-column p-b-100 hr-product-slide">
            <SlideItem data={hrKeywords1} settings={settings1} width={{width:"70%"}}/>
            <SlideItem data={hrKeywords2} settings={settings2} width={{width:"50%"}}/>
        </div>
        <Card />
        </>
    )
    }

    export default HrHub