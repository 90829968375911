import './index.scss'
import { useEffect } from 'react'
import {TailSpin} from 'react-loader-spinner'
import { useContext } from 'react'
import { MenuContext } from '../../../app'
import { Link } from 'react-router-dom'

export interface BlogItem {
    id: string;
    title: string;
    short_description: string;
    photo: string;
    slug: string;
    category: string[]
}
  
const AllBlogs = () => {

    const {blogList, setBlogList, isLoading, setIsLoading} = useContext(MenuContext)

    const getBlogList = async(url:string) => {
        setIsLoading(true)

        const options = {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            }
        }  

        try{
            const response = await fetch(url, options)
            if(response.ok){
                setIsLoading(false)
                const blogs = await response.json()
                setBlogList(blogs)
                setBlogList((prevBlogList:any) => ({
                    ...prevBlogList,
                    categories: prevBlogList.categories.map((category:any) => {
                    if (category.category__name === "All") {
                        return { ...category, category__is_clicked: !category.category__is_clicked };
                    } else {
                        return category;
                    }
                    })
                }));
            }
        }
        catch(error:any){
            console.error(`error in fetching blogs: ${error.message}`)
        }
    }

    useEffect(() => {
        const url = `${process.env.REACT_APP_API_URL}/blogs`;
        getBlogList(url)

    }, [])


    return(
        <div className='all-blogs-cont d-flex flex-column align-self-center p-b-40'>
            <h1 className="col-container-font h2-size m-b-20">All <span className='col-secondary'>Blogs</span></h1>
            {!isLoading && <div className='d-flex flex-row justify-content-start flex-wrap'>
                {blogList.results.map((each:BlogItem) => {
                    return <Link to={`/blog/${each.slug}`} key={each.id} className='d-flex flex-column justify-content-start align-items-start blog-items-cont m-r-35 m-b-40 decoration'>
                                <img className='w-100 m-l--15' src={each.photo} alt='blog' />
                                <h3 className='text-16 font font-weight-6'>{each.title}</h3>
                                <p className='text-12 font font-weight-3'>{each.short_description}</p>
                                <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                                        <p className='font text-12 font-weight-3 p-size'>2 mins to Read..</p>
                                        <span className='font-1 text-12 font-weight-6 col-secondary decoration'>Read More...</span>
                                </div>
                            </Link>
                })}
            </div>}
            {isLoading && <div className="align-self-center m-t-100 m-b-100"><TailSpin  visible={true} height="30" width="30" color="#f45600" ariaLabel="tail-spin-loading" radius="1" /></div>}
            <div className='d-center flex-row align-self-center'>
                {blogList.previous && <button type="button" onClick={() => getBlogList(blogList.previous)} className='btn-4 bg-col-secondary rounded-5 col-white prev-btn text-12 m-r-20'>Prev</button>}
                {blogList.next && <button type="button" onClick={() => getBlogList(blogList.next)} className='btn-4 bg-col-secondary rounded-5 col-white prev-btn text-12'>Next</button>}
            </div>
        </div>
    )
}

export default AllBlogs