import "./index.scss"

import { TextField } from "@mui/material"
import { useState, useContext } from "react";
import {ReactComponent as Newsletter} from "../../../assets/logos/inbox.svg";
import { TailSpin } from "react-loader-spinner";
import Celebration from "../../../assets/gifs/celebration.gif"
import { BaseBtn } from "../../../styled-components";
import { MenuContext } from "../../../app";


const Inbox = () => {

    const [email, setEmail] = useState('');
    const [required, setRequired] = useState(false);
    const [loading, setLoading] = useState(false);
    const [celebrate, setCelebrate] = useState(false);
    const [errorMsg, setErrorMsg] = useState('This field is required');

    const {isOn} = useContext(MenuContext)

    const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const typeEmail = (event:React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }

    const checkEmail = (event:React.FocusEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        if(!pattern.test(inputValue)){
            setRequired(true)
            setErrorMsg('Please enter a valid email')
        }
        if(inputValue === ''){
            setRequired(true)
            setErrorMsg('This field is required')
        }
        if(pattern.test(inputValue)){
            setRequired(false)
            setErrorMsg('')
        }
    }

    const sendMail = async() => {
        const url = `${process.env.REACT_APP_API_URL}/subscribe-blog`;
        const data = {email}
        const options = {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(data)
        }

        try{
            const response = await fetch(url, options)
    
            if(response.ok){
                setLoading(false)
                setCelebrate(true)
                const data = await response.json()
                console.log(data)
            }else{
                throw new Error("failed to book a demo")
            }
        }catch(error:any){
            setLoading(false)
            console.error('Error in sending email:', error.message)
        }

    }

    const submitInbox:React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        if(email !== ''){
            if(pattern.test(email)){
                setLoading(true)
                setRequired(false);
                setErrorMsg('')
                sendMail();
            }
            else{
                setRequired(true)
                setErrorMsg('Please enter a valid email')
            }
        }
        else{
            setRequired(true)
            setErrorMsg('Please enter a valid email')
        }
    }

    return(
        <div className='container m-b--30 z-index-1 yellow-card'>
            <div className='d-flex flex-row justify-content-between align-items-center bg-col-container-bg rounded-21 p-r-30 p-l-30 p-t-70 p-b-70 w-100 footer-card-cont'>
                <div className='inbox-card-1 d-flex flex-column position-relative'>
                    <h3 className='col-container-font font-weight-7 h2-size cent1'>Get the latest <span className='col-secondary'>Blogs from Walkzero</span> in your inbox.</h3>
                    <Newsletter width={30} className="position-absolute top--30 news" />
                </div>
                {!celebrate && <div className='inbox-card-2 d-flex flex-column align-items-center'>
                    <form onSubmit={submitInbox} className="d-center align-items-center inbox-form" noValidate>
                        <TextField required className='inputs inbox-email' error={required?true:false} margin="normal" id="outlined-basic" label={required?errorMsg:"Email address"} variant="outlined" size="small" type="email" value={email} onBlur={checkEmail} onChange={typeEmail} />
                        <BaseBtn isOn={isOn} className="col-white btn-4 subscribe-btn font-1 font-weight-6" type="submit">Subscribe</BaseBtn>
                    </form>
                    {loading && <TailSpin wrapperStyle={{marginTop:'10px'}} visible={true} height="30" width="30" color="#f45600" ariaLabel="tail-spin-loading" radius="1" />}
                </div>}
                {celebrate &&<div className='d-flex flex-column justify-content-center align-items-center inbox-greet-cont'>
                    <div className='d-flex justify-content-between w-100 m-t--40'>
                        <img src={Celebration} alt="gif" />
                        <img src={Celebration} alt="gif" />
                    </div>
                    <h1 className='col-container-font font-weight-6 h1-size m-t--90 m-b-16'>Thank <span className='col-secondary'>you!</span></h1>
                    <p className='text-12 font-1 font-weight-4 new-gray-900 text-center'>Thank you for subscribing! We're thrilled to have you join our community. Stay tuned for the latest updates and exclusive content straight to your inbox.</p>
                </div>}
            </div>
        </div>
    )
}

export default Inbox