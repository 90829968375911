import './index.scss'
import TopBlog from '../top-blog'
import OurCategories from '../our-categories'
import AllBlogs from '../all-blogs'
import BlogsNavbar from '../../layout/blogs-nav'
import Inbox from '../inbox'
import { useContext } from 'react'
import { MenuContext } from '../../../app'
import { Helmet } from 'react-helmet-async'
import MobBlogMenu from '../../layout/mob-menu-blog'

const Blog = () => {

    const props = useContext(MenuContext)
    const {searchInput} = props

    return(
        <>
        <Helmet>
            <title>Blogs | Walkzero Technologies Private Limited</title>               
            <meta name="description" content="Get expert insights on HRMS, payroll management, and workforce strategies from Walkzero’s industry-leading blog." />
            <meta name="keywords" content="HRMS blog, HR trends, payroll compliance, time tracking tips, workforce management insights, best HR practices" />
            <meta property="og:title" content="Blogs | Walkzero Technologies Private Limited" />
            <meta property="og:description" content="Get expert insights on HRMS, payroll management, and workforce strategies from Walkzero’s industry-leading blog." />
            <meta property="og:image" content="https://www.walkzero.com/static/walkzero-w.png" />
            <meta property="og:url" content="https://www.walkzero.com/blogs" />
        </Helmet>
        <BlogsNavbar />
        <div className='d-flex flex-column align-self-center w-100'>
        {searchInput === "" && <TopBlog />}
        </div>
        <OurCategories />
        <AllBlogs />
        <Inbox />
        <MobBlogMenu/>
        </>
    )
}

export default Blog