import { Link } from "react-router-dom"
import { useContext, useEffect } from "react"
import {useAnimation, motion} from "framer-motion"
import Rectangle from "../../assets/images/wz-app.png"
import { Breadcrumbs, Typography } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import { useInView } from "react-intersection-observer";
import { container2, child2 ,child1 } from "../../variants"
import { Helmet } from 'react-helmet-async'
import  LandingImage from "../../assets/logos/dashboard-hr.svg"

import  DashBoard from "../../assets/logos/ess-dashboard.svg"
import  DashBoardLeave from "../../assets/logos/leave-dashboard.svg"
import  DashBoardAttendance from "../../assets/logos/attendance-dashboard.svg"
import  DashBoardLeaveHr from "../../assets/logos/dashboard-hr.svg"
import Card from "../landing-page/card"
import  DashBoardGeo from "../../assets/logos/geo-dashboard.svg"
import {ReactComponent as Left} from "../../assets/logos/logo-left.svg"
import {ReactComponent as TopW} from "../../assets/logos/top-w-curve.svg"
import {ReactComponent as BottomW} from "../../assets/logos/bottom-w-curve.svg"
import {ReactComponent as ButtonLogo} from "../../assets/logos/button-logo.svg"
import './index.scss'
import MobMenu from "../layout/mob-menu";
import { BaseBtn } from "../../styled-components";
import { MenuContext } from "../../app";





const SEO = () => {


const {isOn} = useContext(MenuContext)
    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: false,
      });

    useEffect(() => {
        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView])


    const child2w = {
        visible: {
          x:0,
          y:0,
          rotate:0,
          transition: {
            duration: 1.5,
            type:'tween'
          }
        },
        hidden: {
          x:-1700,
          y:350,
          rotate:90,
        }
      }
      const Top = {
        visible: {
          x:0,
          y:0,
          transition: {
            duration: 1,
            delay: 1,
            type:'tween'
          }
        },
        hidden: {
          x:0,
          y:-500,
        }
      }
    
      const Bottom = {
        visible: {
          x:0,
          y:0,
          transition: {
            duration: 1,
            delay: 1,
            type:'tween'
          }
        },
        hidden: {
          x:0,
          y:200,
        }
      }


  return (
    <>
    <Helmet>
        <title>Best HR software in India | Walkzero Technologies Private Limited</title>
    </Helmet>
    <motion.div ref={ref} variants={container2} initial="hidden" animate={ctrls} id="hrMain" className="hr-excellence d-flex flex-column position-relative bg-col-new-light-green">
        <motion.div className="product-inner-cont d-flex flex-row justify-content-between align-items-center align-self-center">
            <motion.div variants={child1} className="step-into-card d-flex flex-column">
                <h3 className='product-heading col-container-font h1-size'>Walkzero HRMS 24/7 : The Smart, Scalable, and Secure <span className='col-secondary'>HR Solution  </span> for Businesses of All Sizes
                </h3>
                <p className='font font-weight-3 m-b-20 p-size'>Simplify HR, streamline processes, and scale effortlessly with Walkzero HRMS. From payroll to compliance, our all-in-one solution empowers businesses of all sizes with smarter workforce management.</p>
                <div className="m-r-20 position-relative mob-width z-index-1">
                    <Link to="/sign-up" className="decoration">
                    <BaseBtn isOn={isOn} className="position-relative border-0 p-y-10 rounded-10 d-flex flex-row justify-content-center align-items-center w-100 btn-4">
                        <ButtonLogo className="me-1 mb-1" /> 
                        <span className="col-white font-bold text-14 font-weight-700 z-index-0">Sign up for Free</span>
                    </BaseBtn>
                    </Link>
                </div>
            </motion.div>
            <motion.div variants={child2} className="d-flex flex-column justify-content-center align-items-center position-relative second-s">
                {/* <motion.img initial={{opacity: 0}} animate={{opacity: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 1]}} transition={{duration: 3, delay: 2, ease: 'easeOut', type:"keyframes"}} src={LandingImage} className='product-img' alt="calendar" /> */}
                <motion.div style={{zIndex:1}} initial={{opacity: 0}} animate={{opacity: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 1]}} transition={{duration: 3, delay: 2, ease: 'easeOut', type:"keyframes"}}>
                <img className="platform-img" src={Rectangle} alt="platform" />
                </motion.div>
                <div className="position-absolute bgnd-prod z-index-0 rounded-circle">
                </div>
                <motion.div variants={child2w} initial="hidden" animate="visible" className="position-absolute p-0 z-index-0">
                    <Left style={{opacity:0.6}} id="Leftw"/>
                </motion.div>
                <motion.div variants={Top} initial="hidden" animate="visible" className="position-absolute hr-top">
                    <TopW id='Payroll-top' style={{opacity:0.6}}/>
                </motion.div>
                <motion.div variants={Bottom} initial="hidden" animate="visible" className="position-absolute p-0 hr-bottom">
                    <BottomW id='Payroll-bottom' style={{opacity:0.6}}/>
                </motion.div>
            </motion.div>
        </motion.div>
        <div className="custom-shape-divider-bottom-1715923248">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
            </svg>
        </div>
    </motion.div>
    <div className="d-flex flex-column justify-content-center align-self-center seo-main ">
  
            <div className='mb-4'>
                <h2 className='font-weight-7 mt-4 mb-4 policy-heading-2'>Revolutionizing Workforce Management with Walkzero HRMS</h2>

                <p>Managing human resources efficiently is no longer a luxury—it’s a necessity.</p><p> Walkzero’s HRMS software is designed to streamline every aspect of HR operations, ensuring effortless payroll, attendance tracking, recruitment, and compliance.</p><p> Whether you run a small business, a large enterprise, or a specialized industry like healthcare, manufacturing, education, security agencies, food industry, logistics, sales and service industry, delivery partners, construction company, real estate, tourism sector, BPO, KPO, or consultancy organizations, Walkzero provides tailored HRMS solutions that evolve with your workforce needs.</p>

        
                <h2 className='font-weight-7 mt-5 mb-5'>Core HR Solutions: Powering People, Simplifying Processes</h2>

                <ul style={{listStyle:'none'}} className="large-hr-solutions">
                <li >
                    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          

  

         <div style={{ flex: 1 }}>
              <p className='privacy-info font-21'> Attendance Management System
              </p>
                    <p>
                    Accurate time tracking with multiple clock-in options including biometric, geo-clock, and mobile app integration ensures effortless workforce attendance management.
                    </p>
                    </div>
                    <div style={{ flex: 1,textAlign:'center' }}>
    <img
      src={DashBoardAttendance }
      alt="Employee Self-Service Portal"
      style={{ width:'500px',height:'300px'}}
    />
  </div>
</div>

                    </li>
     <li >
                    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          

  <div style={{ flex: 1,textAlign:'left' }}>
    <img
      src={DashBoardLeave}
      alt="Employee Self-Service Portal"
      style={{ width:'500px',height:'300px'}}
    />
  </div>
  <div style={{ flex: 1 }}>
              <p className='privacy-info font-21'>  Leave Management System
              </p>
                    <p>
                    No more leave-tracking hassles. Our leave management system offers a smooth experience with automated and authorized approvals, leave balance tracking and policy customization.
                    </p>
                    </div>
</div>

                    </li>
                    <li >
                    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <div style={{ flex: 1 }}>
              <p className='privacy-info font-21'> Employee Self-Service Portal</p>
                    <p>
                    Empower your workforce with a self-service HRMS portal where employees can update their information, Instant Notifications and Alerts, Deadline Alerts, request leaves, short leaves, and download payslips.

                    </p>
                    </div>

                        <div style={{ flex: 1,textAlign:'center' }}>
                            <img
                            src={DashBoard}
                            alt="Employee Self-Service Portal"
                            style={{ width:'300px',height:'300px'}}
                            />
                        </div>
                            </div>

                    </li>
                    <li >
                    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          

  <div style={{ flex: 1,textAlign:'left' }}>
    <img
      src={DashBoardGeo}
      alt="Employee Self-Service Portal"
      style={{ width:'500px',height:'300px'}}
    />
  </div>
  <div style={{ flex: 1 }}>
              <p className='privacy-info font-21'> Performance Management System
              </p>
                    <p>
                    Walkzero's performance management module enables goal setting, real-time feedback, and automated performance reviews, ensuring talent growth and retention.
                    </p>
                    </div>
</div>

                    </li>
                </ul>

                <ul style={{ listStyle: "none", padding: 0 }} className="small-hr-solutions">
  <li style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", marginBottom: "40px" }}>
    <p className="privacy-info font-21" style={{ width: "100%" }}>
      Attendance Management System
    </p>
    <img src={DashBoardAttendance} alt="Employee Self-Service Portal" style={{ width: "100%", maxWidth: "500px", height: "auto" }} />
    <p style={{ width: "100%", maxWidth: "700px" }}>
      Accurate time tracking with multiple clock-in options including biometric, geo-clock, and mobile app integration ensures effortless workforce attendance management.
    </p>
  </li>

  <li style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", marginBottom: "40px" }}>
    <p className="privacy-info font-21" style={{ width: "100%" }}>
      Leave Management System
    </p>
    <img src={DashBoardLeave} alt="Employee Self-Service Portal" style={{ width: "100%", maxWidth: "500px", height: "auto" }} />
    <p style={{ width: "100%", maxWidth: "700px" }}>
      No more leave-tracking hassles. Our leave management system offers a smooth experience with automated and authorized approvals, leave balance tracking, and policy customization.
    </p>
  </li>

  <li style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", marginBottom: "40px" }}>
    <p className="privacy-info font-21" style={{ width: "100%" }}>
      Employee Self-Service Portal
    </p>
    <img src={DashBoard} alt="Employee Self-Service Portal" style={{ width: "80%", maxWidth: "500px", height: "300px" }} />
    <p style={{ width: "100%", maxWidth: "700px" }}>
      Empower your workforce with a self-service HRMS portal where employees can update their information, receive instant notifications and alerts, and download payslips.
    </p>
  </li>

  <li style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", marginBottom: "40px" }}>
    <p className="privacy-info font-21" style={{ width: "100%" }}>
      Performance Management System
    </p>
    <img src={DashBoardGeo} alt="Employee Self-Service Portal" style={{ width: "100%", maxWidth: "500px", height: "auto" }} />
    <p style={{ width: "100%", maxWidth: "700px" }}>
      Walkzero's performance management module enables goal setting, real-time feedback, and automated performance reviews, ensuring talent growth and retention.
    </p>
  </li>
</ul>


                <h2 className='font-weight-7 mt-5'>Industry Smart HRMS - Accessible Anytime Anywhere! </h2>

                <div className='d-flex flex-column align-self-center privacy-table py-0'>
                <p className='privacy-info'>HRMS for Small Businesses</p>
                <p>Designed for growth, Walkzero’s HRMS for small businesses helps startups and SMEs manage HR tasks with minimal effort and maximum efficiency.</p>
                </div>
                <div className='d-flex flex-column align-self-center privacy-table py-0'>
                <p className='privacy-info'>HRMS for Large Enterprises</p>
                <p>Seamlessly integrate HR operations across multiple locations, ensuring scalability and compliance with our HRMS for large enterprises.</p>
                </div>

                <h2 className='font-weight-7 mt-5 mb-5'>Smart Features for a Future-Ready HRMS</h2>

<ul>
    <li ><p className='privacy-info'>Cloud-Based HRMS</p>
    <p> Access HR data anytime, anywhere with Walkzero’s secure cloud-based HRMS, ensuring flexibility and data security.</p></li>
    {/* <li ><p className='privacy-info'>Leave Management System</p><p> No more leave tracking hassles. Our leave management system offers a smooth experience with automated approvals, leave balance tracking, and policy customization.</p></li> */}
    <li >
    <p className='privacy-info'>Mobile HRMS App</p>
    <p> Manage HR operations on the go with Walkzero’s mobile HRMS app, offering instant access to attendance, leave, and payroll data..</p>
    </li>
    <li >
    <p className='privacy-info'> AI-Powered HR Solutions</p>
    <p> Leverage artificial intelligence in HRMS to automate HR workflows, enhance recruitment, and predict workforce trends.</p>
    <li>
    <p className='privacy-info'>Advanced HR Analytics</p>
    <p> Leverage artificial intelligence in HRMS to automate HR workflows, enhance recruitment, and predict workforce trends.</p>
    </li>
    <li>
    <p className='privacy-info'> Customization & Integrations</p>
    <p>Every organization is unique, and Walkzero understands that. Our HRMS with customization options allows you to tailor workflows, approvals, and reporting to your specific needs.</p>
    </li>

    </li>
</ul>

<h2 className='font-weight-7 mt-5 mb-5'>Solving HR Challenges with Walkzero</h2>
{/* <div style={{ display:'flex',alignItems:'center' }}>
    <img
      src={DashBoard }
      alt="Employee Self-Service Portal"
      style={{ width:'500px',height:'300px'}}
    />
  </div> */}
<ul>
    <li ><p className='privacy-info'> HRMS for Employee Engagement</p>
    <p>Boost employee satisfaction with self-service options, feedback tools, and career growth tracking.</p></li>
    {/* <li ><p className='privacy-info'>Leave Management System</p><p> No more leave tracking hassles. Our leave management system offers a smooth experience with automated approvals, leave balance tracking, and policy customization.</p></li> */}
    <li >
    <p className='privacy-info'> HRMS for Talent Management</p>
    <p>Attract, nurture, and retain top talent with data-driven insights and automated career development programs.</p>
    </li>
    <li >
    <p className='privacy-info'>HRMS for Compliance Management</p>
    <p>Stay audit-ready with real-time compliance tracking, automated labor law updates, and seamless tax calculations.</p>
    <li>
    <p className='privacy-info'>HRMS for Workforce Planning</p>
    <p> Optimize workforce allocation with intelligent scheduling, shift planning, and project-based time tracking.</p>
    </li>
    <li>
    <p className='privacy-info'>HRMS for Succession Planning</p>
    <p>Identify and develop future leaders with AI-powered succession planning tools.</p>
    </li>

    </li>
</ul>
<h2 className='font-weight-7 mt-5 mb-4'>Why Choose Walkzero HRMS?</h2>
<ul>
    <li >
    <p>User-Friendly & Scalable – Designed for businesses of all sizes.</p></li>
    {/* <li ><p className='privacy-info'>Leave Management System</p><p> No more leave tracking hassles. Our leave management system offers a smooth experience with automated approvals, leave balance tracking, and policy customization.</p></li> */}
    <li >
    
    <p>Secure & Cloud-Based – Access HR data anytime, anywhere.</p>
    </li>
    <li >

    <p>AI-Driven Insights – Smarter decisions with real-time analytics.</p>
    <li>
  
    <p> Customizable & Flexible – Adaptable to unique business needs.</p>
    </li>
    <li>
    <p> Seamless Integrations – Compatible with existing software and tools.</p>
    </li>

    </li>
</ul>

                <p> Transform Your HR Operations with Walkzero! Get in touch today to see how our HRMS software can redefine your workforce management.</p>
            </div>
          
        </div>
        <MobMenu/>

  
    </>
  )
}

export default SEO;