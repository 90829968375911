import { Link } from "react-router-dom"
import { useEffect } from "react"
import {useAnimation, motion} from "framer-motion"
import { Breadcrumbs, Typography } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { container2, child2  } from "../../../variants"
import { Helmet } from 'react-helmet-async'

import {ReactComponent as Left} from "../../../assets/logos/logo-left.svg"
import {ReactComponent as TopW} from "../../../assets/logos/top-w-curve.svg"
import {ReactComponent as BottomW} from "../../../assets/logos/bottom-w-curve.svg"
import './index.scss'
import MobMenu from '../mob-menu'





const TermsAndCondition = () => {



    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: false,
      });

    useEffect(() => {
        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView])


    const child2w = {
        visible: {
          x:0,
          y:0,
          rotate:0,
          transition: {
            duration: 1.5,
            type:'tween'
          }
        },
        hidden: {
          x:-1700,
          y:350,
          rotate:90,
        }
      }
      const Top = {
        visible: {
          x:0,
          y:0,
          transition: {
            duration: 1,
            delay: 1,
            type:'tween'
          }
        },
        hidden: {
          x:0,
          y:-500,
        }
      }
    
      const Bottom = {
        visible: {
          x:0,
          y:0,
          transition: {
            duration: 1,
            delay: 1,
            type:'tween'
          }
        },
        hidden: {
          x:0,
          y:200,
        }
      }


  return (
    <>
    <Helmet>
        <title>TERMS AND CONDITIONS | Walkzero Technologies Private Limited</title>
    </Helmet>
    <motion.div ref={ref} variants={container2} initial="hidden" animate={ctrls} id="hrMain" className="hr-excellence d-flex flex-column position-relative bg-col-new-light-green">
        <motion.div  className="product-inner-cont-terms d-flex flex-row justify-content-center align-items-center align-self-center">
        
            <motion.div style={{height:'400px'}} variants={child2} className="d-flex flex-column justify-content-center align-items-center position-relative second-tc-s mt-20">
                <motion.div style={{zIndex:1}} initial={{opacity: 0}} animate={{opacity: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 1]}} transition={{duration: 3, delay: 2, ease: 'easeOut', type:"keyframes"}} className="d-flex flex-row justify-content-center align-items-center align-self-center pt-0">
            <motion.div   className="step-into-card-ts d-flex flex-column position-relative justify-content-center align-items-center">
         

                <h3  className='product-tandc-heading col-container-font h1-size text-center'>Terms  <span className='col-secondary'> & Conditions </span> </h3>
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
                Home
                </Link>
                <Typography color="text.primary">Terms & Conditions </Typography>
            </Breadcrumbs>
             </motion.div>
            </motion.div>




                <div className="position-absolute bgnd-prod z-index-0 rounded-circle">
                </div>
                <motion.div variants={child2w} initial="hidden" animate="visible" className="position-absolute p-0 z-index-0">
                    <Left style={{opacity:0.6}} id="Leftw"/>
                </motion.div>
                <motion.div variants={Top} initial="hidden" animate="visible" className="position-absolute hr-top-pr">
                    <TopW id='Payroll-top' style={{opacity:0.6}}/>
                </motion.div>
                <motion.div variants={Bottom} initial="hidden" animate="visible" className="position-absolute p-0 hr-bottom-pr">
                    <BottomW id='Payroll-bottom' style={{opacity:0.6}}/>
                </motion.div>
            </motion.div>
        </motion.div>
        <div className="custom-shape-divider-bottom-1715923248">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
            </svg>
        </div>
    </motion.div>
    <div className="d-flex flex-column justify-content-center align-self-center privacy-policy ">
  
            <div className='mb-4'>
                <h2 className='font-weight-7 mt-4 mb-4 policy-heading-2'>Terms & Conditions</h2>

                <p>This Agreement is entered into by the Customer and Walkzero. The term &quot;Customer&quot; refers to
the entity for which this Agreement is being accepted, or if that does not apply, the individual
who accepts this Agreement. &quot;Walkzero&quot; refers to the Walkzero entity that owns or operates the
Products that the Customer uses or accesses, as listed at
https://www.walkzero.com/legal/product-terms.</p>

                <p>If you (the individual accepting this Agreement) are accepting this Agreement on behalf of your
employer or another organization, you confirm that: (i) you have the legal authority to bind your
employer or that organization to this Agreement, and (ii) you accept the terms of this Agreement
on behalf of your employer or that organization.</p>

                <p>If you accept this Agreement using an email address from your employer or another
organization, then: (i) you will be considered a representative of that party, (ii) your acceptance
will bind your employer or that organization to these terms, and (iii) the term &quot;you&quot; or &quot;Customer&quot;
in this Agreement will apply to your employer or that organization.</p>

                <p>By selecting the “Agree” (or similar button or checkbox) presented to you during the process of
placing an Order, downloading Products, or by using or accessing the Products, you confirm
your acceptance of this Agreement. If you do not agree to be bound by this Agreement, do not
select “Agree” (or similar button or checkbox), download the Products, or use or access the
Products..</p>

                {/* <h2 className='font-weight-7 mt-5 mb-5'>What We Collect and How We Utilise It</h2> */}

                <h5 className='font-weight-7 mt-4 mb-4'>1 . Overview </h5>

                <p>This Agreement governs Customer’s Orders for Products and related Support and
Advisory Services. The terms outlined in this Agreement apply to both Cloud Products
and Software Products, although certain conditions are specific to either Cloud Products
or Software Products, as indicated below. Additionally, some Products are subject to
separate Product-Specific Terms, and Support and Advisory Services are governed by
the applicable Policies.</p>

<h5 className='font-weight-7 mt-4 mb-4'>2. Use of Products </h5>

<p>2.1. Authorized Use. Subject to this Agreement and within the applicable Subscription
Term, Walkzero grants Customer a non-exclusive, global right to use the Products and
related Support and Advisory Services for internal business purposes of the Customer
and its Affiliates, in line with the Documentation and as specified in the Order and the
Product-Specific Terms.</p>

                <p><span className='font-weight-7 mt-4 mb-4'>2.2. Restrictions.</span> Except where explicitly allowed by this Agreement, Customer must not (and
must not permit others to): (a) rent, lease, sell, distribute, or sublicense the Products, or (except
for Affiliates) use them in a service bureau or outsourcing offering, (b) provide access to the
Products to anyone other than Users, (c) charge a specific fee to its customers for the use of the
Products, although the Customer may charge a general fee for its own offerings, (d) use the
Products to create a competing product or service, (e) reverse engineer, decompile,

disassemble, or attempt to access the source code or non-public APIs of the Products, (f)
modify or create derivative works from the Products, (g) circumvent or interfere with the
Products’ usage limits or Scope of Use restrictions, (h) alter or obscure any proprietary notices
or attributions in the Products, or (i) breach the Acceptable Use Policy.</p>

                {/* <h5 className='font-weight-7 mt-4 mb-4'>Location Information</h5> */}

                <p>2.3. DPA. The DPA applies to Customer’s use of Products and related Support and Advisory
                Services and forms a part of this Agreement.</p>

                

                <h5 className='font-weight-7 mt-4 mb-4'>3. Users </h5>
                <p>3.1. Responsibility. Customer may authorize Users to access and utilize the Products, in
compliance with the Documentation and Customer’s Scope of Use. Customer is
accountable for ensuring that its Users comply with this Agreement and for all actions
taken by its Users, including Orders they may place, Third Party-Products and apps
enabled, and how they access and utilize Customer Data.</p>

<p><span className='font-weight-7 mt-4 mb-4'>3.2. Login Credentials.</span> Customer must ensure that each User keeps their login credentials
secure and must immediately notify Walkzero if any unauthorized access to a User’s login
credentials or any other unauthorized use of the Products is detected.</p>

<p><span className='font-weight-7 mt-4 mb-4'>3.3. Domain Ownership.</span> In cases where a Cloud Product requires the Customer to specify a
domain (such as <a href="#" target="_blank">www.example.com</a> ) for the operation of the Cloud Product or a feature,
Walkzero may verify that the Customer or an Affiliate controls that domain. Walkzero has no
obligation to provide that Cloud Product or feature if it cannot verify the domain ownership or
control by the Customer or an Affiliate. Product administrators designated by the Customer may
also manage accounts that were previously registered with an email address under the
Customer’s domain, which will be considered &quot;managed accounts&quot; (or similar term), as detailed
in the Documentation.</p>

<p><span className='font-weight-7 mt-4 mb-4'>3.4. Age Requirements.</span> The Products are not intended for use by individuals under the age of
16. Customer is responsible for ensuring that all Users are at least 16 years old.</p>

<h5 className='font-weight-7 mt-4 mb-4'>4. Cloud Products</h5>
<p>This Section 4 applies solely to Cloud Products.</p>

<p><span className='font-weight-7 mt-4 mb-4'>4.1. Customer Data.</span> Walkzero may process Customer Data to deliver the Cloud Products and related Support or Advisory Services as outlined in this Agreement.</p>

<p><span className='font-weight-7 mt-4 mb-4'>4.2. Security Program.</span> Walkzero has established and will continue to uphold an information security program that implements appropriate physical, technical, and organizational measures designed to safeguard Customer Data from unauthorized access, destruction, alteration, or disclosure, as outlined in its Security Measures. Walkzero will also maintain a compliance program, including independent third-party audits and certifications, as detailed in its Security Measures. Additional information about Walkzero’s security program is available on the Walkzero Trust Center at https://www.walkzero.com/trust, as updated periodically.</p>

<p><span className='font-weight-7 mt-4 mb-4'>4.3. Service Levels.</span> If applicable, service level commitments for the Cloud Products are provided in the Service Level Agreement.</p>

<p><span className='font-weight-7 mt-4 mb-4'>4.4. Data Retrieval.</span> The Documentation explains how the Customer can retrieve their Customer Data from the Cloud Products.</p>

<p><span className='font-weight-7 mt-4 mb-4'>4.5. Removals and Suspension.</span> Walkzero is not required to monitor Customer Data. However, if Walkzero becomes aware that: (a) Customer Data may breach Laws, Section 2.2 (Restrictions), or the rights of others (including in relation to a takedown request under the guidelines for Reporting Copyright and Trademark Violations at https://www.walkzero.com/legal/copyright-and-trademark-violations), or (b) the use of the Cloud Products by the Customer jeopardizes the security or operation of the Cloud Products, then Walkzero may: (i) restrict access to, or remove, the specific Customer Data, or (ii) suspend access to the relevant Cloud Products for Customer or any User. Walkzero may also take such actions if required by Law or upon request from a government authority. Walkzero will, when feasible, provide the Customer an opportunity to resolve the issue before implementing any such actions.</p>

<h5 className='font-weight-7 mt-4 mb-4'>5. Software Products</h5>
<p>This Section 5 applies only to Software Products.</p>

<p><span className='font-weight-7 mt-4 mb-4'>5.1. Modifications.</span> Walkzero may provide certain portions of the Software Products in source code form for Customer to use internally to create bug fixes, configurations, or other modifications to the Software Products, as permitted in the Documentation (“Modifications”). Customer must keep such source code secure (on devices or online repositories controlled by Customer), confidential, and restrict access to it to only those employees who need it for creating or maintaining Modifications. Customer may only use Modifications with the Software Products and in accordance with this Agreement, including the Third-Party Code Policy, the Documentation, and Customer’s Scope of Use. Customer must not distribute the source code or Modifications to third parties. The source code must be securely destroyed at the earliest of: (a) when Customer no longer needs it for Modifications, (b) upon termination or non-renewal of the relevant Subscription Term, or (c) upon Walkzero’s request for any reason. Notwithstanding anything else in this Agreement, Walkzero is not responsible for providing support, warranties, indemnities, or any other obligations regarding Modifications.</p>

<p><span className='font-weight-7 mt-4 mb-4'>5.2. License Verification.</span> Upon Walkzero’s written request, Customer must promptly confirm in writing whether their use of the Software Products complies with the applicable Scope of Use. Walkzero or its authorized agents may audit Customer’s use of the Software Products no more than once per year to verify compliance with the Scope of Use, provided that Walkzero gives reasonable advance notice and makes efforts to minimize disruption to the Customer. If Customer exceeds their Scope of Use, Walkzero may invoice for the excess usage, and Customer must pay Walkzero promptly after receiving the invoice.</p>

<p><span className='font-weight-7 mt-4 mb-4'>5.3. Number of Instances.</span> Unless specified otherwise in the Order or Product-Specific Terms, Customer may install up to one (1) production instance of each Software Product included in an Order on systems owned or operated by the Customer or its Users.</p>

<h5 className='font-weight-7 mt-4 mb-4'>6. Customer Obligations</h5>

<p><span className='font-weight-7 mt-4 mb-4'>6.1. Disclosures and Rights.</span> Customer must ensure that it has provided all necessary disclosures and obtained all rights and consents required for Walkzero to use Customer Data and Customer Materials in order to deliver the Cloud Products, Support, or Advisory Services.</p>

<p><span className='font-weight-7 mt-4 mb-4'>6.2. Product Assessment.</span> Customer is responsible for evaluating whether the Products fulfill their needs and comply with any regulatory requirements related to the intended use.</p>

<p><span className='font-weight-7 mt-4 mb-4'>6.3. Sensitive Health Information and HIPAA.</span> Unless the parties have entered into a ‘Business Associate Agreement,’ Customer must not (and must not allow others to) upload to the Cloud Products (or use the Cloud Products to process) any patient, medical, or other protected health information governed by the Health Insurance Portability and Accountability Act (HIPAA).</p>

<h5 className='font-weight-7 mt-4 mb-4'>7. Third-Party Code and Third-Party Products</h5>

<p><span className='font-weight-7 mt-4 mb-4'>7.1. Third-Party Code.</span> This Agreement and the Third-Party Code Policy apply to open-source software and commercial third-party software that Walkzero integrates into the Products.</p>

<p><span className='font-weight-7 mt-4 mb-4'>7.2. Third-Party Products.</span> Customer may choose to use the Products with third-party platforms, applications, add-ons, services, or products, including offerings made available through the Walkzero Marketplace (“Third-Party Products”). Using such Third-Party Products with the Products may require access to Customer Data and other data by the third-party provider, which, for Cloud Products, Walkzero will permit on Customer’s behalf if the Customer enables that Third-Party Product. The use of Third-Party Products is governed by the relevant provider’s terms of use, not this Agreement. Walkzero does not control and is not liable for Third-Party Products.</p>

<h5 className='font-weight-7 mt-4 mb-4'>8. Support and Advisory Services</h5>

<p>Walkzero will provide Support and Advisory Services as specified in the Order and relevant Policies. The provision of Support or Advisory Services by Walkzero is contingent upon Customer granting timely access to Customer Materials and personnel as reasonably requested by Walkzero.</p>

<h5 className='font-weight-7 mt-4 mb-4'>9. Ordering Process and Delivery</h5>

<p>No Order will be binding until Walkzero provides its acceptance, which may include sending a confirmation email, granting access to the Products, or making license or access keys available to Customer. No terms from any purchase order or other business form used by Customer will override, supplement, or otherwise apply to this Agreement or Walkzero. Walkzero will deliver login instructions or license keys for Products electronically, to Customer’s account (or through other reasonable methods) once payment is received. Customer is responsible for installing the Software Products, and Walkzero has no further delivery obligations after providing the license keys.</p>

<h5 className='font-weight-7 mt-4 mb-4'>10. Billing and Payment</h5>

<p><span className='font-weight-7 mt-4 mb-4'>10.1. Fees</span></p>

<p>(a) <span className='font-weight-7'>Direct Purchases.</span> If Customer purchases directly from Walkzero, the fees and payment terms will be outlined in Customer’s Order with Walkzero.</p>

<p>(b) <span className='font-weight-7'>Resellers.</span> If Customer purchases through a Reseller, Customer must make all payments directly to the Reseller, and the order details (e.g., Products and Scope of Use) will be specified in the Order placed by the Reseller on Customer’s behalf.</p>

<p>(c) <span className='font-weight-7'>Renewals.</span> Unless otherwise specified in the Order and provided that the Product, Support, or Advisory Services remain generally available, a Subscription Term will automatically renew at Walkzero’s current rates for: (i) if the prior Subscription Term was less than twelve (12) months, another Subscription Term of the same length as the previous one, or (ii) if the prior Subscription Term was twelve (12) months or more, twelve (12) months. Either party can opt not to renew a Subscription Term by notifying the other party before the current Subscription Term ends. Customer must submit any non-renewal notice through the account settings in the Products, by contacting Walkzero’s support team, or by providing notice by other means.</p>

<p>(d) <span className='font-weight-7'>Increased Scope of Use.</span> If Customer exceeds the Scope of Use purchased, unless otherwise agreed with Walkzero in writing, Customer must upgrade their subscription or pay for the increased Scope of Use. Unless specified otherwise in an applicable Order, Walkzero will charge Customer for the increased Scope of Use at Walkzero’s then-current rates, which may be prorated for the remainder of the Subscription Term.</p>

<p>(e) <span className='font-weight-7'>Refunds.</span> All fees and expenses are non-refundable, except as otherwise stated in this Agreement. For purchases made through a Reseller, any refunds payable by Walkzero to Customer for such purchases will be processed by the Reseller, unless Walkzero specifically notifies Customer otherwise at the time of refund.</p>

<p>(f) <span className='font-weight-7'>Credit Cards.</span> If Customer uses a credit card or similar online payment method for an initial Order, Walkzero may bill that payment method for renewals, additional Orders, overages in scope of use, expenses, and unpaid fees, as applicable.</p>

<p><span className='font-weight-7 mt-4 mb-4'>10.2. Taxes</span></p>

<p>(a) <span className='font-weight-7'>Taxes Generally.</span> Fees and expenses do not include any sales, use, GST, value-added, withholding, or similar taxes or levies that apply to Customer’s Orders. Except for taxes on Walkzero’s net income, Customer is responsible for paying these taxes or levies, which Walkzero will itemize separately on the invoice.</p>

<p>(b) <span className='font-weight-7'>Withholding Taxes.</span> If Customer is required to withhold tax from payments to Walkzero in certain jurisdictions, Customer must provide valid documentation confirming the remittance of withholding to the relevant taxing authority at the time of payment of the applicable invoice.</p>

<p>(c) <span className='font-weight-7'>Exemptions.</span> If Customer claims exemption from any sales tax, VAT, GST, or similar taxes under this Agreement, they must provide Walkzero with a valid tax exemption certificate or tax ID at the time of Order. After receiving valid proof of exemption, Walkzero will not include applicable taxes on the relevant Customer invoice.</p>

<p><span className='font-weight-7 mt-4 mb-4'>10.3. Return Policy.</span> Within thirty (30) days of their initial Order for a Product, Customer may terminate the Subscription Term for that Product for any reason by notifying Walkzero. Following termination, upon request (which may be made through Customer’s Walkzero account), Walkzero will refund Customer the amount paid for the Product and associated Support under the applicable Order. Unless otherwise specified in the Policies or Product-Specific Terms, this return policy does not apply to Advisory Services.</p>

<p><span className='font-weight-7 mt-4 mb-4'>10.4. Suspension for Non-payment.</span> Walkzero may suspend Customer’s right to use the Products or receive Support or Advisory Services if payment is overdue, provided that Walkzero has given Customer at least ten (10) days' written notice.</p>

<h5 className='font-weight-7 mt-4 mb-4'>11. Walkzero Warranties</h5>
<p><span className='font-weight-7 mt-4 mb-4'>11.1. Performance Warranties.</span> Walkzero warrants to Customer that: (a) the Products will operate substantially in accordance with the applicable Documentation during the Subscription Term, (b) Walkzero will not materially reduce the functionality or overall security of the Products during the Subscription Term, and (c) Walkzero will use reasonable efforts to ensure that the Products, when provided, are free from viruses, malware, or similar malicious code (collectively, “Performance Warranty”).</p>

<p><span className='font-weight-7 mt-4 mb-4'>11.2. Performance Warranty Remedy.</span> If Walkzero breaches a Performance Warranty and Customer submits a reasonably detailed warranty claim within 30 days of discovering the issue, Walkzero will use reasonable efforts to correct the non-conformity. If Walkzero determines that correcting the issue is impractical, either party may terminate the affected Subscription Term. Walkzero will then refund Customer any pre-paid, unused fees for the terminated portion of the Subscription Term. These procedures will serve as Customer’s exclusive remedy and Walkzero’s full liability for breach of a Performance Warranty.</p>

<p><span className='font-weight-7 mt-4 mb-4'>11.3. Exclusions.</span> The warranties in this Section 11 (Walkzero Warranties) do not apply to: (a) issues or non-conformities caused by Customer’s unauthorized use or modification of the Products, (b) unsupported releases of Software Products or Cloud Clients, or (c) Third-Party Products.</p>

<p><span className='font-weight-7 mt-4 mb-4'>11.4. Disclaimers.</span> Except as expressly provided in this Section 11 (Walkzero Warranties), the Products, Support, Advisory Services, and all related services are provided “AS IS.” Walkzero makes no other warranties, whether express, implied, statutory, or otherwise, including warranties of merchantability, fitness for a particular purpose, title, or non-infringement. Walkzero does not warrant that Customer’s use of the Products will be uninterrupted or error-free. Walkzero is not liable for delays, failures, or issues inherent in the use of the internet and electronic communications or systems outside Walkzero’s control.</p>

<h5 className='font-weight-7 mt-4 mb-4'>12. Term and Termination</h5>
<p><span className='font-weight-7 mt-4 mb-4'>12.1 Term:</span> This Agreement begins on the date the Customer accepts it and continues until all Subscription Terms have concluded.</p>

<p><span className='font-weight-7 mt-4 mb-4'>12.2 Termination for Convenience:</span> The Customer has the right to terminate this Agreement or any Subscription Term by providing notice for any reason. As per Section 10.3 (Return Policy), no refunds will be provided when exercising this right, and any outstanding amounts for the current Subscription Terms or related service periods will be immediately due upon termination.</p>

<p><span className='font-weight-7 mt-4 mb-4'>12.3 Termination for Cause:</span> Either party may end this Agreement or any Subscription Term if the other party: (a) fails to correct a significant breach of this Agreement (including non-payment of fees) within 30 days of receiving notice, (b) ceases operations without a successor, or (c) seeks protection under a bankruptcy, receivership, trust deed, creditors’ arrangement, composition, or similar procedure, or if such a process is initiated against them and is not dismissed within 60 days. If the Customer terminates this Agreement or a Subscription Term under this Section 12.3, Walkzero will refund any pre-paid, unused fees for the portion of the Agreement or Subscription Term that was terminated.</p>

<p><span className='font-weight-7 mt-4 mb-4'>12.4 Effect of Termination:</span> Upon expiration or termination of this Agreement or any Subscription Term: (a) the Customer's rights to use the relevant Products, Support, or Advisory Services will cease, (b) the Customer must immediately stop accessing the Cloud Products and using the applicable Software Products and Cloud Clients, and (c) the Customer must delete (or return upon request) all license keys, access keys, and any Product copies. After expiration or termination, unless prohibited by law, Walkzero will delete the Customer's data in line with the Documentation.</p>

<p><span className='font-weight-7 mt-4 mb-4'>12.5 Survival:</span> The following sections will remain in effect after the expiration or termination of this Agreement: 2.2 (Restrictions), 4.2 (Security Program), 10.1 (Fees), 10.2 (Taxes), 11.4 (Disclaimers), 12.4 (Effect of Termination), 12.5 (Survival), 13 (Ownership), 14 (Limitations of Liability), 15 (Indemnification by Walkzero), 16 (Confidentiality), 17.4 (Disclaimer), 18 (Feedback), 20 (General Terms), and 21 (Definitions).</p>

<h5 className='font-weight-7 mt-4 mb-4'>13. Ownership</h5>
<p>Unless explicitly stated in this Agreement, neither party grants the other any rights or licenses to its intellectual property. Customer owns all rights and intellectual property in Customer Data and Customer Materials provided to Walkzero or used with the Products. Walkzero and its licensors retain all rights and intellectual property related to the Products, any Support and Advisory Services deliverables, source code, Walkzero technology, templates, formats, dashboards, and any modifications or improvements thereof.</p>

<h5 className='font-weight-7 mt-4 mb-4'>14. Limitations of Liability</h5>
<p><span className='font-weight-7 mt-4 mb-4'>14.1 Damages Waiver:</span> Except for Excluded Claims or Special Claims, neither party will have any liability arising from or in relation to this Agreement for any loss of use, data, profits, business interruption, or any indirect, special, incidental, consequential, or reliance damages, even if they were informed of the possibility of such damages beforehand.</p>

<p><span className='font-weight-7 mt-4 mb-4'>14.2 General Liability Cap:</span> Except for Excluded Claims or Special Claims, each party’s total liability arising from this Agreement will not exceed the aggregate amount paid to Walkzero for the Products, Support, and Advisory Services that gave rise to the liability during the 12 months before the first event that triggered the liability. The Customer’s obligations regarding payments under Sections 10.1 (Fees) and 10.2 (Taxes) are not limited by this Section 14.2.</p>

<p><span className='font-weight-7 mt-4 mb-4'>14.3 Excluded Claims:</span> “Excluded Claims” refers to: (a) the Customer’s breach of Section 2.2 (Restrictions) or Section 6 (Customer Obligations), (b) either party’s breach of Section 16 (Confidentiality), excluding claims relating to Customer Data or Customer Materials, or (c) amounts due to third parties under Walkzero’s obligations in Section 15 (Indemnification by Walkzero).</p>

<p><span className='font-weight-7 mt-4 mb-4'>14.4 Special Claims:</span> For Special Claims, Walkzero’s total liability under this Agreement will be the lesser of: (a) twice the amount paid to Walkzero for the Products, Support, and Advisory Services related to the Special Claim during the 12 months prior to the first incident of the Special Claim, or (b) US$5,000,000. “Special Claims” refers to any unauthorized disclosure of Customer Data or Customer Materials resulting from a breach of Walkzero’s obligations in Section 4.2 (Security Program).</p>

<p><span className='font-weight-7 mt-4 mb-4'>14.5 Nature of Claims and Failure of Essential Purpose:</span> The exclusions and limitations in this Section 14 (Limitations of Liability) apply regardless of the form of the action, whether in contract, tort (including negligence), strict liability, or any other legal theory and will apply even if any limited remedy in this Agreement fails of its essential purpose.</p>

<h5 className='font-weight-7 mt-4 mb-4'>15. Indemnification by Walkzero</h5>
<p><span className='font-weight-7 mt-4 mb-4'>15.1 IP Indemnification:</span> Walkzero will: (a) defend the Customer from any third-party claim alleging that the Products, when used by the Customer in accordance with this Agreement, infringe upon the intellectual property rights of a third party (an "Infringement Claim"), and (b) indemnify and protect the Customer from any damages, fines, or costs awarded by a court of competent jurisdiction (including reasonable attorneys’ fees) or agreed to in a settlement by Walkzero, which arise from such an Infringement Claim.</p>

<p><span className='font-weight-7 mt-4 mb-4'>15.2 Procedures:</span> Walkzero's obligations under Section 15.1 (IP Indemnification) are contingent upon the Customer providing: (a) timely notice of the Infringement Claim, so as to avoid prejudicing Walkzero’s defense, (b) the exclusive right to control and oversee the investigation, defense, and settlement of the Infringement Claim, and (c) all reasonable cooperation requested by Walkzero, at Walkzero’s expense for out-of-pocket costs. The Customer may participate in the defense of the Infringement Claim using their own counsel, at their own expense.</p>

<p><span className='font-weight-7 mt-4 mb-4'>15.3 Settlement:</span> The Customer cannot settle any Infringement Claim without obtaining prior written consent from Walkzero. Similarly, Walkzero cannot settle an Infringement Claim without the Customer’s prior written approval if such a settlement would require the Customer to admit fault or take, or refrain from taking, certain actions (other than those relating to the use of the Products).</p>

<p><span className='font-weight-7 mt-4 mb-4'>15.4 Mitigation:</span> In response to a real or potential Infringement Claim, Walkzero may, at its discretion: (a) acquire the rights necessary for the Customer to continue using the Products, (b) replace or modify the allegedly infringing part of the Products without reducing their overall functionality, or (c) terminate the affected Subscription Term and refund any pre-paid, unused fees for the terminated portion of the Subscription Term.</p>

<p><span className='font-weight-7 mt-4 mb-4'>15.5 Exceptions:</span> Walkzero’s indemnification obligations under this Section 15 (Indemnification by Walkzero) do not apply if an Infringement Claim arises from: (a) the Customer’s modifications to or unauthorized use of the Products, (b) the Products being used in combination with other items not provided by Walkzero (including Third-Party Products), (c) any unsupported release of the Software Products or Cloud Clients, or (d) Third-Party Products, Customer Data, or Customer Materials.</p>

<p><span className='font-weight-7 mt-4 mb-4'>15.6 Exclusive Remedy:</span> This Section 15 (Indemnification by Walkzero) sets forth the Customer’s exclusive remedy and Walkzero’s total liability with respect to any third-party intellectual property infringement claims.</p>

<p><span className='font-weight-7 mt-4 mb-4'>16. Confidentiality</span></p>

<p><span className='font-weight-7 mt-4 mb-4'>16.1 Definition:</span> "Confidential Information" refers to any information disclosed by one party to the other in relation to this Agreement that: (a) is marked by the disclosing party as proprietary or confidential, or (b) should reasonably be understood as proprietary or confidential due to its nature and the context of its disclosure. Walkzero’s Confidential Information includes any source code and technical or performance data regarding the Products. The Customer’s Confidential Information includes Customer Data and Customer Materials.</p>

<p><span className='font-weight-7 mt-4 mb-4'>16.2 Obligations:</span> Unless explicitly authorized by the disclosing party in writing, the receiving party must: (a) keep the disclosing party’s Confidential Information confidential and not disclose it to third parties, except as permitted in this Agreement, and (b) use such Confidential Information solely to fulfill its obligations and exercise its rights under this Agreement. The receiving party may disclose such Confidential Information to its employees, agents, contractors, and other representatives who have a legitimate need to know (including, for Walkzero, the subcontractors referenced in Section 20.11 (Subcontractors and Affiliates)), provided the receiving party ensures their compliance with this Section 16 (Confidentiality) and that they are bound to confidentiality obligations no less protective than this Section 16 (Confidentiality).</p>

<p><span className='font-weight-7 mt-4 mb-4'>16.3 Exclusions:</span> These confidentiality obligations do not apply to information that the receiving party can demonstrate: (a) is or becomes publicly available without fault on the part of the receiving party, (b) was already known or possessed by the receiving party prior to its disclosure under this Agreement without violating confidentiality obligations, (c) was obtained from a third party without breaching confidentiality obligations, or (d) was independently developed without the use of the disclosing party’s Confidential Information. The receiving party may disclose Confidential Information if required by law, subpoena, or court order, provided (if allowed by law) the receiving party notifies the disclosing party in advance and cooperates, at the disclosing party's cost, to seek confidential treatment.</p>

<p><span className='font-weight-7 mt-4 mb-4'>16.4 Remedies:</span> Unauthorized use or disclosure of Confidential Information may cause significant harm for which damages alone are an inadequate remedy. Each party may seek appropriate equitable relief, in addition to other available remedies, for a breach or anticipated breach of this Section 16 (Confidentiality).</p>

<p><span className='font-weight-7 mt-4 mb-4'>17. Free or Beta Products</span></p>

<p><span className='font-weight-7 mt-4 mb-4'>17.1 Access:</span> The Customer may be granted access to certain Products or Product features on a free, fully discounted, or trial basis, or as part of an alpha, beta, or early access offering ("Free or Beta Products"). The use of Free or Beta Products is governed by this Agreement and any additional terms specified by Walkzero, including the applicable scope and duration of use.</p>

<p><span className='font-weight-7 mt-4 mb-4'>17.2 Termination or Modification:</span> Walkzero may, at any time, terminate or modify the Customer’s use of (including the applicable terms of) Free or Beta Products, or modify the Free or Beta Products themselves, without incurring any liability to the Customer. To continue using or accessing Free or Beta Products, the Customer must accept any modifications to the terms or products.</p>

<p><span className='font-weight-7 mt-4 mb-4'>17.3 Pre-GA:</span> Free or Beta Products may be inoperable, incomplete, or contain bugs and features that Walkzero may never release, and their features and performance information are considered Walkzero’s Confidential Information.</p>

<p><span className='font-weight-7 mt-4 mb-4'>17.4 Disclaimer:</span> Notwithstanding any other provisions of this Agreement, to the fullest extent allowed by law, Walkzero makes no warranties, indemnities, service level agreements, or support for Free or Beta Products, and its total liability for Free or Beta Products is limited to US$100.</p>

<p><span className='font-weight-7 mt-4 mb-4'>18. Feedback</span></p>

<p>If the Customer provides Walkzero with feedback or suggestions regarding the Products or other Walkzero offerings, Walkzero may freely use such feedback or suggestions without any obligation or restriction.</p>

<p><span className='font-weight-7 mt-4 mb-4'>19. Publicity</span></p>

<p>Walkzero may identify the Customer as a client in its promotional materials. Walkzero will cease doing so promptly upon the Customer’s request.</p>

<p><span className='font-weight-7 mt-4 mb-4'>20. General Terms</span></p>

<p><span className='font-weight-7 mt-4 mb-4'>20.1 Compliance with Laws:</span> Both parties must adhere to all applicable laws while fulfilling their responsibilities or exercising their rights under this Agreement.</p>

<p><span className='font-weight-7 mt-4 mb-4'>20.2 Code of Conduct:</span> Walkzero must comply with its own Code of Conduct while performing its obligations or exercising its rights under this Agreement.</p>

<p><span className='font-weight-7 mt-4 mb-4'>20.3 Assignment:</span></p>
<ul>
  <li>(a) Customer is not allowed to transfer or assign any of its rights or obligations under this Agreement or any Order without prior written approval from Walkzero. However, Customer may assign the entire Agreement (including all Orders) to a successor arising from a merger, acquisition, or sale of all or a substantial portion of Customer’s assets or voting securities, provided Customer gives Walkzero prompt written notice of the assignment and the assignee agrees in writing to assume all of Customer’s obligations under this Agreement, and complies with Walkzero’s procedural and documentation requirements to finalize the assignment.</li>
  <li>(b) Any attempt by Customer to transfer or assign this Agreement or an Order without explicit authorization, as described above, will be invalid.</li>
  <li>(c) Walkzero may assign its rights and obligations under this Agreement (in whole or in part) without obtaining consent from Customer.</li>
</ul>

<p><span className='font-weight-7 mt-4 mb-4'>20.4 Governing Law, Jurisdiction, and Venue:</span></p>
<ul>
  <li>(a) If Customer is based: (i) in Europe, the Middle East, or Africa, this Agreement is governed by the laws of the Republic of Ireland, with jurisdiction and venue for related actions in the courts of the Republic of Ireland; or (ii) elsewhere, this Agreement is governed by the laws of the State of California, with jurisdiction and venue for related actions in the state and federal courts located in San Francisco, California.</li>
  <li>(b) This Agreement will be governed by such laws, without regard to conflicts of law provisions, and both parties submit to the personal jurisdiction of the designated courts. The United Nations Convention on the International Sale of Goods does not apply to this Agreement.</li>
</ul>

<p><span className='font-weight-7 mt-4 mb-4'>20.5 Notices:</span></p>
<ul>
  <li>(a) Unless stated otherwise in this Agreement, notices must be in writing and will be deemed received on: (i) personal delivery, (ii) upon receipt by the recipient if sent via a recognized overnight courier with a receipt request, (iii) on the third business day after mailing, or (iv) on the first business day after sending by email. However, email notices are not sufficient for matters related to Infringement Claims, alleged breaches of this Agreement by Walkzero, or Customer’s termination of this Agreement per Section 12.3 (Termination for Cause).</li>
  <li>(b) Notices to Walkzero must be sent as per the details provided at https://www.walkzero.com/legal#how-to-send-notices-to-walkzero, which may be updated occasionally.</li>
  <li>(c) Notices to Customer should be directed to the billing or technical contact provided to Walkzero, which can be updated by Customer in their account portal. However, Walkzero may issue general or operational notices through email, its website, or the Products. Customer may subscribe to receive updates about this Agreement via email, as described at https://www.walkzero.com/legal#notification-of-updates.</li>
</ul>

<p><span className='font-weight-7 mt-4 mb-4'>20.6 Entire Agreement:</span> This Agreement constitutes the complete and exclusive understanding between the parties regarding its subject matter and supersedes all prior or contemporaneous agreements on the same subject. In the case of any conflict between documents, the main body of this Agreement (Sections 1 through 21, inclusive) will prevail, except that Policies, Product-Specific Terms, and DPA will govern for their specific matters.</p>

<p><span className='font-weight-7 mt-4 mb-4'>20.7 Other Walkzero Offerings:</span> Walkzero also offers additional services that can be used with the Products, which may be subject to separate terms available at https://www.walkzero.com/legal. These offerings include training services, developer tools, and the Walkzero Marketplace. For clarity, this Agreement takes precedence over any separate terms concerning Customer’s use of the Products (including Walkzero Apps).</p>

<p><span className='font-weight-7 mt-4 mb-4'>20.8 Interpretation, Waivers, and Severability:</span> In this Agreement, headings are for convenience only, and terms like "including" are to be interpreted without limitation. Waivers must be granted in writing and signed by the authorized representative of the waiving party. If any provision of this Agreement is deemed invalid, illegal, or unenforceable, it will be enforced to the minimum extent necessary, so the rest of the Agreement remains valid.</p>

<p><span className='font-weight-7 mt-4 mb-4'>20.9 Changes to this Agreement:</span></p>
<ul>
  <li>(a) Walkzero reserves the right to modify this Agreement (including Policies, Product-Specific Terms, and DPA) by posting the modified portion(s) on its website. Walkzero will make reasonable efforts to post any modification at least thirty (30) days before it takes effect.</li>
  <li>(b) For free subscriptions, changes will take effect during the current Subscription Term, in line with Walkzero’s notice.</li>
  <li>(c) For paid subscriptions: <ul>
      <li>(i) Modifications to this Agreement will take effect with the next Order or renewal unless either party chooses not to renew under Section 10.1(c) (Renewals), and</li>
      <li>(ii) Walkzero may specify that changes will apply during the current Subscription Term if: (A) necessary for legal compliance, or (B) needed to reflect updates to Product features or functionalities. If Customer disagrees, it may terminate the remaining portion of the Subscription Term for the affected Products as the exclusive remedy. Customer must notify Walkzero within thirty (30) days of the modification notice to exercise this right, and Walkzero will refund any prepaid fees for the terminated portion of the Subscription Term.</li>
    </ul></li>
</ul>

<p><span className='font-weight-7 mt-4 mb-4'>20.10 Force Majeure:</span> Neither party will be liable for any delay or failure in fulfilling any obligation under this Agreement (except for payment of fees) due to circumstances beyond its reasonable control that occur without fault or negligence.</p>

<p><span className='font-weight-7 mt-4 mb-4'>20.11 Subcontractors and Affiliates:</span> Walkzero may engage subcontractors or affiliates to fulfill its obligations under this Agreement, but Walkzero remains accountable for its overall performance and ensures that appropriate written agreements are in place with subcontractors to meet its obligations.</p>

<p><span className='font-weight-7 mt-4 mb-4'>20.12 Independent Contractors:</span> The parties are independent contractors, not agents, partners, or joint venturers.</p>

<p><span className='font-weight-7 mt-4 mb-4'>20.13 Export Restrictions:</span> The Products may be subject to U.S. export restrictions and the import restrictions of other jurisdictions. Customer must comply with all applicable export and import laws when accessing, using, or downloading the Products or any content within them. Customer must not (and must prevent others from) exporting, re-exporting, transferring, or disclosing the Products or any direct products of the Products: (a) to any U.S. embargoed jurisdiction, (b) to anyone on any U.S. or applicable non-U.S. restricted-party list, or (c) to any party that Customer knows will use the Products in violation of U.S. export laws.</p>

<p><span className='font-weight-7 mt-4 mb-4'>20.14 Government End-Users:</span> If Customer is a U.S. government entity, this Agreement is subject to the Government Amendment available at https://www.walkzero.com/legal/government-amendment.</p>

<p><span className='font-weight-7 mt-4 mb-4'>20.15 No Contingencies:</span> The Products, Support, and Advisory Services in each Order are purchased independently and are not contingent on the purchase or use of other Walkzero products or services, even if listed in the same Order. Customer's purchases are not contingent upon the delivery of future functionality or features.</p>

<h5 className='font-weight-7 mt-4 mb-4'>21. Definitions</h5>   

<p>“Acceptable Use Policy” means Walkzero’s acceptable use policy available at https://www.walkzero.com/legal/acceptable-use-policy.</p>

<p>“Advisory Services” refers to advisory services as outlined in Walkzero’s Advisory Services Policy.</p>

<p>“Advisory Services Policy” refers to Walkzero’s advisory services policy, which can be accessed at https://www.walkzero.com/legal/advisory-services-policy.</p>

<p>“Affiliate” means any entity that directly or indirectly owns or controls, is owned or controlled by, or shares common ownership or control with a party. Here, "ownership" is defined as holding more than fifty percent (50%) of the voting equity or equivalent voting rights, and “control” means the ability to direct the entity's management or operations.</p>

<p>“Agreement” means the Walkzero Customer Agreement, including the Product-Specific Terms, DPA, and the Policies.</p>

<p>“Walkzero Apps” refers to applications developed by Walkzero for use with Cloud Products or Software Products, as indicated in the Walkzero Marketplace.</p>

<p>“Walkzero Marketplace” refers to the online platform where apps for Walkzero products can be purchased, currently branded as the Walkzero Marketplace and accessible at https://marketplace.walkzero.com/.</p>

<p>“Cloud Products” refers to Walkzero’s cloud-based products, including the client software designed for these cloud products (“Cloud Clients”).</p>

<p>“Code of Conduct” refers to Walkzero’s Code of Business Conduct & Ethics, available at https://investors.walkzero.com/governance/governance-documents/default.aspx.</p>

<p>“Customer Data” refers to any data, content, or materials provided to Walkzero by or on behalf of the Customer or its Users via Cloud Products, including data from Third-Party Products.</p>

<p>“Customer Materials” means the materials and resources provided by Customer to Walkzero in connection with Support or Advisory Services.</p>

<p>“Documentation” refers to Walkzero’s usage guidelines and standard technical documentation for the applicable Product, available at https://support.walkzero.com/, unless otherwise specified in the Product-Specific Terms.</p>

<p>“DPA” refers to Walkzero’s data processing addendum, available at https://www.walkzero.com/legal/data-processing-addendum.</p>

<p>“Laws” means all applicable laws, regulations, decrees, orders, judgments, and codes enacted or decreed by any government body (whether local, state, federal, or international) with jurisdiction.</p>

<p>“Order” refers to Walkzero’s ordering document, online registration, or other order process that Walkzero facilitates, detailing the Products, Support, or Advisory Services to be delivered under this Agreement, accepted by Walkzero as outlined in Section 9 (Ordering Process and Delivery).</p>

<p>“Policies” refers to the Acceptable Use Policy, Advisory Services Policy, guidelines for reporting Copyright and Trademark Violations, Privacy Policy, Security Measures, Service Level Agreement, Support Policy, Third-Party Code Policy, and any other Walkzero policies included in Product-Specific Terms.</p>

<p>“Privacy Policy” refers to Walkzero’s privacy policy available at https://www.walkzero.com/legal/privacy-policy.</p>

<p>“Products” refers to the Cloud Products or Software Products made available by Walkzero in connection with an Order. This includes Walkzero Apps.</p>

<p>“Product-Specific Terms” means terms that apply only to certain Products, available at https://www.walkzero.com/legal/product-terms.</p>

<p>“Reseller” means a partner authorized by Walkzero to resell Walkzero’s Products, Support, and Advisory Services to customers.</p>

<p>“Scope of Use” refers to the Customer’s rights to use the Products, which may be limited based on factors like the number of licenses, copies, or instances, specific business entities, divisions, business units, websites, or other usage parameters.</p>

<p>“Security Measures” refers to Walkzero’s security practices available at https://www.walkzero.com/legal/security-measures.</p>

<p>“Service Level Agreement” means any service level commitments for Cloud Products as described at https://www.walkzero.com/legal/sla.</p>

<p>“Software Products” refers to Walkzero’s installed software products and any updates, bug fixes, or upgrades made available to the Customer, including those provided through Support.</p>

<p>“Subscription Term” refers to the duration for which Customer is granted access to the Products and associated Support and Advisory Services, as identified in an Order.</p>

<p>“Support” refers to the level of support for the Products based on the Customer’s Scope of Use, as outlined in the Support Policy.</p>

<p>“Support Policy” refers to Walkzero’s documentation outlining support offerings, available at https://confluence.walkzero.com/support/walkzero-support-offerings-193299636.html.</p>

<p>“Third-Party Code Policy” refers to Walkzero’s third-party code policy available at https://www.walkzero.com/legal/third-party-code-policy.</p>

<p>“User” means any individual authorized by Customer to use the Products. Users may include: (i) employees, consultants, contractors, and agents of the Customer and its Affiliates, (ii) third parties with whom the Customer or its Affiliates do business, (iii) individuals invited by Customer’s users, (iv) individuals with managed accounts, or (v) individuals interacting with a Product as a Customer’s customer.</p>

            </div>
        </div>
        <MobMenu/>

  
    </>
  )
}

export default TermsAndCondition;