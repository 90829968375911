import { Link } from "react-router-dom"
import { TailSpin } from "react-loader-spinner"
import { useState, useEffect, useContext } from "react"
import {useAnimation, motion} from "framer-motion"
import { useInView } from "react-intersection-observer";
import { container2, child1, child2 } from "../../../../variants"
import { Helmet } from 'react-helmet-async'

import  DashBoard from "../../../../assets/logos/leave-dashboard.svg"
import {ReactComponent as Left} from "../../../../assets/logos/logo-left.svg"
import {ReactComponent as TopW} from "../../../../assets/logos/top-w-curve.svg"
import {ReactComponent as BottomW} from "../../../../assets/logos/bottom-w-curve.svg"
import {ReactComponent as ButtonLogo} from "../../../../assets/logos/button-logo.svg"
import AutoPlay from '../../../layout/industry-slide'
import {data, settings} from "../../../landing-page/home"
import LeaveMerits from '../leave-merits'
import LeaveSlide from '../leave-manage-slide'
import SecureLeaveManage from "../secure-leave-manage"
import Faqs from "../../../landing-page/faqs"
import HrTasks from "../../hr-hub/hr-tasks"
import Modules from "../modules"
import SlideItem from "../../../landing-page/slide-items"
import { settings1, settings2 } from "../../../landing-page/leave"
import Card from "../../../landing-page/card"
import './index.scss'
import { BaseBtn } from "../../../../styled-components";
import { MenuContext } from "../../../../app";

type FaqData = {
    faq:{
        question:string 
        answer:string 
        order: number
    }[]
}

const initialData = {
    faq:[]
}

const leaveKeywords1 =[
    {id:"slide0", name:"Tailor leave policies"},
    {id:"slide1", name:"Customizable leave types"},
    {id:"slide2", name:"Flexible leave management"},
    {id:"slide3", name:"Time-off management"},
    {id:"slide4", name:"Holiday planning software"},
    {id:"slide5", name:"Holiday coordination tools"},
    {id:"slide6", name:"Team leave coordination"},
    {id:"slide7", name:"Leave Approval "},
    {id:"slide8", name:"Efficient leave approval processes"},
]

const leaveKeywords2 =[
    {id:"slide0", name:"Transparent leave workflows"},
    {id:"slide1", name:"Comprehensive leave records"},
    {id:"slide2", name:"Leave analytics and insights"},
    {id:"slide3", name:"Informed leave decision-making"},
    {id:"slide4", name:"Track leave trends"},
    {id:"slide5", name:"Year-end leave process automation"},
    {id:"slide6", name:"Accurate leave balance calculations"},
    {id:"slide7", name:"Seamless year-end leave transition"},
    {id:"slide8", name:"Automated leave management"},
]

const LeaveMain = () => {

    const [faqs, setFaqs] = useState<FaqData>(initialData);
    const [isLoading, setIsLoading] = useState(true)

    const {isOn} = useContext(MenuContext)

    const getFaqs = async() => {
        setIsLoading(true)
        const url = `${process.env.REACT_APP_API_URL}/faq?page=leave management`;
        const options = {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            }
        }

        try{
            const response = await fetch(url, options)
            if(response.ok){
                setIsLoading(false)
                const faqs = await response.json()
                setFaqs(faqs.data)
            }
        }
        catch(error:any){
            console.error(`error in fetching faqs: ${error.message}`)
        }
    }

    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: false,
      });


    useEffect(() => {
        getFaqs()

        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView])

    const child2w = {
        visible: {
          x:0,
          y:0,
          rotate:0,
          transition: {
            duration: 1.5,
            type:'tween'
          }
        },
        hidden: {
          x:-1700,
          y:350,
          rotate:90,
        }
      }
      const Top = {
        visible: {
          x:0,
          y:0,
          transition: {
            duration: 1,
            delay: 1,
            type:'tween'
          }
        },
        hidden: {
          x:0,
          y:-500,
        }
      }
    
      const Bottom = {
        visible: {
          x:0,
          y:0,
          transition: {
            duration: 1,
            delay: 1,
            type:'tween'
          }
        },
        hidden: {
          x:0,
          y:170,
        }
      }


  return (
    <>
    <Helmet>
        <title>Leave Management | Walkzero Technologies Private Limited</title>
        <meta name="description" content="Improve employee experience with a user-friendly self-service leave management solution for seamless time-off tracking."/>
        <meta name="keywords" content="leave management system, employee leave tracking, HR self-service, automated time-off requests, absence management"/>   
    </Helmet>
    <motion.div ref={ref} variants={container2} initial="hidden" animate={ctrls} id="hrMain" className="hr-excellence d-flex flex-column position-relative bg-col-new-light-yellow">
        <motion.div className="product-inner-cont d-flex flex-row justify-content-between align-items-center align-self-center">
            <motion.div variants={child1} className="step-into-card d-flex flex-column">
                <p className='text-12 col-quaternary font-weight-5 text-12 mb-1 text-uppercase'>Streamlined Leave Management for a Productive Workforce</p>
                <h3 className='product-heading col-container-font h1-size'>Peace of Mind, Every Time: <span className='col-secondary'> Streamlined </span>Leave Management Solutions!</h3>
                <p className='font font-weight-3 m-b-20 p-size'>Efficiently manage leave with tailored policies and seamless approvals. Gain insights and automate year-end processes for a hassle-free experience.</p>
                <div className="m-r-20 position-relative mob-width z-index-1">
                    <Link to="/sign-up" className="decoration">
                    <BaseBtn isOn={isOn} className="position-relative border-0 p-y-10 rounded-10 d-flex flex-row justify-content-center align-items-center w-100 btn-4">
                        <ButtonLogo className="me-1 mb-1"/> 
                        <span className="col-white font-bold text-14 font-weight-700 z-index-0">Sign up for Free</span>
                    </BaseBtn>
                    </Link>
                </div>
            </motion.div>
            <motion.div variants={child2} className="d-flex flex-column justify-content-center align-items-center position-relative second-s">
                <motion.img initial={{opacity: 0}} animate={{opacity: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]}} transition={{duration: 3, delay: 2, ease: 'easeOut',type:"keyframes"}} src={DashBoard} className='leave-img' alt="calendar" />
                <div className="position-absolute bgnd-leave z-index-0 rounded-circle">
                </div>
                <motion.div variants={child2w} initial="hidden" animate="visible" className="position-absolute p-0 z-index-0">
                    <Left style={{opacity:0.6}} id="Leftw-l"/>
                </motion.div>
                <motion.div variants={Top} initial="hidden" animate="visible" className="position-absolute leave-top">
                    <TopW id='leave-top' style={{opacity:0.6}}/>                                                
                </motion.div>
                <motion.div variants={Bottom} initial="hidden" animate="visible" className="position-absolute p-0 leave-bottom">
                    <BottomW id='leave-bottom' style={{opacity:0.6}}/>
                </motion.div>
            </motion.div>
        </motion.div>
        <div className="custom-shape-divider-bottom-1715923248">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
            </svg>
        </div>
    </motion.div>
    <AutoPlay data={data} settings={settings} />
    <LeaveSlide />
    <LeaveMerits />
    <div className="position-relative d-block">
        <SecureLeaveManage/>
    </div>
    {isLoading? <div className="align-self-center m-t-100 m-b-100"><TailSpin  visible={true} height="30" width="30" color="#f45600" ariaLabel="tail-spin-loading" radius="1" /></div>:<Faqs faqs={faqs} setFaqs={setFaqs} />}
    <HrTasks />
    <Modules />
    <div className="d-flex flex-column p-b-100 hr-product-slide">
        <SlideItem data={leaveKeywords1} settings={settings1} width={{width:"70%"}}/>
        <SlideItem data={leaveKeywords2} settings={settings2} width={{width:"50%"}}/>
    </div>
    <Card />
    </>
  )
}

export default LeaveMain