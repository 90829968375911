import { Link } from "react-router-dom"
import { useEffect } from "react"
import {useAnimation, motion} from "framer-motion"
import { Breadcrumbs, Typography } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { container2, child2  } from "../../../variants"
import { Helmet } from 'react-helmet-async'

import {ReactComponent as Left} from "../../../assets/logos/logo-left.svg"
import {ReactComponent as TopW} from "../../../assets/logos/top-w-curve.svg"
import {ReactComponent as BottomW} from "../../../assets/logos/bottom-w-curve.svg"
import './index.scss'
import MobMenu from '../mob-menu'





const PrivacyPolicy = () => {



    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: false,
      });

    useEffect(() => {
        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView])


    const child2w = {
        visible: {
          x:0,
          y:0,
          rotate:0,
          transition: {
            duration: 1.5,
            type:'tween'
          }
        },
        hidden: {
          x:-1700,
          y:350,
          rotate:90,
        }
      }
      const Top = {
        visible: {
          x:0,
          y:0,
          transition: {
            duration: 1,
            delay: 1,
            type:'tween'
          }
        },
        hidden: {
          x:0,
          y:-500,
        }
      }
    
      const Bottom = {
        visible: {
          x:0,
          y:0,
          transition: {
            duration: 1,
            delay: 1,
            type:'tween'
          }
        },
        hidden: {
          x:0,
          y:200,
        }
      }


  return (
    <>
    <Helmet>
        <title>PRIVACY POLICY | Walkzero Technologies Private Limited</title>
    </Helmet>
    <motion.div ref={ref} variants={container2} initial="hidden" animate={ctrls} id="hrMain" className="hr-excellence d-flex flex-column position-relative bg-col-new-light-green">
        <motion.div  className="product-inner-cont-privacy d-flex flex-row justify-content-center align-items-center align-self-center">
        
            <motion.div style={{height:'400px'}} variants={child2} className="d-flex flex-column justify-content-center align-items-center position-relative second-privacy-s mt-20">
                <motion.div style={{zIndex:1}} initial={{opacity: 0}} animate={{opacity: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 1]}} transition={{duration: 3, delay: 2, ease: 'easeOut', type:"keyframes"}} className="d-flex flex-row justify-content-center align-items-center align-self-center pt-0">
            <motion.div  className="step-into-card-privacy d-flex flex-column position-relative justify-content-center align-items-center">
         

                <h3  className='product-privacy-heading col-container-font h1-size text-center'>Privacy <span className='col-secondary'>Policy </span></h3>
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
                Home
                </Link>
                <Typography color="text.primary">Privacy Policy</Typography>
            </Breadcrumbs>
             </motion.div>
            </motion.div>




                <div className="position-absolute bgnd-prod z-index-0 rounded-circle">
                </div>
                <motion.div variants={child2w} initial="hidden" animate="visible" className="position-absolute p-0 z-index-0">
                    <Left style={{opacity:0.6}} id="Leftw"/>
                </motion.div>
                <motion.div variants={Top} initial="hidden" animate="visible" className="position-absolute hr-top-pr">
                    <TopW id='Payroll-top' style={{opacity:0.6}}/>
                </motion.div>
                <motion.div variants={Bottom} initial="hidden" animate="visible" className="position-absolute p-0 hr-bottom-pr">
                    <BottomW id='Payroll-bottom' style={{opacity:0.6}}/>
                </motion.div>
            </motion.div>
        </motion.div>
        <div className="custom-shape-divider-bottom-1715923248">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
            </svg>
        </div>
    </motion.div>
    <div className="d-flex flex-column justify-content-center align-self-center privacy-policy ">
  
            <div className='mb-4'>
                <h2 className='font-weight-7 mt-4 mb-4 policy-heading-2'>Privacy Policy</h2>

                <p>At Walkzero Technologies Private Limited (referred to as ‘Walkzero,’ ‘we,’ or the ‘Company’),
                we recognize the importance of the trust you place in us by sharing your confidential
                information. We want to be transparent about our practices regarding the collection and use
                of information when you interact with our services.Walkzero offers a comprehensive cloud-
                based platform designed to streamline organisational processes, including our web platform
                and Walkzero Mobile Apps. Additionally, we operate the Walkzero.com website.</p>

                <p>Users of Walkzero services may include entities such as companies that have entered into
                agreements with Walkzero or with our authorised resellers and distributors (“Customer”), as
                well as individuals such as the employees of those companies or visitors to our website
                (“end user(s)”). For simplicity, we refer to both Customers and end users collectively as
                “users” or “you.”</p>

                <p>This Privacy Policy explains Walkzero's approach to the collection, use, access, correction,
                and disclosure of your personal information on Walkzero.com (the “Site”) and within our
                Mobile Apps. This policy does not extend to personal information that Walkzero gathers on
                behalf of, or under the instructions of, its clients.When you log in to the Site, you will be
                directed to the privacy policy of your employer or prospective employer, who is a client of
                Walkzero. Their policy will detail how they manage your personal information and outline
                your rights regarding that information. If our clients request changes to your personal
                information, such as amendments, updates, or deletions, Walkzero will carry out these
                requests in line with the terms of our contract with the client.</p>

                <p>Personal information includes any data that can reasonably identify you, either on its own or
                when combined with other information (“Personal Information”). This Privacy Policy also
                addresses any Personal Information you provide that is used to market our services,
                features, or content (the “Services”) to our Clients, and to assist you with support related to
                our Services and Mobile Apps.</p>

                <h2 className='font-weight-7 mt-5 mb-5'>What We Collect and How We Utilise It</h2>

                <h5 className='font-weight-7 mt-4 mb-4'>Personal Information</h5>

                <p>Walkzero collects personal information that you, your employer, or administrators provide
                voluntarily, as well as data generated through your use of the Walkzero platform. This may
                include:</p>

                <ul>
                    <li ><span className='privacy-info'>Personal Details:</span> Name, contact information, date of birth, gender, nationality.</li>
                    <li><span className='privacy-info'>Employment Information:</span> Job title, department, employee ID, salary details,
                    employment dates, work authorization, and tax-related information.</li>
                    <li><span className='privacy-info'>Banking Information:</span> Bank name, account number, branch address.</li>
                    <li><span className='privacy-info'>Family Information:</span> Details about your spouse and dependents.</li>
                    <li><span className='privacy-info'>Emergency Contact Information:</span> Name, relationship, contact details.</li>
                    <li><span className='privacy-info'>Technical Data:</span> IP address, unique identifiers.</li>
                </ul>

                <p>This information helps us provide and improve our services.</p>

                <h5 className='font-weight-7 mt-4 mb-4'>Location Information</h5>

                <p>Walkzero does not collect or track location data from your mobile device when using our
                Mobile Apps. If your employer uses the Walkzero Mobile App, they may enable location
                tracking for timekeeping, based on your employer’s requirements. The GDPR (General Data
                Protection Regulation) basis for this processing is our contractual obligation with your
                employer.</p>

                <h5 className='font-weight-7 mt-4 mb-4'>Attendance Logs</h5>

                <p>If our Customer (Employer) selects the optional Time and Attendance Software, we will
                process biometric logs to track attendance and calculate payroll according to the Customer’s
                configuration. This data includes employee or attendance IDs and the timestamps for clock-
                in and clock-out events.</p>

                <h5 className='font-weight-7 mt-4 mb-4'>Device Information</h5>

                <p>When using our Mobile Apps, we may request access to your device’s camera, photo
                storage, and microphone. This access is for the purpose of taking and uploading pictures,
                recording audio, and accessing your photos. These permissions are only utilized based on
                your actions and can be revoked at any time through your device settings. We do not access
                your camera, photo storage, or microphone without your explicit permission.</p>

                <h5 className='font-weight-7 mt-4 mb-4'>Data Collected as a Service Provider</h5>

                <p>As a service provider, Walkzero collects information based solely on the requirements set by
                our Customers (employers). Our Master Subscription Agreement outlines the terms for
                delivering, accessing, and using our Services and Mobile Apps, including the processing of
                Personal Information and data submitted through Service accounts. The Customer (e.g.,
                your employer) manages their Platform and any related client data. For questions about
                specific Platform settings, data processing, or privacy practices, please contact the
                Customer administrator of the Platform you use.</p>

                <p>Walkzero uses Customer data according to the Customer’s instructions, the terms of the
                Master Service Agreement, and applicable laws. Under GDPR, Walkzero acts as a
                processor of Customer data, while the Customer is the data controller.</p>

                <h5 className='font-weight-7 mt-4 mb-4'>Google Account Integration</h5>

                <p>Walkzero will use and handle information obtained from Google APIs in accordance with
                Google API Services User Data Policy, including adhering to the Limited Use requirements.</p>

                <h5 className='font-weight-7 mt-4 mb-4'>Sharing of your information</h5>

                <p>We work with Sub-Processors, such as email and customer support providers, to perform
                tasks on our behalf. We may share your Personal Information with them as needed to
                provide services. Sub-Processors are only allowed to use your information for these specific
                tasks. Transfers to third parties are governed by agreements between us and our Sub-
                Processors.</p>

                <div className='d-flex justify-content-center align-self-center privacy-table'>
                    <table>
                        <tr className='font-weight-7'>
                            <th>Name</th>
                            <th>Purpose</th>
                        </tr>
                        <tr>
                            <td>Google Cloud</td>
                            <td>For rendering google maps in the browser to mark
                            employee location associated with their attendance.</td>
                        </tr>
                        <tr>
                            <td>AWS</td>
                            <td>For Hosting Walkzero.</td>
                        </tr>
                        <tr>
                            <td>Google App</td>
                            <td>Google Mail for Email Communication.</td>
                        </tr>
                    </table>
                </div>

                <h5 className='font-weight-7 mt-4 mb-4'>Where do we store your Data?</h5>

                <p>The data we collect is securely hosted on the AWS cloud, located in the AP South India
                region. Walkzero&#39;s headquarters is also based in South India, where we provide our
                customer support services. While our support team operates from this location, no customer
                data is stored at our headquarters, with the exception of customer call data, which is kept for
                service quality and training purposes. All other customer information remains securely stored
                on the AWS cloud, ensuring high standards of data protection and compliance.</p>

                <h5 className='font-weight-7 mt-4 mb-4'>Changes to the Privacy Policy</h5>

                <p>The terms of this Privacy Policy govern your use of our Service and any information
                collected in connection with it. Walkzero may amend or update this Privacy Policy from time
                to time.</p>

                <p>Unless otherwise agreed with the Customer, we will aim to notify you of significant changes
                to this policy on our website&#39;s homepage and, if applicable, via email. Material changes will
                take effect 10 days after notice is provided on our website or sent via email. All other
                changes will be effective as of the &quot;Last Revised&quot; date stated, and your continued use of our
                Services will signify your acceptance and agreement to the updated Privacy Policy.</p>

                <p>If you have any questions (or comments) concerning this Privacy Policy, you are welcome to
                send us an email or otherwise contact us at <a href="mailto:support@walkzero.com?Subject=Hello%20" target="_top" className="col-secondary">support@walkzero.com</a> and we will make an
                effort to reply within a reasonable timeframe, and not over 30 business days.</p>
            </div>
        </div>
        <MobMenu/>

  
    </>
  )
}

export default PrivacyPolicy;