import './index.scss'
import { Link, useLocation } from "react-router-dom"
import { useContext } from "react"
import { MenuContext } from "../../../app"
import {motion} from "framer-motion"
import { IoIosSearch } from "react-icons/io";
import { IoMdArrowDropup } from "react-icons/io";
import { data1, data2, data3 } from "../../product-menu/product-menu"

const MobileNav = () => {

    const props = useContext(MenuContext);
    const {isOpen, setIsOpen, isOn, searchInput, setSearchInput, setBlogList, setIsLoading, mobProduct, setMobProduct, setIsMobProductOpen, setModules} = props

    const location = useLocation();
    const showBlogNav = location.pathname === "/blogs" || location.pathname.includes("/blog/");

    const searchBlogs = async() => {
        setIsLoading(true)
        const url = `${process.env.REACT_APP_API_URL}/blogs?search=${searchInput}`;

        const options = {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            }
        }  

        try{
            const response = await fetch(url, options)
            if(response.ok){
                setIsLoading(false)
                const blogs = await response.json()
                setBlogList(blogs)
            }
        }
        catch(error:any){
            console.error(`error in fetching blogs: ${error.message}`)
        }
    }

    const searchBlog = (event:React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value)
    }

    const typeSearch = (event:React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key === 'Enter'){
            searchBlogs()
        }
    }

    const toggleMenu = () => {
        setMobProduct((prevMobProduct:boolean) => !prevMobProduct)
    }

    const products = (name:string) =>{
        setIsOpen((prevIsOpen:boolean) => !prevIsOpen)
        setIsMobProductOpen(true)
        if(name === "HR"){
            setModules(data2)
        }
        else if(name === "Payroll"){
            setModules(data3)
        }

    }

    return(
        <>
        {showBlogNav === false?
        <motion.div initial={{y:"150vh"}} animate={{y:isOpen?0:"150vh"}} transition={{duration:0.5,type:"tween"}} className="bg-col-white d-flex flex-column align-items-center rounded-15 popup position-fixed z-index-99 bottom-55 left-5">
            <div className="marquee-container"><p className="marquee-text text-10 " style={{color:isOn?"#1A2D48":"#F45600"}}>"Chennai Roots, Silicon Valley Vision: Welcome to Walkzero's Next Gen HRMS." #IndiaSaas #SaasBoomi</p></div>
            <Link to="/" className="prod-link menu-item-box" onClick={() => setIsOpen(false)}><span></span>Home</Link>
            <span className="prod-link menu-item-box" onClick={toggleMenu}><span>Product</span><IoMdArrowDropup style={{rotate:mobProduct?"0deg":"-180deg", transition:"rotate 0.4s ease"}} className='ml-170'/></span>
            {mobProduct && <motion.div className='align-self-start' initial={{ y: '100%', opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5, type: 'tween' }}>
                {data1.map((each:any)=>
                <ul key={each.id} className="mob-products">
                    <li className="mob-prod-list" onClick={()=>{products(each.name)}}>{each.name}</li>
                </ul>
            )}
            </motion.div>}
            <Link to="/pricing" className="prod-link menu-item-box" onClick={() => setIsOpen(false)}><span>Pricing</span></Link>
            <Link to="/partnership" className="prod-link menu-item-box" onClick={() => setIsOpen(false)}><span>Partnership</span></Link>
            <Link to="/about-us" className="prod-link menu-item-box" onClick={() => setIsOpen(false)}><span>About us</span></Link>
            <Link to="/blogs" className="prod-link menu-item-box" onClick={() => setIsOpen(false)}><span>Blogs</span></Link>
            <Link to="/login" className="prod-link menu-item-box" onClick={() => setIsOpen(false)}><span>Login</span></Link>
            <div className="d-flex flex-column justify-content-center align-items-start w-100 border-top">
                <Link to='/book-a-demo' style={{color:isOn?"#1A2D48":"#F45600"}} className="w-50 decoration" onClick={() => setIsOpen(false)}>
                    Book a Demo
                </Link>
            </div>
        </motion.div>:
        <motion.div initial={{y:"150vh"}} animate={{y:isOpen?0:"150vh"}} transition={{duration:0.5,type:"tween"}} className="bg-col-white d-flex flex-column align-items-center rounded-15 blog-popup position-fixed z-index-4 bottom-55 left-5">
        <div className="marquee-container"><p className="marquee-text text-10 col-secondary">"Chennai Roots, Silicon Valley Vision: Welcome to Walkzero's Next Gen HRMS." #IndiaSaas #SaasBoomi</p></div>
        <Link to="/" className="link menu-item-box" onClick={() => setIsOpen(false)}><span>Home</span></Link>
        <Link to="" className="link menu-item-box" onClick={() => setIsOpen(false)}><span>Latest Blogs</span></Link>
        <Link to="" className="link menu-item-box" onClick={() => setIsOpen(false)}><span>Product Updates</span></Link>
        <Link to="/about-us" className="link menu-item-box" onClick={() => setIsOpen(false)}><span>Company News</span></Link>
        <div className='d-flex flex-row align-items-center search-box w-100 m-t-10'>
            <IoIosSearch className='search-icon'/>
            <input type="search" placeholder='search' className='search-input' value={searchInput} onChange={searchBlog} onKeyDown={typeSearch} />
        </div>
        {/* <div className="d-flex flex-row justify-content start align-items-center w-100 mt-20 blog-mob">
            <Link to='/book-a-demo' className="demo w-50" onClick={() => setIsOpen(false)}>
                Book a Demo
            </Link>
        </div> */}
    </motion.div>
        }
        </>
    )
}

export default MobileNav