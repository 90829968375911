import "./index.scss"
import { Link, useParams } from "react-router-dom"
import { motion } from 'framer-motion';
import {ReactComponent as Logo} from "../../../assets/logos/logo.svg"
import { data2 } from "../../product-menu/product-menu";
import { useContext, useEffect, useState } from "react";
import { MenuContext } from "../../../app";
import HeroSection from "../../payroll/banner-components/banner-main"
import Avatar from "../../../assets/images/avatar.png"
import {ReactComponent as Star} from '../../../assets/logos/star.svg'
import {ReactComponent as Tick} from '../../../assets/logos/tick.svg'
import {ReactComponent as Close} from '../../../assets/logos/close-popup.svg'
import {ReactComponent as  Apple} from '../../../assets/logos/apple.svg';
import {ReactComponent as Android} from '../../../assets/logos/android.svg';
import {ReactComponent as Soc} from '../../../assets/logos/soc.svg';
import {ReactComponent as Iso} from '../../../assets/logos/iso.svg';
import {ReactComponent as Gdpr} from '../../../assets/logos/gdpr.svg';
import {ReactComponent as TopDesign} from '../../../assets/logos/top-signup-design.svg';
import {ReactComponent as BottomDesign} from '../../../assets/logos/bottom-signup-design.svg';
import { ACCOUNT } from "../../../graphql/queries";
import { ACTIVATE_ACCOUNT } from "../../../graphql/mutation";
import { TailSpin } from "react-loader-spinner";

const url = `${process.env.REACT_APP_WZ_URL}`;

const initialData = {
    email:'',
    name:'',
    fullname:'',
    phone:'',
    industry:'',
    domain:'',
    size:''
}

const status = {
    initial:"Initial",
    inProgress:"Progress",
    success:"Success",
    failed:"Failed"
}

const ActivateAccount = () => {

    const {setIsOpen, setIsProductOpen, setModules} = useContext(MenuContext)
    const params = useParams()
    const token = params.id
    
    const [account, setAccount] = useState(initialData)  
    const [isActive, setActive] = useState(false)
    const [msg, setMsg] = useState('')
    const [show, setShow] = useState(false)
    const [progress, setProgress] = useState(status.initial)

    const clickLogo = () => {
        setIsOpen(false)
        setIsProductOpen(false)
        setModules(data2)
    }

    const getAccount = async() => {
        setProgress(status.inProgress)
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                query: ACCOUNT,
                variables: { token },
                }),
            })

            if (response.ok) {
                
                const res = await response.json();
                const {data, errors} = res
                if(data){
                    setProgress(status.success)
                    setAccount(data.accountStatus)
                }
                if(errors){
                    setProgress(status.success)
                    setActive(true)
                    setMsg(errors[0].token[0])
                }
                return res;
            }
        }
        catch(error:any){
            console.error(`error in signup: ${error.message}`)
        }    
    }

    const activate = async() => {

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                query: ACTIVATE_ACCOUNT,
                variables: { token },
                }),
            })

            if (response.ok) {
                
                const res = await response.json();
                const {data, errors} = res
                if(data){
                    console.log(data)
                    if(data.activateAccount.success){
                        setActive(true)
                        setMsg('Account is Activated')
                    }
                    setShow(false)
                }
                if(errors){
                    setShow(false)
                    setActive(true)
                    setMsg(errors[0].token[0])
                }
                return res;
            }
        }
        catch(error:any){
            console.error(`error in signup: ${error.message}`)
        }    
    }

    const showPopup = () => {
        setShow(prevShow => !prevShow)
    }

    const closePopup = () => {
        setShow(false)
    }

    useEffect(() => {
        getAccount()
    }, [])

    const {fullname, domain, email, industry, name, size, phone} = account

    return (
        <>
          <div  className="signup-flex-container d-flex flex-row align-items-start">
            <div style={{filter: show ? "blur(3px)" : ''}} className="d-flex flex-column justify-content-start align-items-center  sign-up-first-cont  p-b-10 bg-col-container-bg">
              <div className="top-design">
                <TopDesign className="top-design-svg" />
              </div>
              <div className="bottom-design">
                <BottomDesign className="bottom-design-svg" />
              </div>
              <div className="d-flex flex-row justify-content-start p-x-15  w-100 align-self-start logo-container-signup">
                <Link to="/" onClick={clickLogo}>
                  <Logo width={"63%"} className="logo-height" />
                </Link>
              </div>
              <div>
                <div style={{ marginTop: '100px' }} className="d-flex flex-column justify-content-center align-items-center">
                  <h4 className="d-flex align-items-center text-12"> Payroll  <span className="col-secondary ms-1">  Management</span> </h4>
                  <h4 className="d-flex text-12 font-1 align-items-center m-l-5 col-gray-900 text-center">Simplified Payroll with Walkzero:</h4>
                  <h4 className="d-flex text-12 font-1 align-items-center m-l-5 col-gray-900 text-center">Smarter, Faster, Easier</h4>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <motion.div className="z-index-1 w-90 d-flex justify-content-center align-items-center" initial={{ opacity: 0 }} animate={{ opacity: [0, 0.2, 0.3, 0.4, 0.6, 0.8, 0.9, 1] }} transition={{ duration: 2, delay: 1, ease: 'easeOut' }}>
                    <HeroSection className='w-100 z-index-1' />
                  </motion.div>
                </div>
              </div>
              <div className="testimonial-section d-flex flex-row justify-content-center align-items-center p-4  rounded-3">
                <img className="testimonial-avatar rounded-circle me-3" src={Avatar} alt="Aravinthan Menon" width="80" height="80" />
                <div className="w-60  p-0">
                  <p className="text-12 font-1 align-self-start m-l-5 col-gray-900">
                    <span className="font-weight-5">Amazing Product with new user Interface -</span> Walkzero has revolutionized the way we handle HR tasks, simplifying complexities and significantly reducing time investments.
                  </p>
                  <p className="text-12 font-1 align-self-start m-l-5 col-gray-900">Rajasekaran Arul</p>
                  <p className="text-muted text-12 font-1 align-self-start m-l-5 col-gray-900">GM / Page Industries</p>
                </div>
              </div>
            </div>
            <div className="center-certificates d-flex justify-content-center align-items-center">
                <div className="d-flex flex-column  p-2 bg-body inner-certificates">
                  <Soc className="certificate-icons"/>
                  <Iso className="certificate-icons"/>
                  <Gdpr className="certificate-icons"/>
                </div>
            </div>
            <div style={{ justifyContent: isActive ? "center" : "start",height:"100vh",zIndex:55, filter: show ? "blur(3px)" : '' }} className="position-relative sign-up-second-cont1 bg-white d-flex flex-column p-t-30 p-b-10">
            <div style={{ position: 'absolute', top: '20px', right: '20px' }} className="back-to-home">
                    <h4 className="text-12 font-1  m-l-5 col-gray-900">Back to <Link style={{textDecoration:"none"}} className="col-secondary ms-1" to='/'> Home</Link></h4>
                  </div>          

              {progress === status.inProgress && (
                <div className="align-self-center">
                  <TailSpin visible={true} height="30" width="30" color="#f45600" ariaLabel="tail-spin-loading" radius="1" />
                </div>
              )}
              {progress === status.success && (
                <>
                  <div className="d-flex flex-column align-items-center account-cont align-self-center position-relative">

                  
                    {isActive && <h2 className="col-secondary text-22 mb-0 align-self-center m-t-10 text-center">{msg}</h2>}
                    {!isActive && (
                      <>
                     
                        <h2 className="col-container-font text-22 mb-0 align-self-start m-t-10 m-l-15">Account <span className='col-secondary'>Activation</span></h2>
                        <span className="m-b--5 font-1 font-weight-3 text-10 label-clr align-self-start z-index-1 m-l-20 m-t-15">Owner Name</span>
                        <div className="label-box">
                          <span className="font-1 text-13 font-weight-3 label-clr">{fullname}</span>
                        </div>
                        <span className="m-b--5 font-1 font-weight-3 text-10 label-clr align-self-start z-index-1 m-l-20 m-t-20">Work email ID</span>
                        <div className="label-box">
                          <span className="font-1 text-13 font-weight-3 label-clr">{email}</span>
                        </div>
                        <span className="m-b--5 font-1 font-weight-3 text-10 label-clr align-self-start z-index-1 m-l-20 m-t-20">Phone Number</span>
                        <div className="label-box">
                          <span className="font-1 text-13 font-weight-3 label-clr">{phone}</span>
                        </div>
                        <span className="m-b--5 font-1 font-weight-3 text-10 label-clr align-self-start z-index-1 m-l-20 m-t-20">Company Name</span>
                        <div className="label-box">
                          <span className="font-1 text-13 font-weight-3 label-clr">{name}</span>
                        </div>
                        <span className="m-b--5 font-1 font-weight-3 text-10 label-clr align-self-start z-index-1 m-l-20 m-t-20">Industry Type</span>
                        <div className="label-box">
                          <span className="font-1 text-13 font-weight-3 label-clr">{industry}</span>
                        </div>
                        <span className="m-b--5 font-1 font-weight-3 text-10 label-clr align-self-start z-index-1 m-l-20 m-t-20">Employee Count</span>
                        <div className="label-box">
                          <span className="font-1 text-13 font-weight-3 label-clr">{size}</span>
                        </div>
                        <span className="m-b--5 font-1 font-weight-3 text-10 label-clr align-self-start z-index-1 m-l-20 m-t-20">Preferred Domain</span>
                        <div className="label-box">
                          <span className="font-1 text-13 font-weight-3 label-clr">{domain}</span>
                        </div>
                        <Star className="position-absolute big-star" />
                        <Star style={{ width: 20, height: 20 }} className="position-absolute small-star" />
                      </>
                    )}
                    {!isActive && (
                      <div className="d-flex flex-row justify-content-between align-items-center w-95 m-t-30">
                        <button className="cancel-btn rounded-9 btn-4-alternate" type="button">Cancel</button>
                        <button className="activate-btn rounded-9 btn-4" onClick={showPopup} type="button">Activate</button>
                      </div>
                    )}
                  </div>
                  {isActive && (
                    <>
                      <Star className="position-absolute top-60 right-50" />
                      <Star style={{ width: 20, height: 20 }} className="position-absolute top-90 right-80" />
                    </>
                  )}
                   <div className='d-flex flex-row justify-content-center align-items-center w-100 mt-70 new-download-container gap-3'>
           <Apple />
           <Android/>
          </div>
                </>
              )}
            </div>
            {show && (
              <div className="d-flex flex-column align-items-center bg-col-white activate-popup position-absolute rounded-8 z-index-155 w-35">
                <Close onClick={closePopup} className="align-self-end close-popup-x" />
                <div className="d-flex flex-row justify-content-center align-items-start">
                  <Tick className="popup-tick" />
                  <div className="m-l-15">
                    <h1 className="text-20 font-weight-6 font-1 popup-head">Are you sure that you want to activate {name}?</h1>
                    <p className="text-14 font-weight-4 font-1 popup-para">Ensure all the background verification is completed.</p>
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center w-95 m-t-30">
                  <button className="cancel-btn rounded-9 btn-4-alternate" onClick={closePopup} type="button">No, Cancel</button>
                  <button className="activate-btn rounded-9 btn-4" onClick={activate} type="button">Yes, Confirm</button>
                </div>
              </div>
            )}
          </div>
        </>
      );
    }
export default ActivateAccount