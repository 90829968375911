import './index.scss'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { TailSpin } from 'react-loader-spinner'

type RelatedBlogsProps = {
    categories: string[]
    slug: string
}

type BlogList = {
    blogs:{
     id: string 
     title: string 
     short_description: string
     photo: string
     slug: string
     category:[]
    }[]
}

const RelatedBlogs = (props:RelatedBlogsProps) => {

    const [isLoading, setIsLoading] = useState(true)
    const [blogList, setBlogList] = useState<BlogList>({blogs:[]})

    const {categories, slug} = props

    const getRelatedBlogs = async() => {
        setIsLoading(true)
        
        const url = `${process.env.REACT_APP_API_URL}/related-blogs`;
        const options = {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({ categories, slug }), 
        }  

        try{
            const response = await fetch(url, options)
            if(response.ok){
                setIsLoading(false)
                const blogs = await response.json()
                setBlogList(blogs)
            }
        }
        catch(error:any){
            console.error(`error in fetching related blogs: ${error.message}`)
        }
    }

    useEffect(() => {
        getRelatedBlogs()
    }, [slug])


    return(
        <>
        {blogList.blogs.length > 0 && <div className='w-100 m-t-20 p-b-40 d-flex flex-column'>
            <h1 className="col-container-font h2-size">Related <span className='col-secondary'>Blogs</span></h1>
            {isLoading === false ? <div className='d-flex flex-row justify-content-start align-items-center w-100 m-t-20 blog-cont'>
                {blogList.blogs.map(each => {
                    return <Link to={`/blog/${each.slug}`} key={each.id} className='d-flex flex-column justify-content-start align-items-start blog-items-cont decoration m-r-35 m-b-20'>
                    <img className='w-95 m-l--15' src={each.photo} alt='blog' />
                    <h3 className='text-16 font font-weight-6'>{each.title}</h3>
                    <p className='text-12 font font-weight-3'>{each.short_description}</p>
                    <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                        <p className='font text-12 font-weight-3 p-size'>2 mins to Read..</p>
                        <span className='font-1 text-12 font-weight-6 col-secondary decoration'>Read More...</span>
                    </div>
                </Link>
                })} 
            </div>:<div className="align-self-center m-t-100 m-b-100"><TailSpin  visible={true} height="30" width="30" color="#f45600" ariaLabel="tail-spin-loading" radius="1" /></div>}
        </div>}
        </>
    )
}

export default RelatedBlogs