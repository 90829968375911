import './index.scss'
import Form from '../form'
import { motion } from 'framer-motion';
import AutoPlay2 from '../demo-slide'
import {Link} from "react-router-dom"
import {ReactComponent as Logo} from "../../../assets/logos/logo.svg"
import AnimatedNumber from '../../animation/number'
import {ReactComponent as Education} from "../../../assets/logos/education.svg"
import {ReactComponent as Healthcare} from "../../../assets/logos/health-care.svg"
import {ReactComponent as Crane} from "../../../assets/logos/crane.svg"
import {ReactComponent as Clean} from "../../../assets/logos/clean.svg"
import {ReactComponent as Bus} from "../../../assets/logos/bus.svg"
import {ReactComponent as Food} from "../../../assets/logos/food.svg"
import {ReactComponent as Media} from "../../../assets/logos/media.svg"
import {ReactComponent as Flight} from "../../../assets/logos/flight.svg"
import {ReactComponent as Shopping} from "../../../assets/logos/shopping.svg"
import {ReactComponent as Financial} from "../../../assets/logos/financial.svg"
import {ReactComponent as Computer} from "../../../assets/logos/computer.svg"
import {ReactComponent as  Apple} from '../../../assets/logos/apple.svg';
import {ReactComponent as Android} from '../../../assets/logos/android.svg';
import {ReactComponent as Soc} from '../../../assets/logos/soc.svg';
import {ReactComponent as Iso} from '../../../assets/logos/iso.svg';
import {ReactComponent as Gdpr} from '../../../assets/logos/gdpr.svg';
import {ReactComponent as TopDesign} from '../../../assets/logos/top-signup-design.svg';
import {ReactComponent as BottomDesign} from '../../../assets/logos/bottom-signup-design.svg';
import Avatar from "../../../assets/images/avatar.png"
import { data2 } from "../../product-menu/product-menu";
import { useContext } from "react";
import { MenuContext } from "../../../app";
import AutoPlay from "../../layout/industry-slide";
import HeroSection from "../../payroll/banner-components/banner-main"
import { Helmet } from 'react-helmet-async'
import Carousel from "../../signup/carousel-component";

const data = [
    {id:'field1', icon:<Education className="mr" />, name:'Education'},
    {id:'field2', icon:<Healthcare className="mr"/>, name:'Healthcare'},
    {id:'field3', icon:<Bus className="mr"/>, name:'Automobile'},
    {id:'field4', icon:<Crane className="mr"/>, name:'Manufacturing'},
    {id:'field5', icon:<Food className="mr"/>, name:'Food'},
    {id:'field6', icon:<Clean className="mr"/>, name:'Textiles'},
    {id:'field7', icon:<Computer className="mr"/>, name:'Information\u00A0Technology'},
    {id:'field8', icon:<Financial className="mr"/>, name:'Financial\u00A0Services'},
    {id:'field9', icon:<Shopping className="mr"/>, name:'FMCG'},
    {id:'field10', icon:<Flight className="mr"/>, name:'Transportation/Logistics'},
    {id:'field11', icon:<Media className="mr"/>, name:'Media'},
]

const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed:0,
    cssEase: "linear",
    centerMode:true,
    pauseOnHover:false,
  };


const Main = () => {


      const {setIsOpen, isOn, setIsProductOpen, setModules} = useContext(MenuContext)
    
        const clickLogo = () => {
            setIsOpen(false)
            setIsProductOpen(false)
            setModules(data2)
        }
    return(
        <>
        <Helmet>
            <title>Walkzero: Discover More! Begin Now</title>
        </Helmet>
        <div  className="signup-flex-container d-flex flex-row align-items-start">
       
       <div className="d-flex flex-column justify-content-start align-items-center bg-col-white sign-up-first-cont  p-b-10 bg-col-container-bg">
           <div className="top-design">
               <TopDesign className="top-design-svg"/>
           </div>
           <div className="bottom-design">
               <BottomDesign className="bottom-design-svg"/>
           </div>
       <div className="d-flex flex-row justify-content-start p-x-15  w-100 align-self-start logo-container-signup">
      <Link to="/" onClick={clickLogo}>
        <Logo width={"63%"} className="logo-height" />
      </Link>
       </div>
 <div className=" w-100 carousel-book-component">
 <Carousel/>
 </div>
          
        
           <>
               <div className="testimonial-section d-flex flex-row justify-content-center align-items-center p-4  rounded-3">
<img className="testimonial-avatar rounded-circle me-3" src={Avatar} alt="Rajasekaran Arul" width="80" height="80" />
<div className="w-60  p-0">
<p className="text-12 font-1 align-self-start m-l-5 col-gray-900">
<span className="font-weight-5">Amazing Product with new user Interface -</span> Walkzero has revolutionized the way we handle HR tasks, simplifying complexities and significantly reducing time investments.
</p>
<p className="text-12 font-1 align-self-start m-l-5 col-gray-900">Rajasekaran Arul</p>
<p className="text-muted text-12 font-1 align-self-start m-l-5 col-gray-900">GM / Page Industries</p>
</div>
</div>

           </>
       </div>
       <div className="center-certificates d-flex justify-content-center align-items-center">
           <div className="d-flex flex-column  p-2 bg-body inner-certificates">
             <Soc className="certificate-icons"/>
             <Iso className="certificate-icons"/>
       
             <Gdpr className="certificate-icons"/>
           </div>
       </div>
       <div style={{ justifyContent: "center", alignItems: "center" }} className="position-relative sign-up-second-cont d-flex flex-column p-t-30 p-b-10">
  <div className="w-100 d-flex justify-content-center">
    <h4 className="text-12 font-1 align-self-start m-l-5 col-gray-900 d-flex justify-content-end align-items-end w-95 signup-nav">
      To create an account <Link style={{ textDecoration: "none" }} className="col-secondary ms-1" to='/sign-up'>Signup</Link>
    </h4>
  </div>
  
  <div className="w-100 d-flex justify-content-center">
    <Form />
  </div>
  
  <div className="position-absolute not-success-download-container">
    <div className='d-flex flex-row justify-content-center align-items-center w-100 new-download-container gap-3'>
      <div className="mr-5">
        <Android />
      </div>
      <div>
        <Apple />
      </div>
    </div>
  </div>
</div>

   </div> 
        </>
    )
}

export default Main