import "./index.scss"
import { Link, useLocation } from "react-router-dom"
import { Helmet } from 'react-helmet-async'
import { motion } from 'framer-motion';
import { useEffect, useState } from "react"
import { ReactComponent as Logo } from "../../../assets/logos/logo.svg"
import { data2 } from "../../product-menu/product-menu";
import { useContext } from "react";
import { MenuContext } from "../../../app";
import Avatar from "../../../assets/images/avatar.png"
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import { ReactComponent as Star } from '../../../assets/logos/star.svg'
import { VERIFY_EMAIL, VERIFY_PHONE, VERIFY_DOMAIN, INDUSTRIES } from "../../../graphql/queries"
import { SIGNUP_MUTATION } from "../../../graphql/mutation"
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { TailSpin } from "react-loader-spinner"
import { BaseBtn } from "../../../styled-components"
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import HeroSection from "../../payroll/banner-components/banner-main"
import AutoPlay from "../../layout/industry-slide";
import { data, settings } from "../../landing-page/home";
import { ReactComponent as Apple } from '../../../assets/logos/apple.svg';
import { ReactComponent as Android } from '../../../assets/logos/android.svg';
import { ReactComponent as Soc } from '../../../assets/logos/soc.svg';
import { ReactComponent as Iso } from '../../../assets/logos/iso.svg';
import { ReactComponent as Gdpr } from '../../../assets/logos/gdpr.svg';
import { ReactComponent as TopDesign } from '../../../assets/logos/top-signup-design.svg';
import { ReactComponent as BottomDesign } from '../../../assets/logos/bottom-signup-design.svg';
import Carousel from "../carousel-component";

const msg = 'This field is required';
const url = `${process.env.REACT_APP_WZ_URL}`;

interface Industry {
    name: string;
    id: string
}

const UpdatedMaster = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [noOfEmployees, setNoOfEmployees] = useState('');
    const [company, setCompany] = useState('');
    const [industry, setIndustry] = useState<string | null>(null);
    const [domain, setDomain] = useState('')
    const [nameRequired, setNameRequired] = useState(false);
    const [emailRequired, setEmailRequired] = useState(false);
    const [numberRequired, setNumberRequired] = useState(false);
    const [employeeRequired, setEmployeeRequired] = useState(false);
    const [companyRequired, setCompanyRequired] = useState(false);
    const [industryRequired, setIndustryRequired] = useState(false);
    const [domainRequired, setDomainRequired] = useState(false);
    const [isLoading, setLoading] = useState(false)
    const [nameErMsg, setNameErMsg] = useState(msg)
    const [emailErMsg, setEmailErMsg] = useState(msg);
    const [numberErMsg, setNumberErMsg] = useState(msg);
    const [employeeErMsg, setEmployeeErMsg] = useState(msg);
    const [companyErMsg, setCompanyErMsg] = useState(msg);
    const [industryErMsg, setIndustryErMsg] = useState(msg);
    const [domainErMsg, setDomainErMsg] = useState(msg);
    const [focused, setFocused] = useState<boolean>(false);
    const [success, setSuccess] = useState(false)
    const [industryList, setIndustryList] = useState([])
    const [industrySearchInput, setIndustrySearchInput] = useState('')


    const location = useLocation();
    const heading = location.pathname === "/register" ? "Register" : "Create Your";


    const getIndustries = async (searchInput: string) => {
        const input = {
            "paginationArgs": {
                "page": 1,
                "limit": 10
            },
            "filter": searchInput
        }
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query: INDUSTRIES,
                    variables: { input },
                }),
            })

            if (response.ok) {

                const res = await response.json();
                const { data, errors } = res
                if (data) {
                    setIndustryList(data.adminIndustries.results)
                }
                if (errors) {
                    console.error(errors)
                }
                return res;
            }
        }
        catch (error: any) {
            console.error(`error in signup: ${error.message}`)
        }
    }

    useEffect(() => {
        getIndustries(industrySearchInput)
    }, [industrySearchInput])


    const { setIsOpen, isOn, setIsProductOpen, setModules } = useContext(MenuContext)

    const clickLogo = () => {
        setIsOpen(false)
        setIsProductOpen(false)
        setModules(data2)
    }

    const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const changeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        if (/^[a-zA-Z ]*$/.test(inputValue)) {
            setName(event.target.value)
        }

        if (inputValue !== '' && inputValue.includes(' ')) {
            setNameErMsg('')
            setNameRequired(false)
        }
        else if (inputValue !== '') {
            setNameErMsg('Please enter your last name')
            setNameRequired(true)
        }
        else if (inputValue === '') {
            setNameErMsg(msg)
            setNameRequired(true)
        }
    }

    const changeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
        const inputValue = event.target.value;

        if (pattern.test(inputValue)) {
            setEmailErMsg('')
            setEmailRequired(false)
        }
        else if (!pattern.test(inputValue) && inputValue !== '') {
            setEmailErMsg('Please enter a valid email')
            setEmailRequired(true)
        }
        else if (inputValue === '') {
            setEmailErMsg(msg)
            setEmailRequired(true)
        }
    }

    const changeNumber = (value: string, _data: {} | CountryData, _event: React.ChangeEvent<HTMLInputElement>, _formattedValue: string) => {
        const inputValue = value;

        const phone = parsePhoneNumberFromString(_formattedValue)


        if ((parseInt(inputValue) > 0 && /^\d+$/.test(inputValue)) || (inputValue === '')) {
            setNumber(inputValue);
        }

        if (inputValue !== '' && !phone?.isValid()) {
            setNumberErMsg('The phone number entered is not valid.')
            setNumberRequired(true)
        }
        else if (inputValue === '') {
            setNumberErMsg(msg)
            setNumberRequired(true)
        }
        else if (inputValue !== '' && phone?.isValid()) {
            setNumberErMsg('')
            setNumberRequired(false)
        }
    }

    const changeNoOfEmploy = (event: SelectChangeEvent<string>) => {
        const inputValue = event.target.value;
        setNoOfEmployees(event.target.value);

        if (inputValue === '') {
            setEmployeeErMsg(msg)
            setEmployeeRequired(true)
        }
        else {
            setEmployeeErMsg('')
            setEmployeeRequired(false)
        }
    }

    const changeCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        if (/^[a-zA-Z0-9  &-]*$/.test(inputValue)) {
            setCompany(event.target.value)
        }

        if (inputValue === '') {
            setCompanyErMsg(msg)
            setCompanyRequired(true)
        }
        else {
            setCompanyErMsg('')
            setCompanyRequired(false)
        }
    }

    const changeIndustry = (event: React.SyntheticEvent, newValue: string | null) => {
        if (newValue) {
            setIndustry(newValue);
        }
    }

    const searchIndustry = (event: React.SyntheticEvent, newInputValue: string) => {
        setIndustrySearchInput(newInputValue)
        setIndustry(newInputValue)

        if (newInputValue === '') {
            setIndustryErMsg(msg)
            setIndustryRequired(true)
        }
        else {
            setIndustryErMsg('')
            setIndustryRequired(false)
        }
    }

    const changeDomain = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        if (/^[a-zA-Z0-9-]*$/.test(inputValue)) {
            setDomain(event.target.value)
        }

        if (inputValue === '') {
            setDomainErMsg(msg)
            setDomainRequired(true)
        }
        else {
            setDomainErMsg('')
            setDomainRequired(false)
        }
    }

    interface SignupInput {
        first_name: string
        last_name: string
        name: string
        email: string
        phone: string
        industry: string
        size: string
        url: string
        send_newsletter: boolean
    }


    const verifySignUp = async (input: SignupInput) => {

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query: SIGNUP_MUTATION,
                    variables: { input },
                }),
            })

            if (response.ok) {

                const res = await response.json();
                const { data, errors } = res
                setLoading(false)
                if (data) {
                    setSuccess(true)
                }
                if (errors) {
                    setSuccess(false)
                }
                return res;
            }
        }
        catch (error: any) {
            console.error(`error in signup: ${error.message}`)
        }
    }


    const submitForm: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        const inputValue = `https://${domain}.walkzero.com`
        VerifyEmail({ 'email': email })
        VerifyDomain({ 'url': inputValue })
        VerifyPhone({ 'phone': `+${number}` })
        if (name !== '' && email !== '' && number !== '' && noOfEmployees !== '' && company !== '' && industry !== null && domain !== '' && emailErMsg === '' && domainErMsg === '' && numberErMsg === '') {
            if (pattern.test(email)) {
                setLoading(true)
                setEmailRequired(false)
                setEmployeeRequired(false)
                setNumberRequired(false)
                setCompanyRequired(false)
                setNameRequired(false)
                setIndustryRequired(false)
                setDomainRequired(false)
                let name_array = name.trim().split(" ")
                let first_name = '';
                let last_name;
                if (name_array.length > 2) {
                    last_name = name_array[name_array.length - 1]
                    for (let i = 0; i < name_array.length - 1; i++) {
                        first_name += name_array[i] + ' '
                    }
                }
                else {
                    first_name = name_array[0]
                    last_name = name_array[1]
                }

                let phone = `+${number}`
                let domainUrl = `https://${domain}.walkzero.com`;
                let industryName = typeof industry === 'string' ? industry : (industry as Industry).name
                const input = { "first_name": first_name.trim(), "last_name": last_name, "email": email, "phone": phone, "name": company, "send_newsletter": false, "size": noOfEmployees, "industry": industryName, "url": domainUrl }
                verifySignUp(input)
            }
            else {
                setEmailRequired(true)
                setEmailErMsg('Please enter a valid email')
            }

        }
        else {
            if (name === '') {
                setNameRequired(true)
            } else {
                setNameRequired(false)
            }

            if (email === '') {
                setEmailRequired(true)
            } else {
                setEmailRequired(false)
            }

            if (number === '') {
                setNumberRequired(true)
            } else {
                setNumberRequired(false)
            }

            if (noOfEmployees === '') {
                setEmployeeRequired(true)
            } else {
                setEmployeeRequired(false)
            }

            if (company === '') {
                setCompanyRequired(true)
            } else {
                setCompanyRequired(false)
            }

            if (industry === null) {
                setIndustryRequired(true)
            } else {
                setIndustryRequired(false)
            }

            if (domain === '') {
                setDomainRequired(true)
            } else {
                setDomainRequired(false)
            }
        }
    }

    interface VerifyEmailInput {
        email: string;
    }

    interface VerifyPhoneInput {
        phone: string;
    }

    interface VerifyDomainInput {
        url: string;
    }

    const VerifyEmail = async (input: VerifyEmailInput) => {

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query: VERIFY_EMAIL,
                    variables: { input },
                }),
            });

            if (response.ok) {
                const res = await response.json();
                const { data, errors } = res
                if (data) {
                    setEmailRequired(false)
                    setEmailErMsg('')
                }
                else if (errors) {
                    setEmailRequired(true)
                    setEmailErMsg(errors[0].email[0])
                }
                return res;
            }
        }
        catch (error: any) {
            console.error(`error in verifying email: ${error.message}`)
        }
    }


    const VerifyPhone = async (input: VerifyPhoneInput) => {

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query: VERIFY_PHONE,
                    variables: { input },
                }),
            });

            if (response.ok) {
                const res = await response.json();
                const { data, errors } = res
                if (data) {
                    setNumberRequired(false)
                    setNumberErMsg('')
                }
                else if (errors) {
                    setNumberRequired(true)
                    setNumberErMsg(errors[0].phone[0])
                }
                return res;
            }
        }
        catch (error: any) {
            console.error(`error in verifying number: ${error.message}`)
        }
    }


    const VerifyDomain = async (input: VerifyDomainInput) => {

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query: VERIFY_DOMAIN,
                    variables: { input },
                }),
            });

            if (response.ok) {
                const res = await response.json();
                const { data, errors } = res
                if (data) {
                    setDomainRequired(false)
                    setDomainErMsg('')
                }
                else if (errors) {
                    setDomainRequired(true)
                    setDomainErMsg(errors[0].url[0])
                }
                return res;
            }
        }
        catch (error: any) {
            console.error(`error in verifying domain: ${error.message}`)
        }
    }


    const checkName = (event: React.FocusEvent<HTMLInputElement>) => {
        const inputValue = event.target.value.trim();

        if (inputValue === '') {
            setNameRequired(true)
            setNameErMsg(msg)
        }
        else if (inputValue.length < 3 || !inputValue.includes(" ")) {
            setNameRequired(true)
            setNameErMsg('Please enter your last name')
        }
        else {
            setNameRequired(false)
            setNameErMsg('')
        }
    }

    const checkEmail = (event: React.FocusEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        if (!pattern.test(inputValue)) {
            setEmailRequired(true)
            setEmailErMsg('Please enter a valid email')
        }
        if (inputValue === '') {
            setEmailRequired(true)
            setEmailErMsg(msg)
        }
        if (pattern.test(inputValue)) {
            const input = { 'email': inputValue }
            VerifyEmail(input)
        }
    }

    const checkNumber = (event: React.FocusEvent<HTMLInputElement>) => {
        setFocused(false);
        const inputValue = event.target.value;

        if (inputValue === '') {
            setNumberRequired(true)
            setNumberErMsg(msg)
        }
        else {
            const input = { 'phone': inputValue }
            VerifyPhone(input)
        }
    }

    const checkEmployee = (event: React.FocusEvent<HTMLInputElement>) => {
        if (event.target.value === '') {
            setEmployeeRequired(true)
            setEmployeeErMsg(msg)
        }
        else {
            setEmployeeRequired(false)
            setEmployeeErMsg('')
        }
    }

    const checkCompany = (event: React.FocusEvent<HTMLInputElement>) => {
        if (event.target.value === '') {
            setCompanyRequired(true)
            setCompanyErMsg(msg)
        }
        else {
            setCompanyRequired(false)
            setCompanyErMsg('')
        }
    }

    const checkIndustry = (event: React.FocusEvent<HTMLInputElement>) => {
        if (event.target.value === '') {
            setIndustryRequired(true)
            setIndustryErMsg(msg)
        }
        else {
            setIndustryRequired(false)
            setIndustryErMsg('')
        }
    }

    const checkDomain = (event: React.FocusEvent<HTMLInputElement>) => {
        const inputValue = `https://${event.target.value}.walkzero.com`
        if (event.target.value === '') {
            setDomainRequired(true)
            setDomainErMsg(msg)
        }
        else {
            const input = { 'url': inputValue }
            VerifyDomain(input)
        }
    }

    const showArrow = () => {
        setFocused(true);
    }

    return (
        <>
              <Helmet>
                  <title>Walkzero: Join the Future of HRM | HCM</title>
                  <meta name="description" content="Sign up for Walkzero today! Experience seamless HR and payroll automation with a free trial and hands-on demo." />
                  <meta name="keywords" content="HRMS free trial, payroll software demo, Walkzero sign-up, employee management software, cloud HRMS, HR automation tools" />
              </Helmet>  
        <div  className="signup-flex-container d-flex flex-row align-items-start">
       
            <div className="d-flex flex-column justify-content-start align-items-center bg-col-white sign-up-first-cont  p-b-10 bg-col-container-bg">
                <div className="top-design">
                    <TopDesign className="top-design-svg"/>
                </div>
                <div className="bottom-design">
                    <BottomDesign className="bottom-design-svg"/>
                </div>
            <div className="d-flex flex-row justify-content-start p-x-15  w-100 align-self-start logo-container-signup">
           <Link to="/" onClick={clickLogo}>
             <Logo width={"63%"} className="logo-height" />
           </Link>
            </div>
      <div className="mt-45 w-100 carousel-signup-component">
        <Carousel/>
        {/* <div  className="d-flex flex-column justify-content-center align-items-center desc-container">
            <h4 className="d-flex align-items-center text-15"> Payroll  <span className="col-secondary ms-1">  Management</span> </h4>
            <h4 className="d-flex text-15 font-1 align-items-center m-l-5 col-gray-900 text-center">Simplified Payroll with Walkzero:</h4>
            <h4 className="d-flex text-15 font-1 align-items-center m-l-5 col-gray-900 text-center">Smarter,Faster,Easier</h4>
        </div>
        <div className="d-flex justify-content-center align-items-center">
        <motion.div className="z-index-1 w-80 d-flex justify-content-center align-items-center" initial={{opacity: 0}} animate={{opacity: [0, 0.2, 0.3, 0.4, 0.6, 0.8, 0.9, 1]}} transition={{duration: 2, delay: 1, ease: 'easeOut'}}>
                <HeroSection className='w-100 z-index-1'/>
                </motion.div>
        </div> */}

                    </div>

                    {success && <h1 className="text-24 font-weight-6 m-t-50 text-center">"Fasten your seatbelt and get <br /> ready for the ride."</h1>}
                    {!success && <>
                        <div className="testimonial-section d-flex flex-row justify-content-center align-items-center p-4   rounded-3">
                            <img className="testimonial-avatar rounded-circle me-3" src={Avatar} alt="Rajasekaran Arul" width="80" height="80" />
                            <div className="w-60  p-0">
                                <p className="text-12 font-1 align-self-start m-l-5 col-gray-900">
                                    <span className="font-weight-5">Amazing Product with new user Interface -</span> Walkzero has revolutionized the way we handle HR tasks, simplifying complexities and significantly reducing time investments.
                                </p>
                                <p className="text-12 font-1 align-self-start m-l-5 col-gray-900">Rajasekaran Arul</p>
                                <p className="text-muted text-12 font-1 align-self-start m-l-5 col-gray-900">GM / Page Industries</p>
                            </div>
                        </div>

                    </>}
                </div>
                <div className="center-certificates d-flex justify-content-center align-items-center">
                    <div className="d-flex flex-column  p-2 bg-body inner-certificates">
                        <Soc className="certificate-icons" />
                        <Iso className="certificate-icons" />

                        <Gdpr className="certificate-icons" />
                    </div>
                </div>
                <div style={{ justifyContent: success ? "center" : "start" }} className="position-relative sign-up-second-cont  d-flex flex-column p-t-30 p-b-10">
                    {success && <div className="msg-cont d-flex flex-column align-items-center position-relative">
                        <h2 className="col-container-font text-22 mb-0 m-t-10 text-center">You have signed up <span className='col-secondary'>successfully</span></h2>
                        <p className="text-13 font-weight-4 font-1 col-copyrights-clr text-center m-t-10 m-b-0">Please check your email for verification</p>
                        <p className="font-1 font-weight-6 text-20 col-container-font m-t-10">Thank you for choosing walkzero</p>
                        <Star className="position-absolute msg-big-star" />
                        <Star style={{ width: 20, height: 20 }} className="position-absolute msg-small-star" />
                    </div>}
                    {success && <div className="position-absolute success-download-container">
                        <div className='d-flex flex-row justify-content-center align-items-center w-100 new-download-container gap-3'>

                            <Android />
                            <Apple />
                        </div>
                    </div>}
                    <div className=" p-x-15  w-100  signup-text">
                        {
                            !success && <div>
                                <h4 className="text-12 font-1 align-self-start m-l-5 col-gray-900 d-flex justify-content-end align-items-end w-95">Already have a account <Link style={{ textDecoration: "none" }} className="col-secondary ms-1" to='/login'> Login</Link></h4>
                            </div>
                        }
                        {
                            success && <h4 style={{ position: 'absolute', top: '0px', right: '40px' }} className="text-12 font-1 align-self-start col-gray-900">Back to <Link style={{ textDecoration: "none" }} className="col-secondary ms-1" to='/'> Home</Link></h4>
                        }
                    </div>



                    {!success && <form onSubmit={submitForm} className="d-flex flex-column align-items-center signup-form-cont align-self-center position-relative" noValidate>
                        <h2 className="col-container-font text-22 align-self-center now">{heading} <span className='col-secondary'>Account</span></h2>
                        <h4 className="text-12 font-1 align-self-start m-l-5 col-gray-900 align-self-center now m-b-10 font-weight-3 text-center cover-text">Free forever up to 5 employees! No credit card required!</h4>
                        <TextField className={`inputs ${name && 'has-value'}`} sx={{ mt: 1.5 }} required id="outlined-basic" label="Full Name" variant="outlined" size="small" type="text" value={name} onChange={changeName} onBlur={checkName} error={nameRequired ? true : false} />
                        <span style={{ opacity: nameRequired ? 1 : 0 }} className="text-12 font-1 align-self-start m-l-25 col-error msg-height">{nameErMsg}</span>
                        <TextField className={`inputs ${email && 'has-value'}`} sx={{ mt: 1.5 }} required id="outlined-basic" label="Work Email" variant="outlined" size="small" type="email" value={email} onChange={changeEmail} onBlur={checkEmail} error={emailRequired ? true : false} />
                        <span style={{ opacity: emailRequired ? 1 : 0 }} className="text-12 font-1 align-self-start m-l-25 col-error msg-height">{emailErMsg}</span>

                        <FormControl fullWidth error={!!numberRequired} sx={{ mt: 1.5 }}>
                            <div className={`phone-input-container ${focused || number ? 'focused' : ''} ${number ? 'filled' : ''} ${numberRequired ? 'error' : ''} ${number && 'has-value'}`}>
                                <label htmlFor="phone-input">Phone Number *</label>
                                <PhoneInput
                                    country={'in'}
                                    value={number}
                                    onChange={changeNumber}
                                    onBlur={checkNumber}
                                    onFocus={showArrow}
                                    placeholder=""
                                    inputProps={{
                                        id: 'phone-input',
                                        required: true,

                                    }}
                                    containerClass={numberRequired ? 'phone-input-error' : ''}
                                    containerStyle={{ width: '100%' }}
                                    inputStyle={{ width: '100%' }}
                                />
                                <span style={{ opacity: numberRequired ? 1 : 0 }} className="text-12 font-1 align-self-start m-l-25 col-error msg-height">{numberErMsg}</span>
                            </div>
                        </FormControl>
                        <TextField className={`inputs ${company && 'has-value'}`} sx={{ mt: 1.5 }} required id="outlined-basic" label="Company name" variant="outlined" size="small" type="text" value={company} onChange={changeCompany} onBlur={checkCompany} error={companyRequired ? true : false} />
                        <span style={{ opacity: companyRequired ? 1 : 0 }} className="text-12 font-1 align-self-start m-l-25 col-error msg-height">{companyErMsg}</span>

                        <div style={{ display: 'flex', gap: '16px', width: '96%' }}>

                            <div style={{ flex: 1, maxWidth: '48%', display: 'flex', flexDirection: 'column' }}>
                                <FormControl className={`inputs ${noOfEmployees && 'has-value'}`} sx={{ mt: 1.5, minWidth: '100%' }} size="small" error={employeeRequired}>
                                    <InputLabel id="employee-count-label">Number of employees *</InputLabel>
                                    <Select
                                        labelId="employee-count-label"
                                        id="employee-count"
                                        value={noOfEmployees}
                                        label="Number of employees"
                                        onChange={changeNoOfEmploy}
                                        onBlur={checkEmployee}
                                        required
                                    >
                                        {/* <MenuItem value=""><em>None</em></MenuItem> */}
                                        <MenuItem value={'0 - 10'}>0 - 10</MenuItem>
                                        <MenuItem value={'11 - 100'}>11 - 100</MenuItem>
                                        <MenuItem value={'101 - 500'}>101 - 500</MenuItem>
                                        <MenuItem value={'501 - 1000'}>501 - 1000</MenuItem>
                                        <MenuItem value={'1001 - 10000'}>1001 - 10000</MenuItem>
                                        <MenuItem value={'10001+'}>10001+</MenuItem>
                                    </Select>
                                </FormControl>
                                <span style={{ opacity: numberRequired ? 1 : 0 }} className="text-12 font-1 align-self-start m-l-10 col-error msg-height">{employeeErMsg}</span>
                            </div>
                            <div style={{ flex: 1, maxWidth: '48%', marginLeft: '10px', display: 'flex', flexDirection: 'column' }}>
                                <FormControl className={`inputs ${industry && 'has-value'}`} sx={{ mt: 1.5, minWidth: '100%' }}>
                                    <Autocomplete
                                        aria-selected
                                        size="small"
                                        value={industry}
                                        onChange={changeIndustry}
                                        onBlur={checkIndustry}
                                        inputValue={industrySearchInput}
                                        onInputChange={searchIndustry}
                                        disablePortal
                                        freeSolo
                                        id="free-solo-demo"
                                        options={industryList}
                                        getOptionLabel={(option: any) => typeof option === 'string' ? option : option.name}
                                        isOptionEqualToValue={(option: any, value: any) => {
                                            if (typeof value === 'string') {
                                                return option.name === value;
                                            }
                                            return option.id === value.id;
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Industry *" error={industryRequired ? true : false} />}
                                    />
                                </FormControl>
                                <span style={{ opacity: industryRequired ? 1 : 0 }} className="text-12 font-1 align-self-start m-l-10 col-error msg-height">
                                    {industryErMsg}
                                </span>
                            </div>
                        </div>

                        <div className="position-relative w-100 domain-input">
                            <span className="text-12 font-1 col-gray-900 position-absolute right-40 top-percentage-45 z-index-2">.walkzero.com</span>
                            <TextField className={`inputs ${domain && 'has-value'}`} sx={{ mt: 1.5 }} required id="outlined-basic" label="Preferred Domain" variant="outlined" size="small" type="text" value={domain} onChange={changeDomain} onBlur={checkDomain} error={domainRequired ? true : false} />
                        </div>
                        <span style={{ opacity: domainRequired ? 1 : 0 }} className="text-12 font-1 align-self-start m-l-25 col-error msg-height">{domainErMsg}</span>
                        <span className="text-10 font-1 align-self-start m-l-23 col-gray-900">Eg: thinq24.walkzero.com</span>
                        <BaseBtn isOn={isOn} type="submit" className='btn-4 rounded-9 text-16 font-weight-6 demo-btn w-px-150 align-self-center m-t-15 m-b-10'>Get Started</BaseBtn>
                        <h4 className="text-12 font-1 align-self-start m-l-5 col-gray-900 align-self-center now m-b-5 font-weight-3 text-center">By clicking this button, you agree to our <span className="col-secondary"><Link style={{ textDecoration: "none" }} className="col-secondary" to="/terms-and-conditions" > Terms of Service</Link>  </span> and <span className="col-secondary"><Link style={{ textDecoration: "none" }} className="col-secondary decoration-none" to="/privacy-policy" > Privacy Policy</Link></span> </h4>
                        {isLoading && <div className="align-self-center"><TailSpin visible={true} height="30" width="30" color="#f45600" ariaLabel="tail-spin-loading" radius="1" /></div>}
                        <Star className="position-absolute big-star " />
                        <Star style={{ width: 20, height: 20 }} className="position-absolute small-star" />
                    </form>}
                    {!success && <
                        div className="d-flex flex-column justify-content-center align-items-center"
                    >
                        <div className='d-flex flex-row justify-content-center align-items-center mb-18  w-100 autoplay-carousel'>
                            <AutoPlay data={data} settings={settings} />

                        </div>
                       <div className="d-flex gap-3 ">
                       <div className="mr-5">
                         <Android />
                       </div>
                       <div>
                         <Apple />
                       </div>
                     </div>
                     
                     
                    </div>

                    }



                </div>
            </div>
        </>
    )
}

export default UpdatedMaster;