import { useState, useEffect } from "react";
import "./index.scss"
import ReactApexChart from 'react-apexcharts';

type BarChartProps = {
    item: {
        id: string
        color1: string
        color2: string
        title: string
    }
}

const BarChart = (props:BarChartProps) => {

    const [showChart, setShowChart] = useState(false)
    const [chartWidth, setChartWidth] = useState("100%");
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowChart(true);
        }, 2000);

        return () => clearTimeout(timer); // Cleanup the timer
    }, []);

    useEffect(() => {
       
        const updateWidth = () => {
          setChartWidth(window.innerWidth <= 450 ? "80%" : "100%");
        };
    
        updateWidth(); 
        window.addEventListener("resize", updateWidth);
    
        return () => window.removeEventListener("resize", updateWidth);
      }, []);
    const {item} = props 
    const {id, color1, color2, title} = item

    const opt:any = {
        chart:{
            id:id,
            stacked: true,
            zoom: {
                enabled: false
            },
        },
        colors:[color1, color2],
       plotOptions:{
        bar: {
            columnWidth: '60%',
            borderRadius: 2,
            borderRadiusApplication: 'around',
            borderRadiusWhenStacked: 'all',
            rangeBarOverlap: true,
            isFunnel3d:true,
        }
       },
        toolbar: {
            show: false,
        },
        dataLabels: {
            enabled: false
        },
        tooltip:{
            enabled:false
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            axisTicks:{
                show:false
            },
            labels:{
                show:false
            },
            axisBorder: {
                show:false,
                width:'90%',
            }
        },
        grid:{
            show:false
        },
        yaxis:{
            labels:{
                show:false
            }
        },
        legend:{
            show:false
        }
    }

    const series = [{
        name: 'Series 1',
        data: [125, 180, 150, 130, 160, 70, 100, 110, 100, 90],
    },
    {
        name: 'Series 2',
        data: [125, 30, 40, 40, 25, 140, 40, 60, 40, 35],
    },

    ];
    

    return(
        <div className="bg-col-white rounded-25 p-3 m-2 bar-chart position-relative">
            <h2 className="text-12 font-weight-5 mb-0 font-1">{title}</h2>
            {showChart?<ReactApexChart className="move" options={opt} series={series} type="bar" height={130} width={chartWidth} />:<div></div>}
        </div>
    )
}

export default BarChart