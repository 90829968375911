import './index.scss'
import FaqItems from '../faq-items'
import { ReactComponent as Circle } from "../../../assets/logos/circle.svg"
import { useAnimation, motion } from "framer-motion"
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

// const faqList = [
//     {id:"faq1", title:"What is HRMS?", ans:"HRMS stands for Human Resource Management System. It's a software solution that helps businesses manage various HR functions, such as employee data, payroll, attendance, recruitment, and performance management, all in one centralized system.", isOpen:false},
//     {id:"faq2", title:"How do I choose the right HRMS for my organization?", ans:"Choosing the right HRMS involves assessing your organization's specific needs, budget, scalability requirements, and desired features. It's essential to request demos or trials to evaluate the software's suitability for your organization's needs.", isOpen:false},
//     {id:"faq3", title:"What are the benefits of using HRMS?", ans:"HRMS offers several benefits, including streamlined HR processes, improved accuracy in data management, increased efficiency, better compliance with regulations, enhanced employee experience, and access to valuable analytics for informed decision-making.", isOpen:false},
//     {id:"faq4", title:"Can employees access Walkzero on mobile devices?", ans:"Yes, it offers mobile apps or responsive web interfaces that allow employees to access the system from their smartphones or tablets. This enables employees to perform tasks such as requesting leave, viewing pay stubs, and updating personal information on the go.", isOpen:false},
//     {id:"faq5", title:"Is Walkzero's HRMS suitable for businesses of all sizes?", ans:"Yes, Walkzero's HRMS is scalable and adaptable, making it suitable for businesses of all sizes. Whether you're a small startup or a large enterprise, our platform can be customized to meet your specific HR needs.", isOpen:false},
//     {id:"faq6", title:"How does Walkzero's HRMS handle data security?", ans:"Walkzero HRMS prioritizes data security and employs robust measures to protect sensitive information. Our platform utilizes encryption, access controls, and regular security updates to ensure data integrity and compliance with regulations.", isOpen:false},
// ]

type FaqProps = {
    faqs: any
    setFaqs: any
    optionalhead?: any
    optionalheadpartner?: any
};


const Faqs = (props: FaqProps) => {

    const { faqs, setFaqs, optionalhead, optionalheadpartner } = props

    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.1,
        triggerOnce: true,
    });

    useEffect(() => {
        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView])


    const checkIsOpen = (id: string) => {
        setFaqs((prevFaqs: any) => ({
            faq: prevFaqs.faq.map((each: any) => {
                if (each.id === id) {
                    return { ...each, is_open: !each.is_open };
                }
                return { ...each, is_open: false };
            }),
        }));
    }

    const parent = {
        hidden: {
            opacity: 1
        },
        visible: (i = 1) => ({
            opacity: 1,
            transition: {
                staggerChildren: 0.08,
                delayChildren: 0.04 * i,
            }
        }),
    }

    const child = {
        visible: {
            opacity: 1,
            transition: {
                duration: 1,
                type: "tween",
                ease: "easeIn"
            }
        },
        hidden: {
            opacity: 0
        }
    }

    return (
        // <motion.div className='d-flex flex-column bgnd-clr w-100 p-t-40 p-b-40 position-relative'>
        <motion.div
            className={`d-flex flex-column w-100 p-t-40 p-b-40 position-relative ${optionalhead ? 'custom-bg' : 'bgnd-clr'}`}>

            <motion.div ref={ref} variants={parent} initial="hidden" animate={ctrls} className='d-flex flex-column justify-content-center align-items-start align-self-center faq-cont'>
                <motion.p variants={child} className='col-quaternary font-weight-6 text-12 align z-index-1'>FREQUENTLY ASKED QUESTIONS</motion.p>

                {
                    optionalhead ? (
                        <motion.p className="col-container-font font-weight-6 h1-size align z-index-1" variants={child}>
                            Questions related to <span className="col-secondary">pricing</span>
                        </motion.p>
                    ) : optionalheadpartner ? (
                        <motion.p className="col-container-font font-weight-6 h1-size align z-index-1" variants={child}>
                            Questions related to <span className="col-secondary">partnership</span>
                        </motion.p>
                    ) : (
                        <motion.h2 variants={child} className="col-container-font font-weight-6 h1-size align z-index-1">
                            Everything <span className="col-secondary">you need</span> to know
                        </motion.h2>
                    )
                }

                <motion.p variants={child} className='text-14 font-weight-4 font align'>If you have anything else you want ask, reach out to us.</motion.p>
                {faqs.faq.map((each: any) => {
                    return <FaqItems key={each.id} item={each} checkIsOpen={checkIsOpen} />
                })}
                <motion.p variants={child} className='text-14 font-weight-3 font m-0 mt-4 align-self-center align'>Walkzero offers Cloud-Based HR Management Solution for Effortless Employee Onboarding,</motion.p>
                <motion.p variants={child} className='text-14 font-weight-3 font m-0 align-self-center align'>Streamlined Leave & Attendance Tracking, Seamless Document Management and Payroll Automation.</motion.p>
            </motion.div>
            <Circle className="position-absolute right-percentage-35 top--20" />
        </motion.div>
    )
}

export default Faqs