import {Link, useLocation} from "react-router-dom"
import {ReactComponent as Logo} from "../../../assets/logos/logo.svg"
import {ReactComponent as Global} from "../../../assets/logos/global-edit.svg"
import React, { useState, useEffect, CSSProperties } from 'react';
import "./index.scss"
import {motion} from "framer-motion"
import { useContext } from "react";
import { MenuContext } from "../../../app";
import { Tooltip } from "@mui/material";
import { IoIosSearch } from "react-icons/io";
import { BaseBtn } from "../../../styled-components";

const BlogsNavbar = () => {

    const props = useContext(MenuContext)
    const {setIsOpen, setIsOn, isOn, searchInput, setSearchInput, setBlogList, setIsLoading} = props
    const location = useLocation();
    const showHeader = location.pathname === "/blogs" || location.pathname.includes("/blog/");


    const [isContainerVisible, setIsContainerVisible] = useState(true);

    const toggleSwitch = () => setIsOn(!isOn);


    const searchBlogs = async() => {
        setIsLoading(true)
        const url = `${process.env.REACT_APP_API_URL}/blogs?search=${searchInput}`;

        const options = {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            }
        }  

        try{
            const response = await fetch(url, options)
            if(response.ok){
                setIsLoading(false)
                const blogs = await response.json()
                setBlogList(blogs)
            }
        }
        catch(error:any){
            console.error(`error in fetching blogs: ${error.message}`)
        }
    }

    
      const searchBlog = (event:React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value)
      }
      
      const typeSearch = (event:React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key === 'Enter'){
            searchBlogs()
        }
      }

    
    useEffect(() => {
        const handleScroll = () => {
            const container = document.getElementById('themeContainer'); 
            if (container) {
                const containerTop = container.getBoundingClientRect().top;
                setIsContainerVisible(containerTop >= 0 && containerTop < window.innerHeight);
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    
    const containerStyle:CSSProperties = isContainerVisible ? { position: 'relative' } : { position: 'fixed', top: 0, zIndex:5, height:'70px', backgroundColor:'#ffffff', boxShadow:'0 5px 5px -3px rgb(244, 86, 0, 0.2)' };
    
    const spring = {
        type: "spring",
        stiffness: 700,
        damping: 30
    };

    const empty = <div></div>

    return(
        <>
        {showHeader ?(<>
        <div className="nav-main">
        <div id="blogContrast" className="d-flex flex-column justify-content-center align-items-center align-self-center contrast-cont"> 
            <div id="themeContainer" className="d-flex flex-row justify-content-between align-items-center inner-contrast align-self-center">
                <p className="text-10 col-secondary">"Chennai Roots, Silicon Valley Vision: Welcome to Walkzero's Next Gen HRMS & Payroll." #IndiaSaas #SaasBoomi</p>
                <div className="text-12 d-flex flex-row align-items-center">
                    <div className="d-flex align-items-center">
                        <span className="text-10 col-black-800">High Contrast</span>
                        <div className="ms-1 switch" data-ison={isOn} onClick={toggleSwitch}>
                            <motion.div style={{backgroundColor:isOn?"#1A2D48":"#F45600"}} className="handle" layout transition={spring} />
                        </div>
                    </div>
                    <div className="d-flex align-items-center m-l-30">
                        <span className="text-10 col-black-800 me-1">English</span>
                        <Global width={15} height={15}/>
                    </div>
                </div>
            </div>
        </div>
        <div style={containerStyle} className="nav-bar">
            <motion.div animate={{y:isContainerVisible?0:[-100, 0],
            transition:{
                staggerChildren: 0.08,
                delayChildren: 0.04 * 1,
                duration: 1,
                type:"spring",
                bounce:0.4
            },}} className="nav-container">
                <Link to="/" onClick={() => setIsOpen(false)}><Logo width={"73%"} className="logo-height"/></Link>
                <div id="blogLinks" className="link-container">
                    <Tooltip title="Coming Soon" arrow>
                    <Link to="" className="link">Latest Blogs</Link>
                    </Tooltip>
                    {/* <Tooltip title="Coming Soon" arrow>
                    <Link to="" className="link">Product Updates</Link>
                    </Tooltip>
                    <Tooltip title="Coming Soon" arrow>
                    <Link to="" className="link">Company News</Link>
                    </Tooltip> */}
                    <div className='d-flex flex-row align-items-center search-box'>
                        <IoIosSearch className='search-icon' />
                        <input type="search" placeholder='search' className='search-input' value={searchInput} onChange={searchBlog} onKeyDown={typeSearch} />
                    </div>
                    
                    <Link to="/sign-up"><BaseBtn isOn={isOn} className="get-started-btn text-12" type="button">Get Started</BaseBtn></Link>
                </div>
                <div className="d-flex flex-row justify-content-center align-items-center mt-4 mb-4 blog-lang"> 
                    <div className="d-flex align-items-center">
                        <span className="text-10 col-black-800">Contrast</span>
                        <div className="ms-1 switch" data-ison={isOn} onClick={toggleSwitch}>
                            <motion.div style={{backgroundColor:isOn?"#1A2D48":"#F45600"}} className="handle" layout transition={spring} />
                        </div>
                    </div>
                <div className="d-flex align-items-center m-l-30 ">
                    <span className="text-10 col-black-800 me-1"> EN</span>
                    <Global width={15} height={15}/>
                </div>
                </div>
            </motion.div>
        </div>
        </div></>):empty}
        </>
    )
}

export default BlogsNavbar