import React, { useState, useEffect, useRef } from 'react';
import './index.scss';
import cycleicon1 from "../../../assets/logos/cycle-icon1.svg";
import cycleicon2 from "../../../assets/logos/cycle-icon2.svg";
import cycleicon3 from "../../../assets/logos/cycle-icon3.svg";
import cycleicon4 from "../../../assets/logos/cycle-icon4.svg";
import cycleicon5 from "../../../assets/logos/cycle-icon5.svg";

const CycleSmall = () => {
  const [currentIndex, setCurrentIndex] = useState(0); // Track the current card index

  const cards = [
    {
      title: 'Onboarding & Training',
      description: 'Become an expert in selling and implementing Walkzero solutions.',
      icon: cycleicon1, // Associated icon for this card
    },
    {
      title: 'Dedicated Partner Success Team',
      description: 'Get direct access to expert support, marketing materials, and sales enabling tools.',
      icon: cycleicon2,
    },
    {
      title: 'Co-Marketing & Lead Generation',
      description: 'Drive demand with joint marketing campaigns, webinars, and events.',
      icon: cycleicon3,
    },
    {
      title: 'Early Access to New Features',
      description: 'Stay ahead with exclusive previews of Walkzero’s latest innovations.',
      icon: cycleicon4,
    },
    {
      title: 'Global Expansion Opportunities',
      description: 'Tap into new markets, industries, and customer segments worldwide.',
      icon: cycleicon5,
    },
  ];

  // Automatically change the card every 3 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % cards.length); // Loop back after last card
    }, 3000); // Change card every 3 seconds (3000ms)

    return () => clearInterval(intervalId); // Cleanup the interval on unmount
  }, []);

  // State to control the animation of arrow, icons, and text
  const [showArrow, setShowArrow] = useState(false);
  const [rotateArrow, setRotateArrow] = useState(false);  // For rotation animation
  const [showIcon, setShowIcon] = useState(false);
  const [showBulletText, setShowBulletText] = useState(false);

  // Create a ref for the Cycle component
  const cycleRef = useRef(null);

  useEffect(() => {
    // IntersectionObserver to detect when the component is in view
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          // Trigger the animation when the component is in view
          setRotateArrow(true);

          // Once the rotation completes, show the arrow and then other elements
          setTimeout(() => {
            setShowArrow(true);  // Show the arrow after rotation
            setTimeout(() => setShowIcon(true), 500);  // Show icons after 500ms
            setTimeout(() => setShowBulletText(true), 1000);  // Show bullet text after another 500ms
          }, 1000);
        }
      },
      {
        threshold: 0.5, // Trigger when 50% of the component is in view
      }
    );

    // Observe the cycleRef element
    if (cycleRef.current) {
      observer.observe(cycleRef.current);
    }

    // Cleanup observer when component unmounts
    return () => {
      if (cycleRef.current) {
        observer.unobserve(cycleRef.current);
      }
    };
  }, []);

  return (
    <>
      <div className="cycle-life-cycle" ref={cycleRef}>
        <div className="cycle-circle-container">
          {/* Circular Arrow */}
          <div className={`cycle-arrow-wrapper ${rotateArrow ? 'rotate' : ''} ${showArrow ? 'appear' : ''}`}>
            <div className="cycle-arrow"></div>

            {/* Container for carousel */}
            <div className="cycle-bullet-container">
              {cards.map((card, index) => (
                <div
                  key={index}
                  className={`cycle-bullet-${index + 1} ${showBulletText ? 'appear' : ''} ${index === currentIndex ? 'active' : ''}`}
                >
                  <div className="bullet-text">
                    <h3>{card.title}</h3>
                    <p>{card.description}</p>
                  </div>
                  {/* Conditional Rendering of the Icon */}
                  <img
                    src={card.icon}
                    alt={card.title}
                    className={`cycle-icon ${showIcon && index === currentIndex ? 'appear' : ''}`}
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Text Content */}
          <div className="cycle-text-wrapper">
            {/* <p className="text-grow"><strong>#ORGANISATION STRUCTURE</strong></p> */}
            <h2><strong>What Sets <span className="col-secondary">Walkzero</span> <br />Partnerships Apart?</strong></h2>
            <p>When you join the Walkzero Partner Network, you gain<br /> more than just a business opportunity—you gain a<br /> competitive edge.</p>
          </div>
        </div>
      </div>

      {/* Card Section */}
      <div>
        {cards.map((card, index) => (
          <div
            className={`cycle-card p-2 mt-4  mb-5 col-md-2 ${index === currentIndex ? 'active' : ''}`}
            style={{ display: index === currentIndex && window.innerWidth <= 997 ? 'block' : 'none' }}
            key={index}
          >
            <div className={`cycle-card-${index + 1}`}>
              <h5>{card.title}</h5>
              <p>{card.description}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default CycleSmall;
