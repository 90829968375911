import "./index.scss"
import {useAnimation, motion} from "framer-motion"
import { Checkbox, FormControlLabel, Typography } from "@mui/material";

import Quote from './quote-form/index'
import { useContext, useState } from "react";
import { MenuContext } from "../../../app";


const serviceCheckBox=[
  { id: 1, name: "Employee Management" },
  { id: 2, name: "Attendance Management" },
  { id: 3, name: "Scheduled Meeting" },
  { id: 4, name: "Tracking" },
  { id: 5, name: "Payroll System" },
  { id: 6, name: "Walkzero Meet & Chat" },
  { id: 7, name: "Leave Management" },
  { id: 8, name: "Self Service Management" },
]


const Pricequote = () => {

    const [selectedServices, setSelectedServices] = useState(serviceCheckBox.map(service=>service.name));
   
    const { isQuoteSubmitted } = useContext(MenuContext)
    const handleCheckboxChange = (service: string) => {
        setSelectedServices((prev) =>
          prev.includes(service)
            ? prev.filter((s) => s !== service)
            : [...prev, service]
        );
      };
    return (
        <motion.div  className="form-bg-outer">
          <div  className="p-20">
            <div>
            <p>Enterprise</p>
            <p className="goodbye-text text-16 font-weight-5">Say Goodbye to One-Size-Fits-All - Customize Your HR Plan Now!</p>
            </div>
          {
            isQuoteSubmitted ?
            <div className="justify-content-center align-items-center text-center quote-bg-res bg-col-container-bg d-flex flex-column">
                <div className="msg-cont d-flex flex-column align-items-center justify-content-center" style={{ flex: 1 }}>
                    <h2 className="col-container-font text-22 mb-0 m-t-10 text-center ml-30 mr-30">
                        Your request has been submitted <span className='col-secondary'> successfully</span>
                    </h2>
                    <p className="text-13 font-weight-4 font-1 col-copyrights-clr text-center m-t-10 m-b-0">
                        Please check your email for verification
                    </p>
                    <p className="font-1 font-weight-6 text-20 col-container-font m-t-10">
                        Thank you for choosing Walkzero
                    </p>
                </div>
           </div>

            :<div className="d-flex  quote-container">
            <div className="d-flex flex-column  align-item-center quote-left-container">
                <div>
                    <p className="text-42  m-0 quote-text-size">Get Your Quote <span className="text-16 goodbye-text"></span></p>
                    <p className="text-16 goodbye-text m-0">Customize Your Bundle</p>
                </div>
                <div className="mt-10">
                {serviceCheckBox.map((service) => (
    <div key={service.id} className="mb-2">
        <FormControlLabel
    control={
      <Checkbox
          checked={selectedServices.includes(service.name)}
                onChange={() => handleCheckboxChange(service.name)}
                sx={{
                  color: "", 
                  "&.Mui-checked": {
                    color: "#F45600", 
                  },
                }}
              />
            }
            label={ <Typography sx={{ fontFamily: '"Sora", sans-serif !important' }}>
      {service.name}
    </Typography>}
            className="price-checkbox"
          />
            </div>
          ))}
                 </div>
          </div>
            <div className="p-20  w-60 quote-right-container d-flex justify-content-center align-items-center">
              {
                selectedServices ?    <Quote  selectedService= {selectedServices}/> : ""
              }
             </div>
            </div>
          }
            
            
          </div>
        </motion.div>
    )
}

export default  Pricequote;