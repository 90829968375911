import { Link } from "react-router-dom"
import { useEffect } from "react"
import {useAnimation, motion} from "framer-motion"
import { Breadcrumbs, Typography } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { container2, child2  } from "../../../variants"
import { Helmet } from 'react-helmet-async'

import {ReactComponent as Left} from "../../../assets/logos/logo-left.svg"
import {ReactComponent as TopW} from "../../../assets/logos/top-w-curve.svg"
import {ReactComponent as BottomW} from "../../../assets/logos/bottom-w-curve.svg"
import './index.scss'
import MobMenu from '../mob-menu'





const CookiePolicy = () => {



    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: false,
      });

    useEffect(() => {
        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView])


    const child2w = {
        visible: {
          x:0,
          y:0,
          rotate:0,
          transition: {
            duration: 1.5,
            type:'tween'
          }
        },
        hidden: {
          x:-1700,
          y:350,
          rotate:90,
        }
      }
      const Top = {
        visible: {
          x:0,
          y:0,
          transition: {
            duration: 1,
            delay: 1,
            type:'tween'
          }
        },
        hidden: {
          x:0,
          y:-500,
        }
      }
    
      const Bottom = {
        visible: {
          x:0,
          y:0,
          transition: {
            duration: 1,
            delay: 1,
            type:'tween'
          }
        },
        hidden: {
          x:0,
          y:200,
        }
      }


  return (
    <>
    <Helmet>
        <title>COOKIE POLICY | Walkzero Technologies Private Limited</title>
    </Helmet>
    <motion.div ref={ref} variants={container2} initial="hidden" animate={ctrls} id="hrMain" className="hr-excellence d-flex flex-column position-relative bg-col-new-light-green">
        <motion.div  className="product-inner-cont-cookie d-flex flex-row justify-content-center align-items-center align-self-center">
        
            <motion.div style={{height:'400px'}} variants={child2} className="d-flex flex-column justify-content-center align-items-center position-relative second-cookie-s mt-20">
                <motion.div style={{zIndex:1}} initial={{opacity: 0}} animate={{opacity: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 1]}} transition={{duration: 3, delay: 2, ease: 'easeOut', type:"keyframes"}} className="d-flex flex-row justify-content-center align-items-center align-self-center pt-0">
            <motion.div   className="step-into-card-cookie d-flex flex-column position-relative justify-content-center align-items-center">
         

                <h3   className='product-cookie-heading col-container-font h1-size text-center'>Cookie <span className='col-secondary'>Policy </span></h3>
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
                Home
                </Link>
                <Typography color="text.primary">Cookie Policy</Typography>
            </Breadcrumbs>
             </motion.div>
            </motion.div>




                <div className="position-absolute bgnd-prod z-index-0 rounded-circle">
                </div>
                <motion.div variants={child2w} initial="hidden" animate="visible" className="position-absolute p-0 z-index-0">
                    <Left style={{opacity:0.6}} id="Leftw"/>
                </motion.div>
                <motion.div variants={Top} initial="hidden" animate="visible" className="position-absolute hr-top-pr">
                    <TopW id='Payroll-top' style={{opacity:0.6}}/>
                </motion.div>
                <motion.div variants={Bottom} initial="hidden" animate="visible" className="position-absolute p-0 hr-bottom-pr">
                    <BottomW id='Payroll-bottom' style={{opacity:0.6}}/>
                </motion.div>
            </motion.div>
        </motion.div>
        <div className="custom-shape-divider-bottom-1715923248">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
            </svg>
        </div>
    </motion.div>
    <div className="d-flex flex-column justify-content-center align-self-center privacy-policy ">
  
            <div className='mb-4'>
                <h2 className='font-weight-7 mt-4 mb-4 policy-heading-2'>Cookie Policy</h2>

                <p>This Cookie Policy explains how WalkZero (&quot;WalkZero&quot;, &quot;we&quot;, &quot;us&quot;, &quot;our&quot;) uses cookies and
                similar technologies on our website www.walkzero.com and related platforms (collectively, the
                &quot;Site&quot;). By using the Site, you consent to the use of cookies as described in this policy.</p>

        
                <h2 className='font-weight-7 mt-5 mb-5'>What are Cookies ?</h2>

                {/* <h5 className='font-weight-7 mt-4 mb-4'>Personal Information</h5> */}

                <p>Cookies are small text files that are stored on your device (computer, tablet, mobile phone)
                    when you visit websites. They are widely used to make websites work more efficiently, as well
                    as to provide information to the site owners.</p>

                    <h2 className='font-weight-7 mt-5 mb-5'>How We Use Cookies</h2>

                <p>We use cookies to enhance your experience on our Site, including:</p>
                <ul>
                    <li ><span className='privacy-info'>Essential Cookies:</span> These cookies are necessary for the Site to function and cannot be
                    switched off in our systems. They are usually only set in response to actions made by
                    you, such as setting your privacy preferences, logging in, or filling in forms.</li>
                    <li><span className='privacy-info'>Performance and Analytics Cookies:</span> These cookies help us understand how visitors
                    interact with our Site by collecting and reporting information anonymously. We use third-
                    party services, such as Google Analytics, to analyze web traffic and improve user
                    experience.</li>
                    <li><span className='privacy-info'>Functional Cookies:</span> These cookies enable the Site to provide enhanced functionality
                    and personalization. They may be set by us or by third-party providers whose services
                    we have added to our pages.</li>
                    <li><span className='privacy-info'>Advertising Cookies:</span> These cookies may be set by advertising partners to build a
                    profile of your interests and show you relevant advertisements on other sites. They do
                    not directly store personal information but are based on uniquely identifying your
                    browser and internet device.</li>
                </ul>

                <h2 className='font-weight-7 mt-5 mb-5'>Cookies We Use</h2>

                <div className='d-flex justify-content-center align-self-center privacy-table'>
                    <table>
                        <tr className='font-weight-7'>
                            <th>Name of the Cookie</th>
                            <th>Domain</th>
                            <th>Source</th>
                            <th>Cookie Type</th>
                            <th>Purpose</th>
                        </tr>
                        <tr>
                            <td>_ga</td>
                            <td>walkzero.com</td>
                            <td>Google Analytics</td>
                            <td>Performance/Analytic</td>
                            <td>Tracks user interactions on the website</td>
                        </tr>
                        <tr>
                            <td>_gid</td>
                            <td>walkzero.com</td>
                            <td>Google Analytics</td>
                            <td>Performance/Analytic</td>
                            <td>Distinguishes users for analytics purposes</td>
                        </tr>
                        <tr>
                            <td>_gat</td>
                            <td>walkzero.com</td>
                            <td>Google Analytics</td>
                            <td>Performance/Analytic</td>
                            <td>Limits data collection for high-
                            traffic sites</td>
                        </tr>
                        <tr>
                            <td>messagesUtk</td>
                            <td>walkzero.com</td>
                            <td>HubSpot</td>
                            <td>Functional</td>
                            <td>Stores user
                            interactions with
                            chat service</td>
                        </tr>
                        <tr>
                            <td>ai_user</td>
                            <td>hr.walkzero.com</td>
                            <td>Microsoft
                            Application
                            Insights</td>
                            <td>Essential</td>
                            <td>Tracks user
                            behavior to
                            improve
                            performance</td>
                        </tr>
                        <tr>
                            <td>ai_session</td>
                            <td>hr.walkzero.com</td>
                            <td>Microsoft
                                Application
                                Insights</td>
                            <td>Essential</td>
                            <td>Tracks session
                            activity to enhance
                            experience</td>
                        </tr>
                    </table>
                </div>

                <h5 className='font-weight-7 mt-4 mb-4'>Local Storage Data</h5>

                <p>We also use local storage to enhance your experience on our Site. Data stored locally may
                include:</p>

                <ul>
                    <li >Last visited pages</li>
                    <li>Login session information</li>
                    <li>Theme preferences</li>
                    <li>Notifications settings</li>
                </ul>

                <h5 className='font-weight-7 mt-4 mb-4'>How to Manage Your Cookies</h5>

             

                <p>You can control and/or delete cookies as you wish. Most web browsers automatically accept
                cookies, but you can modify your browser settings to decline cookies if you prefer. Below are
                links to manage cookies in popular browsers:</p>

                <ul>
                    <li ><a href="https://support.google.com/chrome/answer/95647?hl=en&co=GENIE.Platform%3DDesktop" target="_blank">Google Chrome</a></li>
                    <li ><a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US" target="_blank">Mozilla Firefox</a></li>
                    <li ><a href="https://support.microsoft.com/en-us/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank">Microsoft Edge</a></li>
                    <li ><a href="https://support.apple.com/en-us/105082" target="_blank">Apple Safari</a></li>
                    
                </ul>

                <p>If you have any questions about our use of cookies, please contact us at <a href="mailto:support@walkzero.com?Subject=Hello%20" target="_top" className="col-secondary">support@walkzero.com</a></p>
            </div>
        </div>
        <MobMenu/>

  
    </>
  )
}

export default CookiePolicy;