import React, { useEffect, useRef } from 'react';
import './index.scss';  // Add your custom styles here if needed
import ai_robuster from "../../../assets/logos/ai--robustness_1_.svg"; 
import collabrate from "../../../assets/logos/collaborate-svgrepo-com 1.svg";
import globe from "../../../assets/logos/globe.svg";
import trophy from "../../../assets/logos/trophy.svg";
import vector from "../../../assets/logos/Vector.svg";

const Cards = () => {
  // Define the ref with a type for each element being an HTMLDivElement or null
  const cardRef = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Add a class when the card comes into view
          entry.target.classList.add('animate-card');
        }
      });
    }, { threshold: 0.5 }); // Trigger when 50% of the card is in view

    // Observe each card, only if it's not null
    cardRef.current.forEach(card => {
      if (card) {
        observer.observe(card);
      }
    });

    // Cleanup observer on component unmount, only if it's not null
    return () => {
      cardRef.current.forEach(card => {
        if (card) {
          observer.unobserve(card);
        }
      });
    };
  }, []);

  return (
    <div className='container'>
      {/* <div className='row '>
        <div className='col-12 d-flex justify-content-center'>
          <p className='text-grow'>#ORGANIZATION STRUCTURE</p>
        </div>
      </div> */}
      <div className='row heading-center'>
        <div className='col-12 d-flex justify-content-center '>
          <h2>Why <span className='highlighted-text'>Walkzero</span> ? Because Growth Starts Here</h2>
        </div>
      </div>
      <div className="row g-4 mt-1 ms-1">
        {/* First row with 2 cards */}
        <div className="col-12 col-md-1 ms-5"></div>

        <div className="col-12 col-md-3 card-div" ref={el => cardRef.current[0] = el}>
          <div className="card-partnership d-flex justify-content-center">
            <img src={ai_robuster} alt="AI Robuster Logo" className="d-flex justify-content-center fs-3 h-25 w-25" />
            <p className="mb-0 ">Next-Gen HR Solutions</p>
            <p className="para-small mt-0 mb-0">
              Automate workspace management with advanced compliance-driven technology
            </p>
          </div>
        </div>

        <div className="col-12 col-md-3 card-div" ref={el => cardRef.current[1] = el}>
          <div className="card-partnership  d-flex justify-content-center">
            <img src={vector} alt="AI Robuster Logo" className="d-flex justify-content-center fs-3 h-25 w-25" />
            <p>Massive Growth Potential</p>
            <p className="para-small">Scale up your business with recurring revenue and global expansion.</p>
          </div>
        </div>

        {/* Card 5 placed between Card 2 and Card 3 */}
        <div className="col-12 col-md-3 mt-4 card-div" ref={el => cardRef.current[2] = el}>
          <div className="card-partnership  d-flex justify-content-center">
            <img src={collabrate} alt="AI Robuster Logo" className="d-flex justify-content-center fs-3 h-25 w-25" />
            <p>Dedicated Training & Support</p>
            <p className="para-small">Get trained from innovative tech brains to lead your business.</p>
          </div>
        </div>
      </div>
      <div className="row mb-4  ms-1">
        {/* Second row with 2 cards */}
        <div className="col-12 col-md-3 mt-4"></div>
        <div className="col-12 col-md-3 mt-4 card-div" ref={el => cardRef.current[3] = el}>
          <div className="card-partnership  d-flex justify-content-center">
            <img src={globe} alt="AI Robuster Logo" className="d-flex justify-content-center fs-3 h-25 w-25" />
            <p>Unmatched Industry Trust</p>
            <p className="para-small">Join a recognized leader in HR automation and workforce transformation.</p>
          </div>
        </div>

        <div className="col-12 col-md-3 mt-4 card-div" ref={el => cardRef.current[4] = el}>
          <div className="card-partnership d-flex justify-content-center">
            <img src={trophy} alt="AI Robuster Logo" className="d-flex justify-content-center fs-3 h-25 w-25" />
            <p>Exclusive Partner Benefits</p>
            <p className="para-small">Get VIP support, early feature access, and co-marketing opportunities.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
