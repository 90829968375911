import React, { useContext, useEffect, useState } from "react";
import { MenuContext } from "../../../../app";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { VERIFY_EMAIL, VERIFY_PHONE,  INDUSTRIES } from "../../../../graphql/queries"
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { BaseBtn } from "../../../../styled-components"
import { TailSpin } from "react-loader-spinner"
import Slider from '@mui/material/Slider';  
import './index.scss';


const msg = 'This field is required';
const url = `${process.env.REACT_APP_API_URL}/quote`;
const industry_url =`${process.env.REACT_APP_WZ_URL}`;

function valuetext(value:any) {
    return `${value}`;
  }

const GetQuoteForm =  ({ selectedService }: { selectedService: any }) => {

  
  const [employeeCount, setEmployeeCount] = useState(25);
    // const [name, setName] = useState('');
    const[firstname, setFirstName] =useState('');
    const[lastname, setLastName]=useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [industry, setIndustry] = useState<any>(null);
    const [firstNameRequired, setFirstNameRequired] = useState(false);
    const [lastNameRequired, setLastNameRequired] = useState(false);
    const [emailRequired, setEmailRequired] = useState(false);
    const [numberRequired, setNumberRequired] = useState(false);
    const [industryRequired, setIndustryRequired] = useState(false);
    const [isLoading, setLoading] = useState(false)
    const [firstNameErMsg, setFirstNameErMsg] = useState('');
    const [lastNameErMsg, setLastNameErMsg] = useState('');
    const [emailErMsg, setEmailErMsg] = useState(msg);
    const [numberErMsg, setNumberErMsg] = useState(msg);
    const [industryErMsg, setIndustryErMsg] = useState(msg);
    const [focused, setFocused] = useState<boolean>(false);
    const [success, setSuccess] = useState(false)
    const [industryList, setIndustryList] = useState([]) 
    const [industrySearchInput, setIndustrySearchInput] = useState('')
    const [commonErr , setCommonErr] =useState(false);
    const [errMessages,setErrorMessages] =useState<string[]>([]);
    
       
useEffect(() => {
    if (commonErr) {
      const timer = setTimeout(() => {
        setCommonErr(false); 
        setErrorMessages([]); 
      }, 5000); 
  
      return () => clearTimeout(timer); 
    }
  }, [commonErr]);
       const getIndustries = async(searchInput:string) => {
            const input = {"paginationArgs":{
                "page":1,
                "limit":10
            },
            "filter":searchInput}
            try {
                const response = await fetch(industry_url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                    query: INDUSTRIES,
                    variables: { input },
                    }),
                })
    
                if (response.ok) {
                    
                    const res = await response.json();
                    const {data, errors} = res
                    if(data){
                        setIndustryList(data.adminIndustries.results)
                    }
                    if(errors){
                        console.error(errors)
                    }
                    return res;
                }
            }
            catch(error:any){
                console.error(`error in signup: ${error.message}`)
            }    
        }
    
        useEffect(() => {
            getIndustries(industrySearchInput)
        },[industrySearchInput])
    
   const { isOn, setQuoteSubmitted, isQuoteSubmitted } = useContext(MenuContext)

   const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


const changeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if(/^[a-zA-Z ]*$/.test(inputValue)){
                setFirstName(event.target.value)
    }
    if(inputValue !== '' && inputValue.includes(' ')){
                setFirstNameErMsg('')
                setFirstNameRequired(false)
            }
  
    if (inputValue === '') {
        setFirstNameRequired(true);
        setFirstNameErMsg(msg);
    } else {
        setFirstNameRequired(false);
        setFirstNameErMsg('');
    }
};
const changeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setLastName(inputValue);
    if (inputValue === '') {
        setLastNameRequired(true);
        setLastNameErMsg(msg);
    } else {
        setLastNameRequired(false);
        setLastNameErMsg('');
    }
};
const changeEmail = (event:React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
    const inputValue = event.target.value;

    if(pattern.test(inputValue)){
        setEmailErMsg('')
        setEmailRequired(false)
    }
    else if(!pattern.test(inputValue) && inputValue !== ''){
        setEmailErMsg('Please enter a valid email')
        setEmailRequired(true)
    }
    else if(inputValue === ''){
        setEmailErMsg(msg)
        setEmailRequired(true)
    }
}

const changeNumber = (value: string, _data: {} | CountryData, _event: React.ChangeEvent<HTMLInputElement>, _formattedValue: string) => {
        const inputValue = value;
        
        const phone = parsePhoneNumberFromString(_formattedValue)
        

        if ((parseInt(inputValue) > 0 && /^\d+$/.test(inputValue)) || (inputValue === '')) {
            setNumber(inputValue);
        }

        if(inputValue !== '' && !phone?.isValid()){
            setNumberErMsg('The phone number entered is not valid.')
            setNumberRequired(true)
        }
        else if(inputValue === ''){
            setNumberErMsg(msg)
            setNumberRequired(true)
        }
        else if(inputValue !== '' && phone?.isValid()){
            setNumberErMsg('')
            setNumberRequired(false)
        }
    }


  
    const changeIndustry = (event: React.SyntheticEvent, newValue: string | null) => { 
        if(newValue){
            setIndustry(newValue);
        }
    }

    const searchIndustry = (event: React.SyntheticEvent, newInputValue: string) =>{
        setIndustrySearchInput(newInputValue)
        setIndustry(newInputValue)

        if(newInputValue === ''){
            setIndustryErMsg(msg)
            setIndustryRequired(true)
        }
        else{
            setIndustryErMsg('')
            setIndustryRequired(false)
        }
    }
    interface VerifyEmailInput {
        email: string;
    }

    interface VerifyPhoneInput {
        phone: string;
    }
  const VerifyPhone = async(input: VerifyPhoneInput) => {
      
              try{
                  const response = await fetch(url, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                        query: VERIFY_PHONE,
                        variables: { input },
                      }),
                    });
                  
                    if (response.ok) {
                      const res = await response.json();
                      const {data, errors} = res
                      if(data) {
                          setNumberRequired(false)
                          setNumberErMsg('')
                      }
                      else if(errors){
                          setNumberRequired(true)
                          setNumberErMsg(errors[0].phone[0])
                      }
                      return res;
                    }
              }
              catch(error:any){
                  console.error(`error in verifying number: ${error.message}`)
              }    
          }

              const VerifyEmail = async(input: VerifyEmailInput) => {
          
                  try{
                      const response = await fetch(url, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            query: VERIFY_EMAIL,
                            variables: { input },
                          }),
                        });
                      
                        if (response.ok) {
                          const res = await response.json();
                          const {data, errors} = res
                          if(data) {
                              setEmailRequired(false)
                              setEmailErMsg('')
                          }
                          else if(errors){
                              setEmailRequired(true)
                              setEmailErMsg(errors[0].email[0])
                          }
                          return res;
                        }
                  }
                  catch(error:any){
                      console.error(`error in verifying email: ${error.message}`)
                  }    
              }
          const checkFirstName = (event:React.FocusEvent<HTMLInputElement>) => {
            const inputValue = event.target.value.trim();
            
            if(inputValue === '' ){
                setFirstNameRequired(true)
                setFirstNameErMsg(msg)
            }
            else{
                setFirstNameRequired(false)
                setFirstNameErMsg('')
            }
        }
        const checkLastName = (event: React.FocusEvent<HTMLInputElement>) => {
            const inputValue = event.target.value.trim();
        
            if (inputValue === '') {
                setLastNameRequired(true);
                setLastNameErMsg(msg);
            } else {
                setLastNameRequired(false);
                setLastNameErMsg('');
            }
        };
    
        const checkEmail = (event:React.FocusEvent<HTMLInputElement>) => {
            const inputValue = event.target.value;
    
            if(!pattern.test(inputValue)){
                setEmailRequired(true)
                setEmailErMsg('Please enter a valid email')
            }
            if(inputValue === ''){
                setEmailRequired(true)
                setEmailErMsg(msg)
            }
            if(pattern.test(inputValue)){
                const input = {'email': inputValue}
                VerifyEmail(input)
            }
        }
    const checkNumber = (event:React.FocusEvent<HTMLInputElement>) => {
        setFocused(false);
        let inputValue = event.target.value;
        if (inputValue.length > 10) {
            inputValue = inputValue.slice(-10);
        }
    
        if(inputValue === ''){
            setNumberRequired(true)
            setNumberErMsg(msg)
        }
        else{
            const input = {'phone': inputValue}
            VerifyPhone(input) 
        }
    }
    const checkIndustry = (event:React.FocusEvent<HTMLInputElement>) => {
        if(event.target.value === ''){
            setIndustryRequired(true)
            setIndustryErMsg(msg)
        }
        else{
            setIndustryRequired(false)
            setIndustryErMsg('')
        }
    }

    const showArrow = () => {
        setFocused(true);
    }
    
    const handleChange = (e:any,newValue: any) => {
        setEmployeeCount(newValue);
      };
    const submitQuote = async(input: any) => {

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(input),
            })

            if (response.ok) {
                const res = await response.json();
                setLoading(false)
                if(res){
                    setSuccess(true)
                    setQuoteSubmitted(true)
                }
                else{
                    setSuccess(false)
                }
               
                return res;
            }
            if (!response.ok) {
                const errorResponse = await response.json(); 
                setLoading(false)
                
                if (errorResponse.email) {
                    setEmailRequired(true);
                    setEmailErMsg(errorResponse.email[0]);
                  }
                let errorMessages = [];
                for (let field in errorResponse) {
                  if (Array.isArray(errorResponse[field])) {
                    errorMessages.push(...errorResponse[field]); 
                  }
                }
                setErrorMessages(errorMessages)
                console.log(errorMessages,"back end")
                
                if(errorMessages.length !== 0){
                    setCommonErr(true)
                    
                }
            
              }
        }
        catch(error:any){
            console.error(`error in signup: ${error.message}`)
        }    
    }
 

      const submitForm: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const data: Record<string, string> = {};
    
      
        formData.forEach((value, key) => {
            data[key] = value as string;
        });
        // console.log(industry,"update")
    
        if(firstname !== ''&& lastname !== '' && email !== '' && number !== ''  && industry !== null && emailErMsg === '' &&  numberErMsg === ''){
            setLoading(true)
           
            data["first_name"] = firstname;
            data["last_name"] = lastname;
            data["email"] = email;
            data["phone"] = number;
            data["industry"] = typeof industry === "string" ? industry : industry.name ?? "";
            data["no_of_employees"] = employeeCount.toString();
            data["service"] = JSON.stringify(selectedService); 
        
    
            submitQuote(data)
           
        }
        
        else{
            if(firstname === ''){
                setFirstNameRequired(true)
                setFirstNameErMsg(msg)
            }else{
                setFirstNameRequired(false)
            }
            if(lastname === ''){
                setLastNameRequired(true)
                setLastNameErMsg(msg);
            }else{
                setLastNameRequired(false)
            }

            if(email === ''){
                setEmailRequired(true)
            }else{
                setEmailRequired(false)
            }

            if(number === ''){
                setNumberRequired(true)
            }else{
                setNumberRequired(false)
            }

            if(industry === null){
                setIndustryRequired(true)
            }else{
                setIndustryRequired(false)
            }   
        }
     };
    
    
  return (
    <div  className="d-flex w-100">
      <div className="w-100">
    
          <>
          <div
  style={{ justifyContent: success ? "center" : "start" }}
  className={`position-relative quote-bg d-flex flex-column p-t-30 p-b-10 ${success ? "bg-col-container-bg" : ""}`}
>

        
          <form onSubmit={submitForm} className="d-flex flex-column align-items-center signup-form-cont align-self-center w-100 " noValidate>

          <div className="w-95">
      <Slider
        aria-label="Temperature"
        defaultValue={30}
        getAriaValueText={valuetext}
        min={0}
        max={1000}
        value={employeeCount}
        onChange={handleChange}
        sx={{
            '& .MuiSlider-thumb': {
              color: '#FFFFFF', 
              border:'1px solid #F45600'
            },
            '& .MuiSlider-track': {
              color: '#F45600', 
            },
            '& .MuiSlider-rail': {
              color: '#D9D9D9', 
            },
          }}
      />
      <div className="text-center mb-18">
        {
            employeeCount === 1000 ? <div className="gray-texts text-12 font-weight-4"> Choose team size - {employeeCount}  + </div>:<div className="gray-texts text-12 font-weight-4"> Choose team size - {employeeCount}  </div>
        }
      
      </div>
    </div>
        
          <TextField className={`inputs ${email && 'has-value'}`} sx={{mt:1.5}} required id="outlined-basic" label="Business Email" variant="outlined" size="small" type="email"  value={email} onChange={changeEmail} onBlur={checkEmail} error={emailRequired?true:false} />
          <span style={{opacity:emailRequired?1:0}} className="text-12 font-1 align-self-start m-l-25 col-error msg-height">{emailErMsg}</span>

      
                    <div className="d-flex flow-row justify-content-between w-95">
                        <div className="w-48   d-flex flex-column">
                        <TextField className={`inputs ${firstname && 'has-value'}`} sx={{mt:1.5}} required id="outlined-basic" label="First Name" variant="outlined" size="small" type="text" value={firstname} onChange={changeFirstName} onBlur={checkFirstName} error={firstNameRequired?true:false}/>
                        <span style={{opacity:firstNameRequired?1:0}} className="text-12 font-1 align-self-start m-l-5 col-error msg-height">{firstNameErMsg}</span>
                        </div>
                          <div className="w-48  d-flex flex-column align-items-end">
                          <TextField className={`inputs ${lastname && 'has-value'}`} sx={{mt:1.5}} required id="outlined-basic" label="Last Name" variant="outlined" size="small" type="text" value={lastname} onChange={changeLastName} onBlur={checkLastName} error={lastNameRequired ?true:false}/>
                          <span style={{opacity:lastNameRequired?1:0}} className="text-12 font-1 align-self-start m-l-20 col-error msg-height ">{lastNameErMsg}</span>
                          </div>
                    

                    </div>
                  
                   
                
                    <FormControl className=""  error={!!numberRequired} sx={{mt:1.5,width:'100%'}}>
                    <div className={`phone-input-container ${focused || number ? 'focused' : ''} ${number ? 'filled' : ''} ${numberRequired ? 'error' : ''} ${number && 'has-value'}`} style={{width:"100%"}}>
                    <label htmlFor="phone-input" className="ml-5 pl-10">Phone Number *</label>
                        <PhoneInput
                            country={'in'}
                            value={number}
                            onChange={changeNumber}
                            onBlur={checkNumber}
                            onFocus={showArrow}
                            placeholder=""
                            inputProps={{
                            id: 'phone-input',  
                            required: true,
                            
                            }}
                            containerClass={numberRequired ? 'phone-input-error' : ''}
                            containerStyle={{ width: '100%'}}
                            inputStyle={{ width: '100%' }}
                        />
                        <span style={{opacity:numberRequired?1:0}} className="text-12 font-1 align-self-start m-l-25 col-error msg-height">{numberErMsg}</span>
                    </div>
                    </FormControl>
                   
                    
                    <FormControl className={`inputs ${industry && 'has-value'}`} sx={{mt:1.5, minWidth: "95%" }}>
                        <Autocomplete
                        aria-selected
                        size="small"
                        value={industry}
                        onChange={changeIndustry}
                        onBlur={checkIndustry}
                        inputValue={industrySearchInput}
                        onInputChange={searchIndustry}
                        disablePortal
                        freeSolo
                        id="free-solo-demo"
                        options={industryList}
                        getOptionLabel={(option:any) => typeof option === 'string' ? option : option.name}
                        isOptionEqualToValue={(option:any, value:any) => {
                            if (typeof value === 'string') {
                              return option.name === value;
                            }
                            return option.id === value.id;
                          }}
                        renderInput={(params) => <TextField {...params} label="Industry *" error={industryRequired?true:false} />}
                        />
                    </FormControl>
                    <span style={{opacity:industryRequired?1:0}} className="text-12 font-1 align-self-start m-l-25 col-error msg-height">{industryErMsg}</span>
               
                
                  
                    <BaseBtn isOn={isOn} type="submit" className='btn-4 rounded-9 text-12 font-weight-6 demo-btn w-px-190 align-self-center m-t-15 m-b-10'>Get Your Quote</BaseBtn>
                    {isLoading && <div className="align-self-center"><TailSpin  visible={true} height="30" width="30" color="#f45600" ariaLabel="tail-spin-loading" radius="1" /></div>}
                    {/* {
                        commonErr && <div>
                            
                        {errMessages.map((msg, index) => (
                            <div key={index} className="text-13 font-1 align-self-start m-l-20 col-error msg-height mt-20">
                            {msg}
                            </div>
                        ))}
                       </div>
                    } */}
                  
                </form>
               </div>
          </>
        
      </div>
    </div>
  );
};

export default GetQuoteForm;
