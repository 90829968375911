import { Link } from "react-router-dom"
import { TailSpin } from "react-loader-spinner"
import { useState, useEffect, useContext } from "react"
import {useAnimation, motion} from "framer-motion"
import { useInView } from "react-intersection-observer";
import { container2, child1, child2  } from "../../../variants"
import { Helmet } from 'react-helmet-async'

import {ReactComponent as Left} from "../../../assets/logos/logo-left.svg"
import {ReactComponent as TopW} from "../../../assets/logos/top-w-curve.svg"
import {ReactComponent as BottomW} from "../../../assets/logos/bottom-w-curve.svg"
import Faqs from '../../landing-page/faqs';
import './index.scss'
import { BaseBtn } from "../../../styled-components";
import { MenuContext } from "../../../app";
import AutoPlay from '../../layout/industry-slide';
import {data, settings} from "../../landing-page/home";
import Testimonial from "../testimonial";
import Pricequote from "../price-quote";

type FaqData = {
    faq:{
        question:string 
        answer:string 
        order: number
    }[]
}

const initialData = {
    faq:[]
}



const PricingMain = () => {

    const [faqs, setFaqs] = useState<FaqData>(initialData);
    const [isLoading, setIsLoading] = useState(true)

    const {isOn} = useContext(MenuContext)

    const getFaqs = async() => {
        setIsLoading(true)
        const url = `${process.env.REACT_APP_API_URL}/faq?page=Pricing`;
        const options = {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            }
        }

        try{
            const response = await fetch(url, options)
            if(response.ok){
                setIsLoading(false)
                const faqs = await response.json()
                setFaqs(faqs.data)
                console.log(faqs.data,"faq")
            }
        }
        catch(error:any){
            console.error(`error in fetching faqs: ${error.message}`)
        }
    }

    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: false,
      });

    useEffect(() => {
        getFaqs()

        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView])


    const child2w = {
        visible: {
          x:0,
          y:0,
          rotate:0,
          transition: {
            duration: 1.5,
            type:'tween'
          }
        },
        hidden: {
          x:-1700,
          y:350,
          rotate:90,
        }
      }
      const Top = {
        visible: {
          x:0,
          y:0,
          transition: {
            duration: 1,
            delay: 1,
            type:'tween'
          }
        },
        hidden: {
          x:0,
          y:-500,
        }
      }
    
      const Bottom = {
        visible: {
          x:0,
          y:0,
          transition: {
            duration: 1,
            delay: 1,
            type:'tween'
          }
        },
        hidden: {
          x:0,
          y:200,
        }
      }


  return (
    <>
    <Helmet>
        <title>Walkzero HRMS Pricing | Flexible Plans for Every Business</title>
        <meta name="description" content="Choose a pricing plan that fits your business—transparent, scalable, and cost-effective HRMS solutions with Walkzero." />
        <meta name="keywords" content="payroll software pricing, HRMS cost, best HR software plans, cloud HR pricing, HR automation software, affordable payroll system" />
    </Helmet>
    <motion.div ref={ref} variants={container2} initial="hidden" animate={ctrls} id="hrMain" className="quote-excellence d-flex flex-column position-relative bg-col-new-light-green">
        <motion.div className="product-inner-pricing-cont d-flex flex-row justify-content-center align-items-center align-self-center">
        
            <motion.div variants={child2} className="d-flex flex-column justify-content-center align-items-center position-relative second-pricing-s">
                <motion.div style={{zIndex:1}} initial={{opacity: 0}} animate={{opacity: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 1]}} transition={{duration: 3, delay: 2, ease: 'easeOut', type:"keyframes"}} className="product-inner-pricing-cont d-flex flex-row justify-content-center align-items-center align-self-center pt-0">
            <motion.div className="step-into-pricing-card d-flex flex-column position-relative">
            <p style={{marginTop:'40px'}} className="text-12 col-quaternary font-weight-5 text-12 mb-1 text-uppercase text-center offer-text"> WHY PAY WHEN YOU CAN HAVE IT FREE?</p>

                <h3 className='product-pricing-heading col-container-font h1-size text-center'>Free <span className='col-secondary'>forever </span></h3>
                <div className="text-center mt-10">
                  <div className="big-screen-price">
                  <h4  className='font font-weight-3  p-size text-center combined-word'>Effortlessly handle employees, leaves, attendance & payroll</h4>
                  <h4  className='font font-weight-3 m-b-20 p-size text-center '>All in one smart platform!</h4>
                  </div>
               

                <h4 className="font font-weight-3 m-b-20 p-size text-center hide-price">
                Effortlessly handle employees, leaves, attendance & payroll- All in one smart platform!
                </h4>
                </div>
               
                <div className="m-r-20 position-relative mob-width-price z-index-1 d-flex justify-content-center align-items-center  w-100">
                    <Link to="/register" className="decoration">
                    <BaseBtn isOn={isOn} className="position-relative border-0 p-y-10 rounded-10 d-flex flex-row justify-content-center align-items-center  w-100 btn-4 w-px-230">
                     <span className="col-white font-bold text-14 font-weight-700 z-index-0">Grab Your Free Plan Now!</span>
                    </BaseBtn>
                    </Link>
                </div>
                <p  className='font font-weight-3 m-b-20 p-size text-center'>Free for up to 5 employees</p>

             </motion.div>
            </motion.div>
                <motion.div variants={child2w} initial="hidden" animate="visible" className="position-absolute p-0 z-index-0">
                    <Left style={{opacity:0.6}} id="Leftw"/>
                </motion.div>
                <motion.div variants={Top} initial="hidden" animate="visible" className="position-absolute hr-top-pr">
                    <TopW id='Payroll-top' style={{opacity:0.6}}/>
                </motion.div>
                <motion.div variants={Bottom} initial="hidden" animate="visible" className="position-absolute p-0 hr-bottom-pr">
                    <BottomW id='Payroll-bottom' style={{opacity:0.6}}/>
                </motion.div>
            </motion.div>
        </motion.div>
        <div className="custom-shape-divider-bottom-1715923248">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
            </svg>
        </div>
    </motion.div>
  <motion.div  className=" d-flex flex-row justify-content-center align-items-center pricequote">
    <Pricequote/>
  </motion.div>
    <Testimonial/>
    <div className='d-flex flex-row justify-content-center align-items-center mb-70'>
            <AutoPlay data={data} settings={settings} />
          </div>
   
    {isLoading? <div className="align-self-center m-t-100 m-b-100"><TailSpin  visible={true} height="30" width="30" color="#f45600" ariaLabel="tail-spin-loading" radius="1" /></div>:<Faqs  faqs={faqs} setFaqs={setFaqs} optionalhead={"Questions related to pricing"} />}
    </>
  )
}

export default PricingMain;