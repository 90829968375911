import './index.scss'
import {ReactComponent as CircleArrow} from '../../../assets/logos/circle-arrow.svg'
import { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom"
import RichTextFieldComponent from '../../layout/rich-text'
import { TailSpin } from 'react-loader-spinner'
import BlogsNavbar from '../../layout/blogs-nav'
import RelatedBlogs from '../related-blogs'
import Inbox from '../inbox'
import { Helmet } from "react-helmet-async"
import MobMenu from '../../layout/mob-menu'

type BlogDetailsType = {
    id: string 
    date: string
    title: string 
    photo: string 
    creator: string
    role: string
    avatar: string
    body: any
    category: string[]
    slug: string
    meta_description: string
    static_url: string
}

const initialData = {
    id: '',
    date: '',
    title: '',
    photo: '',
    creator: '',
    role: '',
    avatar: '',
    body: '',
    category: [],
    slug: '',
    meta_description: '',
    static_url: ''
}


const BlogDetails = () => {

    const [blogDetails, setBlogDetails] = useState<BlogDetailsType>(initialData)
    const [isLoading, setIsLoading] = useState(true)
    const params = useParams()

    const getBlogDetails = async() => {
        setIsLoading(true)
        const slug = params.slug
        
        const url = `${process.env.REACT_APP_API_URL}/blogs/${slug}`;
        const options = {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            }
        }  

        try{
            const response = await fetch(url, options)
            if(response.ok){
                setIsLoading(false)
                const blog = await response.json()
                setBlogDetails(blog)
            }
        }
        catch(error:any){
            console.error(`error in fetching blog details: ${error.message}`)
        }
    }

    useEffect(() => {
        getBlogDetails()
    }, [params.slug])

    const {title, photo, body, date, category, creator, role, avatar, slug, meta_description, static_url} = blogDetails
    // console.log(photo)

    return(
        <>
        <Helmet>
            <title>{title}</title>
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={meta_description} />
            <meta className="og-image" property="og:image" content={static_url} />  
            <meta property="og:url" content={`https://walkzero.com/blog/${slug}`} />
        </Helmet>
        <BlogsNavbar />
        <div id="blogDetails" className='d-flex flex-column align-self-center'>
            {isLoading === false ? <><div className='d-flex flex-row align-items-center blog-heading'>
                <Link to="/blogs"><CircleArrow width={25} style={{rotate:"-135deg", cursor:"pointer"}} className='m-r-10 m-b-5' /></Link>
                <h1 className='font-weight-6 h2-size col-head-clr'>{title}</h1>
            </div>
            <div className='d-between w-95 align-self-center'>
                <span className='text-12 font font-weight-3'>{date}. <span className='hide-mb'>3 min read</span></span> 
                <div className='d-center'>
                    <img className='blog-avatar m-r-10' src={avatar} alt="avatar" />
                    <div>
                        <p className='text-12 font font-weight-3 m-b-0'>{creator}</p>
                        <p className='text-12 font font-weight-6 m-t-0'>{role}</p>
                    </div>
                </div>
            </div>
            <img className='w-90 align-self-center' src={photo} alt="blog" />
            <div>
                <RichTextFieldComponent richTextFieldContent={body}></RichTextFieldComponent>
            </div></> : <div className="align-self-center m-t-100 m-b-100"><TailSpin  visible={true} height="30" width="30" color="#f45600" ariaLabel="tail-spin-loading" radius="1" /></div>}
            {category.length > 0 && <RelatedBlogs categories={category} slug={slug} />}
        </div>
        <Inbox />
        <MobMenu/>
        </>
    )
}

export default BlogDetails