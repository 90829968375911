import { ReactComponent as ButtonLogo } from "../../../assets/logos/button-logo.svg";
import { Link } from "react-router-dom";
import { BaseBtn } from "../../../styled-components";
import { MenuContext } from "../../../app";
import { useContext } from "react";
import React, { useState } from "react";
import { start } from "repl";

const CardRegister: React.FC = () => {
    const { isOn } = useContext(MenuContext);
    const [workEmail, setWorkEmail] = useState("");
    const [emailError, setEmailError] = useState("");

    // Email regex pattern for validation
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    // Handle email input change and validation
    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value;
        setWorkEmail(email);

        // Check if email matches the regex pattern
        if (!email) {
            setEmailError("Email is required");
        } else if (!emailPattern.test(email)) {
            setEmailError("Please enter a valid email address");
        } else {
            setEmailError(""); // Clear error if valid email
        }
    };

    return (
        <div className="container m-b--30 z-index-2 yellow-card" style={{ position: "relative", zIndex: 2 }}>
            <div className="d-flex flex-column justify-content-center align-items-center bg-col-container-bg rounded-21 p-r-30 p-l-30 p-t-30 p-b-30  footer-card-cont">
                <div className="text-center">
                    <p className="col-quaternary font-weight-6 text-12 m-0 mb-2 text-uppercase">A cloud HRMS platform</p>
                    <h3 className="col-container-font font-weight-7 h2-size cent1">
                        Let’s Build the Future of<span className="col-secondary"> HR-Together.</span>
                    </h3>
                    <p className="font font-weight-3 text-14">
                        We're not just a provider; we're your trusted HR tech partner, committed to <br />constant innovation and ensuring mutual success together.
                    </p>
                </div>
                <div className="card-2 d-flex justify-content-center">
                    <div className="m-r-20 position-relative mob-width" style={{ width: "80%" }}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                               // border: `1px solid ${isOn ? "blue" : "#f45404"}`, // Set the border color based on `isOn`
                                borderRadius: "12px",
                                overflow: "hidden", // Ensure both elements are contained within the border
                                boxShadow: emailError ? "0 0 5px rgba(255, 0, 0, 0.4)" : "none", // Add subtle shadow when there's an error
                            }}
                        >
                            {/* Text Input Field */}
                            <input
    type="email"
    placeholder="Add your E-Mail"
    value={workEmail}
    onChange={handleEmailChange} // Capture email input
    style={{
        border: "none",
        outline: "none",
        flex: 1,
        padding: "10px", // Padding inside the text field
        fontSize: "16px", // You can adjust the size to make it look more standard
        fontWeight: "normal", // Remove boldness
        fontStyle: "normal", // Normal font style (no italics)
        color: emailError ? "#ff0000" : "#000", // Change text color based on error (black if no error, red if error)
        fontFamily: "Arial, sans-serif", // Use a basic font family (you can change this to any other basic font)
        width: "100%" // Ensure the input field takes the full width
    }}
/>

                            {/* Button aligned to the right */}
                            <Link
                                to="/partnership/get-started"
                                state={{ workEmail }} // Pass workEmail using 'state' in Link component
                                className="decoration"
                            >
                                <BaseBtn
                                    isOn={isOn}
                                    className="position-relative border p-y-10 d-flex flex-row justify-content-center align-items-center btn-4"
                                    style={{ borderRadius: "0 10px 10px 0" }}
                                >
                                    <ButtonLogo className="me-1 mb-1 " />
                                    <span className="col-white font-bold text-14 font-weight-700 z-index-0">
                                        Become Partner
                                    </span>
                                </BaseBtn>
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Subtle error message */}
                {emailError && (
                    <p
                        style={{
                            color: "#f45404", // Subtle error color
                            marginTop: "8px",
                            fontSize: "10px",
                            opacity: 0.7, // Subtle effect
                            transition: "opacity 0.3s ease",
                            width:"42%"
                             // Smooth transition for error message
                        }}
                    >
                        * {emailError}
                    </p>
                )}
            </div>
        </div>
    );
};

export default CardRegister;
