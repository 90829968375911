import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import HeroSection from "../../payroll/banner-components/banner-main";
import ScheduleImg from '../../animated-images/schedule';
import GeoLocation from '../../animated-images/geolocation';
import Ess from '../../animated-images/ess';
import Mobile from '../../animated-images/mobile-app';


import './index.scss';
const slides = [
  {
    title: 'Payroll ',
    span:'Management',
    subtitle: 'Simplified Payroll with Walkzero:',
    description: 'Smarter, Faster, Easier',
    heroSection: <HeroSection  className='w-90  z-index-1' />
  },
  {
    title: 'Scheduling ',
    span:'Meet',
    subtitle: 'Smart schedules, smoother',
    description: 'workflows.',
    heroSection:  <ScheduleImg  className='w-100 z-index-1' />
  },
  {
    title: 'Geo ',
    span:'fencing & tracking',
    subtitle: 'Right place, right time, every time',
    description: '',
    heroSection:  <GeoLocation className='w-100  z-index-1' />
  },
  {
    title: 'Ess',
    span:'(fencing & tracking)',
    subtitle: 'Your time, your control',
    description: '',
    heroSection: <Ess className='w-100  z-index-1' />
  },
  {
    title: 'Mobile',
    span:'App',
    subtitle: 'HR at your fingertips, anytime',
    description: 'anywhere',
    heroSection: <Mobile className='w-100 z-index-1' />
  },
];

const CarouselComponent = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef<Slider | null>(null);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,       
    autoplaySpeed: 10000, 
    afterChange: (index:any) => setCurrentSlide(index),
    arrows:false,
  };

  return (
    <div className="carousel-container">
      <Slider ref={sliderRef} {...settings} className='my-slider'>
  {slides.map((slide, index) => (
    <div key={index} className="slide-content">
     <div   className="d-flex flex-column justify-content-center align-items-center desc-container ">
            <h4 className="d-flex align-items-center text-15"> {slide.title}  <span className="col-secondary ms-1">{slide.span}  </span> </h4>
            <h4 className="d-flex text-15 font-1 align-items-center m-l-5 col-gray-900 text-center">{slide.subtitle}</h4>
            <h4 className="d-flex text-15 font-1 align-items-center m-l-5 col-gray-900 text-center">{slide.description}</h4>
        </div>
        <div className="d-flex justify-content-center align-items-center w-100">
        <motion.div  key={currentSlide} className="z-index-1 w-100 d-flex justify-content-center align-items-center" initial={{opacity: 0}} animate={{opacity: [0, 0.2, 0.3, 0.4, 0.6, 0.8, 0.9, 1]}} exit={{opacity:0}} transition={{duration: 2, delay: 1, ease: 'easeOut'}} style={{ marginTop: '-55px' }}>
                {slide.heroSection} 
        </motion.div>
        </div> 
    </div>
  ))}
</Slider>
<div className="custom-indicators">
        {slides.map((_, index) => (
            <div
            key={index}
            className={`indicator ${currentSlide === index ? 'active' : ''}`}
            onClick={() => sliderRef.current?.slickGoTo(index)} 
          ></div>
        ))}
      </div>
    </div>
  );
};

export default CarouselComponent;