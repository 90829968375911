import React from 'react';
// import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './app';
import reportWebVitals from './reportWebVitals';
import { createRoot, hydrateRoot } from 'react-dom/client';
// import HubSpotChat from './components/layout/hubspot-chat';

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   // <React.StrictMode>
//     <App />
//   // </React.StrictMode> 
// );

const rootElement = document.getElementById('root');

if (rootElement) {
  const app = (
    <>
      <App />
      {/* <HubSpotChat/> */}
    </>
  );

  if (rootElement.hasChildNodes()) {
    hydrateRoot(rootElement, app);
  } else {
    const root = createRoot(rootElement);
    root.render(app);
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
