
export const VERIFY_EMAIL = `
  query VerifyEmail($input: VerifyEmailInput!) {
    verifyEmail(input: $input) {
      success
    }
  }
`;

export const VERIFY_PHONE = `
  query($input: VerifyPhoneInput!) {
    verifyPhone(input:$input) {
      success
    }
  }
`;

export const VERIFY_DOMAIN = `
  query($input: VerifyDomainInput!) {
    verifyDomain(input:$input) {
      success
    }
  }
`;
export const FORGOT_PASSWORD = `
  mutation($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      id
      first_name
      last_name
      email
      photo
      token
      org {
        domain
      }
    }
  }
`;
export const SET_PASSWORD = `
  mutation($input: SetPasswordInput!) {
    setPassword(input: $input) {
      token
      profile {
        first_name
        last_name
        fullname
        email
        photo
        type
        superuser
      }
      org {
        id
        name
        logo
      }
    }
  }
`;
export const LOGIN_MUTATION = `
mutation login($input: LoginInput!) {
    login(input: $input) {
        token,
        permissions,
        profile {
            id,
            first_name,
            last_name,
            fullname,
            superuser,
            photo,
            badge_url,
            phone,
            type,
            employee {
                id,
                emp_id,
                first_name,
                legal_entity {
                    id,
                    name,
                    time_format,
                    date_format,
                    currency_code
                },
                designation {
                    id,
                    name
                },
                probation_employee_policy {
                    end_date,
                    is_completed
                },
                over_time_policy {
                    id,
                    name
                },
                holidayplan {
                    id,
                    name
                },
                standard_shift {
                    id,
                    name
                },
                on_duty_policy {
                    id,
                    name
                },
                tracking_policy {
                    id,
                    name
                },
                work_from_home_policy {
                    id,
                    name
                }
            }
        },
        org {
            id,
            domain,
            logo
        }
    }
}
`;

export const INDUSTRIES = `
  query($paginationArgs:AdminPaginationArgs,$filter:String){
    adminIndustries(paginationArgs:$paginationArgs,filter:$filter){
        count,
        results{
            id,
            name
        }
    }
}
`;

export const ACCOUNT = `
  query($token : String!) {
    accountStatus(token:$token) {
        name,
        entity_name,
        industry,
        email,
        phone,
        location,
        ip,
        size,
        email_domain,
        fullname,
        domain,
        verified,
        setup
    }
}
`;

export const DOMAIN_LOGIN = `
  query ($input: PreLoginInput!) {
    preLogin(input: $input) {
      domains{
        url
      }
        id
    }
  }`;
  
export const VERIFY_ACCOUNT = `
  query($token : String!) {
  emailVerify(token:$token){
    success
  }
}
`;
