import React, { useState, useEffect ,useContext} from "react";
import Profile from "../Profile";
import Cards from "../Cards";
import SliderMenu from "../SliderMenu";
import CycleLarge from "../CycleLarge";
import CycleSmall from "../CycleSmall";
import Faqs from "../../landing-page/faqs"; // Import your Faqs component
import { TailSpin } from "react-loader-spinner";
import {useAnimation, motion} from "framer-motion"
import { MenuContext } from "../../../app";
import { useInView } from "react-intersection-observer";
import CardRegister from "../CardRegister";


type FaqData = {
  faq:{
      question:any 
      answer:any 
      order: any
  }[]
}

const initialData: FaqData = {
  faq: [],
};


const PartnershipMain = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 997);
 const [faqs, setFaqs] = useState<FaqData>(initialData);
const [isLoading, setIsLoading] = useState(true)
 

   const {isOn} = useContext(MenuContext)

    const getFaqs = async() => {
        setIsLoading(true)
        const url = `${process.env.REACT_APP_API_URL}/faq?page=Partnership`;
        const options = {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            }
        }

        try{
            const response = await fetch(url, options)
            if(response.ok){
                setIsLoading(false)
                const faqs = await response.json()
                setFaqs(faqs.data)
                console.log(faqs.data,"faq")
            }
        }
        catch(error:any){
            console.error(`error in fetching faqs: ${error.message}`)
        }
    }

    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: false,
      });

    useEffect(() => {
        getFaqs()

        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView])


    const child2w = {
        visible: {
          x:0,
          y:0,
          rotate:0,
          transition: {
            duration: 1.5,
            type:'tween'
          }
        },
        hidden: {
          x:-1700,
          y:350,
          rotate:90,
        }
      }
      const Top = {
        visible: {
          x:0,
          y:0,
          transition: {
            duration: 1,
            delay: 1,
            type:'tween'
          }
        },
        hidden: {
          x:0,
          y:-500,
        }
      }
    
      const Bottom = {
        visible: {
          x:0,
          y:0,
          transition: {
            duration: 1,
            delay: 1,
            type:'tween'
          }
        },
        hidden: {
          x:0,
          y:200,
        }
      }


  // Handle resizing for screen size
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 969);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <Profile />
      <Cards />
      <div className="pb-5">
        <SliderMenu />
      </div>
      <div className="mt-5">
        {isLargeScreen ? <CycleLarge /> : <CycleSmall />}
      </div>

      {/* FAQ Section */}
      <div className='d-flex flex-row justify-content-center align-items-center mt-70 mb-70'>
     {<Faqs  faqs={faqs} setFaqs={setFaqs} optionalheadpartner={"Questions related to Partnership"} />}
     </div>
   

     <CardRegister/>

    </div>
   
  );
};

export default PartnershipMain;
