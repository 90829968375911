import './index.scss'
import BlogItems from '../blog-items'
import { Link } from 'react-router-dom'
import { TailSpin } from 'react-loader-spinner'
import {useAnimation, motion} from "framer-motion"
import { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";


type BlogType = {
    blogs:{id: string 
    title: string 
    short_description: string 
    photo: string
    slug: string}[]
    
}

const RecentBlogs = () => {

    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.5,
        triggerOnce: true,
      });

    useEffect(() => {
        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView])

    const [blogList, setBlogList] = useState<BlogType>({blogs:[]});
    const [isLoading, setIsLoading] = useState(true)

    const getBlogs = async() => {
        setIsLoading(true)
        const url = `${process.env.REACT_APP_API_URL}/blogs/recent-blogs`;

        const options = {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            }
        }  

        try{
            const response = await fetch(url, options)
            if(response.ok){
                setIsLoading(false)
                const blogs = await response.json()
                setBlogList(blogs)
            }
        }
        catch(error:any){
            console.error(`error in fetching blogs: ${error.message}`)
        }
    }

    useEffect(() => {
        getBlogs()
    }, [])


    const container = {
        hidden:{
            opacity:1
        },
        visible: (i = 1) => ({
            opacity:1,
            transition:{
                staggerChildren: 0.08,
                delayChildren: 0.04 * i,
            }
        }),
    }

    const child = {
        visible:{
            y:0,
            opacity:1,
            transition:{
                duration:1, 
                type:"tween",
                ease:"easeIn"
            }
        },
        hidden:{
            y:50,
            opacity:0
        }
    }

    return(
        <motion.div ref={ref} variants={container} initial="hidden" animate={ctrls} className='d-flex flex-column justify-conent-center align-items-center align-self-center recent-blog-cont m-t-50 m-b-50'>
            <motion.div variants={child} className='d-flex flex-row justify-content-between w-100 align-items-center'>
                <p className='col-container-font text-22 font-weiht-6'>Recent <span className='col-secondary'>blogs</span></p>
                <hr className='line-btm' />
                <Link to="/blogs" className='col-secondary font-1 font-weight-5 text-12 decoration'>View more</Link>
            </motion.div>
            {!isLoading && <motion.div variants={child} className='d-flex flex-row justify-content-between align-items-center w-100 m-t-40 blog-cont'>
                {blogList.blogs.map(each => {
                    return <BlogItems key={each.id} item={each}/>
                })} 
            </motion.div>}
            {isLoading && <div className="align-self-center m-t-100 m-b-100"><TailSpin  visible={true} height="30" width="30" color="#f45600" ariaLabel="tail-spin-loading" radius="1" /></div>}
        </motion.div>
    )
}

export default RecentBlogs