export const SIGNUP_MUTATION = `
mutation ($input : SignupInput!) {
  signup(input: $input) {
    id,
    email,
    send_newsletter,
    first_name,
    last_name,
    phone,
    name,
    size,
    industry,
    url
  }
}
`;


export const ACTIVATE_ACCOUNT = `
  mutation($token : String!) {
  activateAccount(token:$token) {
    success
  }
}
`;
