import { TailSpin } from 'react-loader-spinner'
import './index.scss'
import Head from '../head'
import OurStory from '../our-story'
import OurVision from '../our-vision'
import OurJourney from '../our-journey'
import OurValues from '../our-values'
import OurInvesters from '../our-investors'
import LeaderShip from '../leadership'
import OurTeam from '../our-team'
import TeamView from '../team-view'
import Mentor from '../mentor'
import Card from '../../landing-page/card'
import { useEffect, useState, useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { MenuContext } from '../../../app'
import {useAnimation, motion} from "framer-motion"
import { useInView } from "react-intersection-observer";
import { container2 } from '../../../variants'
import MobMenu from '../../layout/mob-menu'

type employeeItem = {
    id: string
    designation: string
    experience: string
    fullname: string
    linkedin: string
    photo: string
    show: boolean
}
const About = () => {

    const {aboutUsData, setAboutUsData} = useContext(MenuContext)
    const [isLoading, setIsLoading] = useState(true)

    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true,
      });

    const toggleShow = (id:string) => {
        setAboutUsData((prevAboutUsData:any)  => ({ 
            ...prevAboutUsData, 
            employee: prevAboutUsData.employee.map((emp:employeeItem) => {
                if(id === emp.id){
                    return {...emp, show:!emp.show}
                }
                return emp
            })
        }))
    }

    const getAboutUs = async() =>{
        const url =  `${process.env.REACT_APP_API_URL}/about-us`;

        const options = {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            }
        }
        try{
            const response = await fetch(url, options)
            if(response.ok){
                setIsLoading(false)
                const res = await response.json()
                setAboutUsData(res)
            }
        }
        catch(error:any){
            console.error(`error in fetching about-us data: ${error.message}`)
        } 
    }

    console.log(aboutUsData)

    useEffect(()=>{
        getAboutUs()

        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    },[ctrls, inView])

    const child = {
        visible: {
            opacity:1,
            transition: {
                duration:1,
                type:"tween",
                staggerChildren:0.2,
                delaychildren:0.1
            }
        },
        hidden:{
            opacity:0
        }
    }

    const child1 = {
        visible: {
            y:0,
            transition: {
                duration:0.1,
                type:"tween"
            }
        },
        hidden:{
            y:50
        }
    }

    return(
        <>
            <Helmet>
                <title>About us | Walkzero Technologies Private Limited</title>
                <meta name="description" content="Learn how Walkzero is transforming HR operations with smart payroll automation and workforce management solutions." />
                <meta name="keywords" content="HR tech company, Walkzero solutions, payroll software provider, HR automation leader, digital workforce solutions, cloud HRMS" />
            </Helmet>
            <Head />
            <div className='d-flex flex-column align-self-center about-cont'>
                <OurStory story={aboutUsData.story}/>
                <OurVision />
            </div>
            {aboutUsData.journey.length > 0 && <OurJourney journey={aboutUsData.journey} />}
            <OurValues culture={aboutUsData.culture}/>
            <OurInvesters />
            <div className='d-flex flex-column bg-col-mixed-white-blue position-relative p-b-80'>
                <LeaderShip />
                <div className='meet align-self-center'>
                    <p className="col-quaternary font-weight-5 text-12 mb-1 text-uppercase">Driven by Diversity, United by Vision</p>
                    <h1 className="col-container-font h2-size mb-0">Meet Our <span className='col-secondary'>Team</span></h1>
                </div>
                {isLoading === false ? <motion.div ref={ref} variants={container2} initial="hidden" animate={ctrls} className='d-flex flex-row justify-content-center team-members align-self-center m-b-40'>
                {aboutUsData.employee.map((each:employeeItem, index:number) => {
                        return <motion.div variants={child} key={index} className='d-flex flex-column align-items-center'>
                                    <motion.div variants={child1} onMouseEnter={()=>toggleShow(each.id)} onMouseLeave={() => toggleShow(each.id)} className='emp-img d-flex flex-column justify-content-end align-items-center z-index-1' style={{backgroundImage:`url(${each.photo})`}}>
                                        <motion.div initial={false}  animate={{y:each.show?0:50, opacity:each.show?1:0, transition:{duration:0.5}}} className='d-flex flex-column justify-content-start  align-items-center bg-col-light-primary-transparent w-100 emp-detail-height'>
                                            <p className='font-1 col-white font-weight-6 m-b-0 text-center emp-text'>{each.fullname}</p>
                                            <p className='font-1 col-secondary font-weight-6 m-t-0 m-b-0 text-center emp-text'>{each.designation}</p>
                                            {each.linkedin && <a href={each.linkedin} target='_blank' rel="noreferrer" className='linkedin-btn'>in</a>}
                                        </motion.div>
                                    </motion.div>
                                    <div className='bg-col-new-sky-blue-clr d-flex flex-row justify-content-center align-items-center z-index-5 exp-btn'>{each.experience} Yrs</div>
                                </motion.div>
                    })}
                </motion.div>: <div className="align-self-center m-t-100 m-b-100"><TailSpin  visible={true} height="30" width="30" color="#f45600" ariaLabel="tail-spin-loading" radius="1" /></div>}
                <OurTeam />
                <div className="custom-shape-divider-top-1713956975">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                    </svg>
                </div>
                <div className="custom-shape-divider-bottom-1713957171">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                    </svg>
                </div>
            </div>
            <TeamView />
            <Mentor />
            <Card />
            <MobMenu/>
        </>
    )
}

export default About