import { BsGlobe2 } from "react-icons/bs";
import {ReactComponent as Logo} from "../../../assets/logos/logo2.svg"
import {ReactComponent as ArrowIcons} from "../../../assets/logos/arrow-icons.svg"
import {ReactComponent as Twitter} from "../../../assets/logos/twitter.svg"
import {ReactComponent as Instagram} from "../../../assets/logos/instagram.svg"
import {ReactComponent as Linkedin} from "../../../assets/logos/linkedin.svg"
import {ReactComponent as Tiktok} from "../../../assets/logos/tiktok.svg"
import {ReactComponent as Youtube} from "../../../assets/logos/youtube.svg"
import {ReactComponent as Fb} from "../../../assets/logos/fb.svg"
import {ReactComponent as Appstore} from "../../../assets/logos/appstore.svg"
import {ReactComponent as Playstore} from "../../../assets/logos/playstore-badge.svg"
// import playstore from "../../../assets/images/playstore.png"
import {ReactComponent as Android} from '../../../assets/logos/android.svg';

// import appstore from "../../../assets/images/appstore.png"
import {Link, useLocation} from "react-router-dom"
import "./index.scss"


const Footer = () => {

    const location = useLocation()
    const showFooter = location.pathname !== "/book-a-demo" && location.pathname !== "/sign-up" && !location.pathname.includes("/account/verify/") && !location.pathname.includes("/account/activate/") && location.pathname !== "/hr-products" && location.pathname !== "/login" && location.pathname !== "/register"&& location.pathname !== "/partnership/get-started"; 

    const empty = <div></div>

    return(
        <>
        {showFooter?(<div className="footer-container">
            <div className="size-container">
                <div className="logo-container">
                    <div className="first-container">
                        <Logo />
                        <div className="language-container">
                            <div className="icon-container">
                                <BsGlobe2 className="icon"/>
                                <span className="lang">English</span>
                            </div>
                            <ArrowIcons className="svg-size" />
                        </div>
                    </div>
                    <div className="second-container">

                        {/* <img className="img1" src={playstore} alt="Google Play" /> */}
                        <Playstore className="img1"/>
                        {/* <div style={{width:300}}>
                            <Android/>
                        </div> */}
                        {/* <img className="img2" src={appstore} alt="App Store" /> */}
                        <Appstore className="img2"/>
                    </div>
                </div>
                <div className="footer-content">
                    <ul className="footer-list core-hr-cont">
                        <li className="topic">Core HR</li>
                        <li><Link to="/">HR Software</Link></li>
                        <li><Link to="/ess">ESS portal</Link></li>
                        <li><Link to="/hr-hub">Employee profiles</Link></li>
                        <li><Link to="/ess">Documents</Link></li>
                        {/* <li><Link to="">Helpdesk</Link></li> */}
                        <li><Link to="/payroll">HR analytics</Link></li>
                    </ul>
                    <ul className="footer-list time-attendance-cont">
                        <li className="topic">Time Attendance</li>
                        <li><Link to="/attendance-management">Attendance Management</Link></li>
                        <li><Link to="/leave-management">Leave Management</Link></li>
                        <li><Link to="/ess">GPS/Mobile Attendance</Link></li>
                        <li><Link to="/geo-fencing">Geo-fencing</Link></li>
                        <li><Link to="/attendance-management">Shift Management</Link></li>
                    </ul>
                    <ul className="footer-list payroll-cont">
                        <li className="topic">Payroll</li>
                        <li><Link to="/payroll">Payroll software</Link></li>
                        <li><Link to="/payroll">Compliance</Link></li>
                        <li><Link to="/payroll">Expense Management</Link></li>
                        <li><Link to="/payroll">Compensation</Link></li>
                        <li><Link to="/payroll">PF</Link></li>
                        <li><Link to="/payroll">ESI</Link></li>
                    </ul>
                    <ul className="footer-list company-cont">
                        <li className="topic">Company</li>
                        <li><Link to="/about-us">About us</Link></li>
                        <li><Link to="">Careers</Link></li>
                        <li><Link to="/blogs">Blog</Link></li>
                    </ul>
                    <ul className="footer-list contact-us-cont">
                        <li className="topic">Contact us</li>
                        <li>Sales: +91 98846 20496 | sales@walkzero.com <br/>
                        Support: info@walkzero.com <br/>
                        Address: Walkzero Technologies Private Limited, <br/>
                        Greeta Techpark, Industrial Estate, Perungudi, <br/>
                        Chennai, Tamil Nadu - 600032
                        </li> 
                        <li className="col-secondary no-op">#TamilNadu. <span className="col-white">Made in India.</span> <span className="col-quaternary">Made for the World.</span></li>
                    </ul>
                </div>
                <div className="container-fluid p-l-0 p-r-0 w-100">
                    <hr className="footer-line" />
                </div>
                <div className="bottom-container m-t--10 m-b-15">
                    <div className="copy-rights">
                        <p className="para">Copyright © 2024 - walkzero.com</p>
                        <p className="para">All Rights Reserved | <Link className="para-link" to="/privacy-policy">Privacy Policy</Link> | <Link className="para-link" to="/terms-and-conditions"> Terms & Conditions </Link> | <Link className="para-link" to="/cookie-policy"> Cookie Policy</Link> | GDPR </p>
                    </div>
                    <div className="footer-icons">
                        <a href="https://www.facebook.com/walkzero/" target="_blank" rel="noopener noreferrer">
                            <Fb className="i-color" height={25} />
                        </a>
                        <a href="https://www.twitter.com/yourprofile" target="_blank" rel="noopener noreferrer">
                            <Twitter className="i-color" height={30} />
                        </a>
                        <a href="https://www.linkedin.com/company/walkzero/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
                            <Linkedin className="i-color" height={30} />
                        </a>
                        <a href="https://www.youtube.com/@walk-zero" target="_blank" rel="noopener noreferrer">
                            <Youtube className="i-color" height={30} />
                        </a>
                        <a href="https://www.instagram.com/yourprofile" target="_blank" rel="noopener noreferrer">
                            <Instagram className="i-color" height={30} />
                        </a>
                    </div>

                </div>
            </div>
        </div>):empty}
        </>
    )
}

export default Footer