import React, { useState, useEffect, useRef } from 'react';
import './index.scss';

import cycleicon1 from "../../../assets/logos/cycle-icon1.svg";
import cycleicon2 from "../../../assets/logos/cycle-icon2.svg";
import cycleicon3 from "../../../assets/logos/cycle-icon3.svg";
import cycleicon4 from "../../../assets/logos/cycle-icon4.svg";
import cycleicon5 from "../../../assets/logos/cycle-icon5.svg";

const CycleLarge = () => {
  const [showArrow, setShowArrow] = useState(false);
  const [rotateArrow, setRotateArrow] = useState(false);  // For rotation animation
  const [showIcon, setShowIcon] = useState(false);
  const [showBulletText, setShowBulletText] = useState(false);

  const cycleRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setRotateArrow(true);
          setTimeout(() => {
            setShowArrow(true);  // Show the arrow after rotation
            setTimeout(() => setShowIcon(true), 500);  // Show icons after 500ms
            setTimeout(() => setShowBulletText(true), 1000);  // Show bullet text after another 500ms
          }, 1000);
        }
      },
      {
        threshold: 0.5, // Trigger when 50% of the component is in view
      }
    );

    if (cycleRef.current) {
      observer.observe(cycleRef.current);
    }

    return () => {
      if (cycleRef.current) {
        observer.unobserve(cycleRef.current);
      }
    };
  }, []);

  return (
    <>
      <div className="cycle-life-cycle" ref={cycleRef}>
        <div className="cycle-circle-container">
          {/* Circular Arrow */}
          <div className={`cycle-arrow-wrapper ${rotateArrow ? 'rotate' : ''}`}>
            <div className={`cycle-arrow ${showArrow ? 'show' : ''}`}></div>
            {/* <div className="cycle-bullet-start"></div>
            <div className="cycle-bullet-end"></div> */}

            {/* Container for carousel */}
            <div className="cycle-bullet-container">
              <div className={`cycle-bullet-1 ${showIcon ? 'show' : ''}`}>
                <div className="bullet-text bullet-text-1">
                  <h3>Onboarding & Training</h3>
                  <p>Become an expert in selling and implementing Walkzero solutions.</p>
                </div>
                <img src={cycleicon1} alt="Onboarding Icon" className="cycle-icon-lg" />
              </div>
              <div className={`cycle-bullet-2 ${showIcon ? 'show' : ''}`}>
                <div className="bullet-text bullet-text-2">
                  <h3>Dedicated Partner Success Team</h3>
                  <p>Get direct access to expert support, marketing materials, and sales enabling tools</p>
                </div>
                <img src={cycleicon2} alt="Onboarding Icon" className="cycle-icon-lg" />
              </div>
              <div className={`cycle-bullet-3 ${showIcon ? 'show' : ''}`}>
                <div className="bullet-text bullet-text-3">
                  <h3>Co-Marketing & Lead Generation</h3>
                  <p>Drive demand with joint marketing campaigns, webinars, and events.</p>
                </div>
                <img src={cycleicon3} alt="Onboarding Icon" className="cycle-icon-lg" />
              </div>
              <div className={`cycle-bullet-4 ${showIcon ? 'show' : ''}`}>
                <div className="bullet-text bullet-text-4">
                  <h3>Early Access to New Features</h3>
                  <p>Stay ahead with exclusive previews of Walkzero’s latest innovations.</p>
                </div>
                <img src={cycleicon4} alt="Onboarding Icon" className="cycle-icon-lg" />
              </div>
              <div className={`cycle-bullet-5 ${showIcon ? 'show' : ''}`}>
                <div className="bullet-text bullet-text-5">
                  <h3>Global Expansion Opportunities</h3>
                  <p>Tap into new markets, industries, and customer segments worldwide.</p>
                </div>
                <img src={cycleicon5} alt="Onboarding Icon" className="cycle-icon-lg" />
              </div>
            </div>
          </div>

          {/* Text Content */}
          <div className="cycle-text-wrapper">
            {/* <p className="text-grow"><strong>#ORGANISATION STRUCTURE</strong></p> */}
            <h2><strong>What Sets <span className="col-secondary">Walkzero</span> <br />Partnerships Apart?</strong></h2>
            <p>When you join the Walkzero Partner Network, you gain<br /> more than just a business opportunity—you gain a<br /> competitive edge.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CycleLarge;
