import { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { ReactComponent as LeftArrow } from '../../../assets/logos/hr-left-arrow.svg';
import Grp1 from '../../../assets/images/Grpimg1.svg';
import Grp2 from '../../../assets/images/Grpimg2.svg';
import Grp3 from '../../../assets/images/Grpimg3.svg';
import Grp4 from '../../../assets/images/Grpimg4.svg';
import { Link } from 'react-router-dom';
import { ReactComponent as ButtonLogo } from "../../../assets/logos/button-logo.svg";
import { MenuContext } from "../../../app"
import { useContext } from "react"
import { BaseBtn, DemoBtn } from "../../../styled-components"


import './index.scss';

const menu = [
    {
        id: 0, name: "Reseller Partner", partnerStruct: [
            {
                id: "1",
                head: <p className='m-0'> <span className='col-secondary'>Reseller </span>Partner </p>,
                title: (
                    <>
                        Already selling to businesses? Want to expand your portfolio?<br />
                        Offer Walkzero’s workforce automation solutions as part of your portfolio.
                        Sell directly to businesses, expand your product offerings, and build long-term revenue streams.
                    </>
                ),
                image: Grp1
            },]
    },

    // {
    //     id: 1, name: "Saas Franchise Partner", partnerStruct: [
    //         {
    //             id: "1",
    //             head: <p className='m-0'> <span className='col-secondary'>Saas Franchise</span> Partner</p>,
    //             title: (
    //                 <>
    //                     Want to run your own business? Looking for a steady recurring income?<br />
    //                     Connect businesses to Walkzero and earn commissions on every successful deal.
    //                     Simply refer potential clients, and our team takes care of everything—from demos to onboarding.
    //                 </>
    //             ),
    //             image: Grp2
    //         },]
    // },
    {
        id: 1, name: "Referral Partner", partnerStruct: [
            {
                id: "1",
                head: <p className='m-0'> <span className='col-secondary'>Referral</span> Partner</p>,
                title: (
                    <>
                        Well-connected? Prefer not to sell directly?<br />
                        Connect businesses to Walkzero and earn commissions on every successful deal.
                        Simply refer potential clients, and our team takes care of everything—from demos to onboarding.
                    </>
                ),
                image: Grp3
            },]
    },

    {
        id: 2, name: "Joint Venture Partner", partnerStruct: [
            {
                id: "1",
                head: <p className='m-0'><span className='col-secondary'>Joint Venture</span> Partner</p>,
                title: (
                    <>
                        Have industry insights? Ready to build something big?<br />
                        Collaborate with Walkzero to create groundbreaking HR solutions.
                        Leverage shared resources, expertise, and market reach to drive mutual growth and innovation.
                    </>
                ),
                image: Grp4
            },
        ]
    }

];

const Collapser = () => {
    const [activeMenu, setActiveMenu] = useState(0);
    const ref = useRef<(HTMLDivElement | null)[]>([]);

    const props = useContext(MenuContext);
    const { isOn } = props
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const visibleSectionId = entry.target.id;
                        const activeIndex = menu.findIndex(
                            (each: any) => `section${each.id}` === visibleSectionId
                        );
                        setActiveMenu(activeIndex);
                    }
                });
            },
            {
                threshold: 1.0,
            }
        );

        ref.current.forEach((section) => {
            if (section) {
                observer.observe(section);
            }
        });

        return () => {
            ref.current.forEach((section) => {
                if (section) {
                    observer.unobserve(section);
                }
            });
        };
    }, []);

    const scrollToView = (id: number, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        const element = document.getElementById(`section${id}`);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
            setActiveMenu(id); // Update active menu when the menu item is clicked
        }
    };

    return (
        <>
            <div className='section3'>
                <div className='  d-flex flex-column justify-content-center align-items-left collapser-components-wrapper ps-4 ms-5'>
                    {/* <p className='col-quaternary'>#Organization Structure</p> */}
                    <h2><span className='col-secondary'>Choose Your Path -</span> Build Your Success.</h2>
                    <p>We offer flexible partnership models to match your strengths and business goals</p></div>
                <div className='d-flex flex-column justify-content-center align-items-center collapser-components-wrapper '>
                    {/* Sticky menu bar */}

                    <div className='d-flex flex-column justify-content-center align-items-center z-index-1 sticky-menu-bar'>

                        <div className='d-flex flex-row justify-content-start align-items-center payroll-menu '>
                            <div className='d-flex flex-row justify-content-between align-items-center lexend-deca font-weight-3 text-14 payroll-nav '>
                                {menu.map((each: any) => (
                                    <div key={each.id} className='payroll-menu-items '>
                                        <a
                                            style={{
                                                color: activeMenu === each.id ? "#F45600" : "#000000",
                                                textDecoration: 'none'
                                            }}
                                            href={`#section${each.id}`}
                                            onClick={(e) => { scrollToView(each.id, e) }}>
                                            {each.name}
                                        </a>
                                    </div>
                                ))}
                                <div className='mob-payroll-nav'>
                                    <a style={{ color: "#F45600", textDecoration: "none" }}>
                                        {menu[activeMenu].name}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Menu Content */}
                    <motion.div className='d-flex flex-column justify-content-center align-items-center collapser-menu-data pt-5'>
                        {menu.map((each: any, index: number) => (
                            <motion.div
                                key={index}
                                initial={{ opacity: 0 }}
                                whileInView={{ opacity: 1 }}
                                transition={{
                                    duration: 1.3,
                                    type: "spring",
                                    damping: 20,
                                    stiffness: 120,
                                    ease: "easeInOut"
                                }}
                                viewport={{ once: false, amount: 0.2 }}
                                ref={(el) => (ref.current[index] = el)}
                                id={`section${each.id}`}
                                className='d-flex section-id flex-column justify-content-between align-items-center each-collapser z-index-0'>
                                {/* Left arrow icon */}
                                <LeftArrow style={{ rotate: "-90deg" }} className='payroll-left-arrow' />


                                {/* Loop through the widgets */}
                                {each.partnerStruct.map((widget: any) => (
                                    <motion.div key={widget.id} className='d-flex flex-row justify-content-center align-items-center collapser-menu-content'>
                                        <motion.div
                                            className={`d-flex ${each.id === 1 || each.id === 3 ? 'flex-row-reverse' : 'flex-row'} justify-content-between align-items-center pr-top`}
                                            style={{
                                                width: '100%',
                                                flexDirection: each.id === 1 || each.id === 3 ? 'row-reverse' : 'row',
                                                padding: '40px 50px',  /* Adjust padding here */
                                            }}
                                        >
                                            {/* Title Section (Head and Title) */}
                                            <motion.div className='d-flex flex-column justify-content-start align-items-start collapser-menu-title' style={{ flex: 1 }}>
                                                <motion.div className='d-flex flex-row justify-content-start align-items-center sora font-weight-6 collapser-head'>
                                                    {widget.head}
                                                </motion.div>
                                                <motion.p className='lexend-deca font-weight-3 w-90 pt-3' style={{ flex: 1 }}>
                                                    {widget.title}

                                                </motion.p>

                                                <Link
                                                    to="/partnership/get-started"
                                                    className="decoration d-flex flex-row justify-content-center align-items-center pt-2 w-auto btn-together btn-cont z-index-0"
                                                >
                                                    {/* <BaseBtn
                                                        isOn={isOn}
                                                        className="position-relative border-0 p-y-10 rounded-10 d-flex flex-row justify-content-center align-items-center w-100 btn-4"
                                                    > */}
                                                    {/* <ButtonLogo className=" ms-1 me-1 logo-align" /> */}
                                                    {/* <button
                                                        className="border-1 p-1 rounded-10 d-flex justify-content-center align-items-center"
                                                        style={{
                                                            borderColor: '#f45807', // Set the border color
                                                            backgroundColor: 'transparent', // Make the background transparent
                                                            color: '#f45807', // Set the font color to match the border color
                                                            outline: 'none', // Remove the default outline on focus
                                                        }}
                                                    >
                                                        <span className="ms-1 me-1 font-bold text-14 font-weight-700 z-index-0 align-middle">
                                                            To Know More
                                                        </span>
                                                    </button> */}
                                                      <div className="mob-width w-100">
                                                                                <Link to='/partnership/get-started' className="demo">
                                                                                    <DemoBtn isOn={isOn} className="rounded-10 font-bold text-14 font-weight-700 book-demo-btn w-100 p-y-10 btn-4-alternate">Know More</DemoBtn>
                                                                                </Link>
                                                                            </div>


                                                    {/* </BaseBtn> */}
                                                </Link>
                                            </motion.div>

                                            {/* Image Section */}
                                            <motion.div className='d-flex flex-row justify-content-end align-items-center collapser-menu-widget' style={{ flex: 1 }}>
                                                <img src={widget.image} alt="Icon" style={{ width: '100%', objectFit: 'cover' }} />
                                            </motion.div>
                                        </motion.div>
                                    </motion.div>
                                ))}
                            </motion.div>
                        ))}
                    </motion.div>

                </div>
            </div>
        </>
    );
};

export default Collapser;
