import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet-async'
import { useState } from "react"
import { ReactComponent as Logo } from "../../../assets/logos/logo.svg"
import { data2 } from "../../product-menu/product-menu";
import { useContext } from "react";
import { MenuContext } from "../../../app";
import Avatar from "../../../assets/images/avatar.png"
import TextField from '@mui/material/TextField';

import { ReactComponent as Star } from '../../../assets/logos/star.svg'

import 'react-phone-input-2/lib/material.css';
import { TailSpin } from "react-loader-spinner"
import { BaseBtn } from "../../../styled-components"

import { ReactComponent as Apple } from '../../../assets/logos/apple.svg';
import { ReactComponent as Android } from '../../../assets/logos/android.svg';
import { ReactComponent as Soc } from '../../../assets/logos/soc.svg';
import { ReactComponent as Iso } from '../../../assets/logos/iso.svg';
import { ReactComponent as Gdpr } from '../../../assets/logos/gdpr.svg';
import { ReactComponent as TopDesign } from '../../../assets/logos/top-signup-design.svg';
import { ReactComponent as BottomDesign } from '../../../assets/logos/bottom-signup-design.svg';
import CarouselComponent from "../../signup/carousel-component";
import { data, settings } from "../../landing-page/home";
import AutoPlay from "../../layout/industry-slide";
import FormControl from '@mui/material/FormControl';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useLocation } from 'react-router-dom';
import { validateEmail } from './Utils/emailUtils';

const msg = 'This field is required';
const url = `${process.env.REACT_APP_API_URL}/submit-partner-form`;
const url_validate_mail = `${process.env.REACT_APP_API_URL}/validate-email`;


const FormGrow = () => {
    const location = useLocation();
    const { workEmail } = location.state || {};
    const [fullName, setFullName] = useState("");
    const [designation, setDesignation] = useState("");
    const [workEmailState, setWorkEmailState] = useState(workEmail || "");
    const [contactNumber, setContactNumber] = useState("");
    const [whatDoYouNeed, setWhatDoYouNeed] = useState("");

    const [fullNameRequired, setFullNameRequired] = useState(false);

    const [designationRequired, setDesignationRequired] = useState(false);
    const [workEmailRequired, setWorkEmailRequired] = useState(false);
    const [contactNumberRequired, setContactNumberRequired] = useState(false);
    const [whatDoYouNeedRequired, setWhatDoYouNeedRequired] = useState(false);

    const [fullNameErMsg, setFullNameErMsg] = useState("");
    const [designationErMsg, setDesignationErMsg] = useState("");
    const [workEmailErMsg, setWorkEmailErMsg] = useState("");
    const [contactNumberErMsg, setContactNumberErMsg] = useState("");
    const [whatDoYouNeedErMsg, setWhatDoYouNeedErMsg] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [focused, setFocused] = useState<boolean>(false);


    const { setIsOpen, isOn, setIsProductOpen, setModules } = useContext(MenuContext)

    
    const clickLogo = () => {
        setIsOpen(false)
        setIsProductOpen(false)
        setModules(data2)
    }

    const changeFullName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFullName(e.target.value);
    };
    const changeDesignation = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDesignation(e.target.value);
    };

    const changeWorkEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setWorkEmailState(e.target.value);
        setWorkEmailRequired(false);  // Clear error state when user types
        setWorkEmailErMsg("");
    };



    // -----?
   

    const changeContactNumber = (value: string, _data: {} | CountryData, _event: React.ChangeEvent<HTMLInputElement>, _formattedValue: string) => {
        const inputValue = value;

        const phone = parsePhoneNumberFromString(_formattedValue);

        if ((parseInt(inputValue) > 0 && /^\d+$/.test(inputValue)) || (inputValue === '')) {
            setContactNumber(inputValue);
        }

        if (inputValue !== '' && !phone?.isValid()) {
            setContactNumberErMsg('The contact number entered is not valid.');
            setContactNumberRequired(true);
        }
        else if (inputValue === '') {
            setContactNumberErMsg(msg);
            setContactNumberRequired(true);
        }
        else if (inputValue !== '' && phone?.isValid()) {
            setContactNumberErMsg('');
            setContactNumberRequired(false);
        }
    };

    const changeWhatDoYouNeed = (e: React.ChangeEvent<HTMLInputElement>) => {
        setWhatDoYouNeed(e.target.value);
    };

    const checkFullName = () => {
        if (!fullName) {
            setFullNameRequired(true);
            setFullNameErMsg("Full name is required.");
        } else {
            setFullNameRequired(false);
            setFullNameErMsg("");
        }
    };

    const checkDesignation = () => {
        if (!designation) {
            setDesignationRequired(true);
            setDesignationErMsg("Designation is required.");
        } else {
            setDesignationRequired(false);
            setDesignationErMsg("");
        }
    };

    const checkWorkEmail = async () => {
        const email = workEmailState;
    
        try {
            // Make API request to validate the email
            const response = await fetch(url_validate_mail, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ work_email: email }),
            });
    
            const data = await response.json();
    
            if (response.ok) {
                // If the backend response is successful
                setWorkEmailRequired(false);
                setWorkEmailErMsg("");  // Clear any previous error
            } else {
                // If the backend returns errors, handle them
                setWorkEmailRequired(true);
                
                // Check if there are specific error messages from the backend
                if (data.error) {
                    const errorMessages = Object.values(data.error).flat();
                    setWorkEmailErMsg(errorMessages.join(' '));  // Join multiple errors if they exist
                } else {
                    setWorkEmailErMsg("An unknown error occurred.");
                }
            }
        } catch (error) {
            setWorkEmailRequired(true);
            setWorkEmailErMsg("There was an error while validating the email.");
        }
    };
    
    
    const checkContactNumber = () => {
        if (!contactNumber) {
            setContactNumberRequired(true);
            setContactNumberErMsg("Contact number is required.");
        } else {
            setContactNumberRequired(false);
            setContactNumberErMsg("");
        }
    };

    const checkWhatDoYouNeed = () => {
        if (!whatDoYouNeed) {
            setWhatDoYouNeedRequired(true);
            setWhatDoYouNeedErMsg("Please specify what you need from us.");
        } else {
            setWhatDoYouNeedRequired(false);
            setWhatDoYouNeedErMsg("");
        }
    };

    const showArrow = () => {
        setFocused(true);
    }

    const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        checkFullName();
        checkDesignation();
        checkWorkEmail();  // This function already sets workEmailErMsg
        checkContactNumber();
        checkWhatDoYouNeed();

        if (
            !fullNameRequired &&
            !designationRequired &&
            !workEmailRequired &&
            !contactNumberRequired &&
            !whatDoYouNeedRequired
        ) {
            // Ensure the email is valid before submitting the form
            if (workEmailErMsg) {
                return; // Prevent form submission if email validation failed
            }

            const formData = {
                full_name: fullName,
                designation: designation,
                work_email: workEmailState,  // Use workEmailState here
                contact_number: contactNumber,
                what_do_you_need: whatDoYouNeed,
            };

            try {
                setIsLoading(true);
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                const data = await response.json();

                if (response.ok) {
                    setSuccess(true);
                } else {
                    console.log(data);  // Log errors if any
                }
            } catch (error) {
                console.error("Error submitting form:", error);
            } finally {
                setIsLoading(false);
            }

            console.log(formData);
        }
    };


    return (
        <>
            <Helmet>
                <title>Walkzero: Join the Future of HRM | HCM</title>
                <meta name="description" content="Sign up for Walkzero today! Experience seamless HR and payroll automation with a free trial and hands-on demo." />
                <meta name="keywords" content="HRMS free trial, payroll software demo, Walkzero sign-up, employee management software, cloud HRMS, HR automation tools" />
            </Helmet>
            <div style={{ minHeight: success ? "90vh" : "auto" }} className="signup-flex-container d-flex flex-row align-items-start">

                <div className="d-flex flex-column justify-content-start align-items-center bg-col-white sign-up-first-cont p-b-10 bg-col-container-bg">
                    <div className="top-design">
                        <TopDesign className="top-design-svg" />
                    </div>
                    <div className="bottom-design">
                        <BottomDesign className="bottom-design-svg" />
                    </div>
                    <div className="d-flex flex-row justify-content-start p-x-15 w-100 align-self-start logo-container-signup">
                        <Link to="/" onClick={clickLogo}>
                            <Logo width={"63%"} className="logo-height" />
                        </Link>
                    </div>
                    <div className="mt-45 w-100 carousel-signup-component">
                        <CarouselComponent />
                    </div>

                    {success && <h1 className="text-24 font-weight-6 text-center">"Fasten your seatbelt and get <br /> ready for the ride."</h1>}
                    {!success && <>
                        <div className="testimonial-section d-flex flex-row justify-content-center align-items-center p-4 rounded-3">
                            <img className="testimonial-avatar rounded-circle me-3" src={Avatar} alt="Rajasekaran Arul" width="80" height="80" />
                            <div className="w-60 p-0">
                                <p className="text-12 font-1 align-self-start m-l-5 col-gray-900">
                                    <span className="font-weight-5">Amazing Product with new user Interface -</span> Walkzero has revolutionized the way we handle HR tasks, simplifying complexities and significantly reducing time investments.
                                </p>
                                <p className="text-12 font-1 align-self-start m-l-5 col-gray-900">Rajasekaran Arul</p>
                                <p className="text-muted text-12 font-1 align-self-start m-l-5 col-gray-900">GM / Page Industries</p>
                            </div>
                        </div>
                    </>}

                </div>

                <div className="center-certificates d-flex justify-content-center align-items-center">
                    <div className="d-flex flex-column p-2 bg-body inner-certificates">
                        <Soc className="certificate-icons" />
                        <Iso className="certificate-icons" />
                        <Gdpr className="certificate-icons" />
                    </div>
                </div>

                <div style={{ justifyContent: success ? "center" : "start" }} className="position-relative sign-up-second-cont d-flex flex-column p-t-30 p-b-10">
                    {success && <div className="msg-cont d-flex flex-column align-items-center position-relative">
                        <h1 className="col-container-font text-24 mb-0 m-t-12 text-center"> Thank you for choosing to <span className='col-secondary'>partner with us!</span></h1>
<p className="text-14 font-weight-4 font-1 col-copyrights-clr text-center m-t-12 m-b-0"> We will review your submission and get in touch with you shortly.</p>
<p className="font-1 font-weight-6 text-18 col-container-font m-t-10">Let's build successful and meaningful collaboration!</p>
 <Star className="position-absolute msg-big-star" />
                        <Star style={{ width: 20, height: 20 }} className="position-absolute msg-small-star" />
                    </div>}

                    {success && <div className="position-absolute success-download-container">
                        <div className='d-flex flex-row justify-content-center align-items-center w-100 new-download-container gap-3'>
                            <Android />
                            <Apple />
                        </div>
                    </div>}



                    {!success && <form onSubmit={submitForm} className="d-flex flex-column align-items-center signup-form-cont align-self-center position-relative" noValidate>
                        <h2 className="col-container-font text-22 align-self-center now">Partner  With Us <span className='col-secondary'>Lets Grow Together</span></h2>
                        <TextField
                            className={`inputs ${fullName && 'has-value'}`}
                            sx={{ mt: 1.5 }}
                            required
                            id="outlined-basic"
                            label="Full Name"
                            variant="outlined"
                            size="small"
                            type="text"
                            value={fullName}
                            onChange={changeFullName}
                            onBlur={checkFullName}
                            error={fullNameRequired ? true : false}
                        />
                        <span
                            style={{ opacity: fullNameRequired ? 1 : 0 }}
                            className="text-12 font-1 align-self-start m-l-25 col-error msg-height"
                        >
                            {fullNameErMsg}
                        </span>
                        <TextField className={`inputs ${designation && 'has-value'}`} sx={{ mt: 1.5 }} required id="outlined-basic" label="Role/Designation" variant="outlined" size="small" type="text" value={designation} onChange={changeDesignation} onBlur={checkDesignation} error={designationRequired ? true : false} />
                        <span style={{ opacity: designationRequired ? 1 : 0 }} className="text-12 font-1 align-self-start m-l-25 col-error msg-height">{designationErMsg}</span>
                        <TextField
    className={`inputs ${workEmailState && 'has-value'}`}
    sx={{ mt: 1.5 }}
    required
    id="outlined-basic"
    label="Work Email"
    variant="outlined"
    size="small"
    type="email"
    value={workEmailState}
    onChange={(e) => setWorkEmailState(e.target.value)}
    onBlur={checkWorkEmail}  // Ensure to trigger validation on blur
    error={workEmailRequired}  // Show error if validation fails
/>
<span
    style={{ opacity: workEmailRequired ? 1 : 0 }} // Show error message when required
    className="text-12 font-1 align-self-start m-l-25 col-error msg-height"
>
    {workEmailErMsg}
</span>



                        <FormControl className="" error={!!contactNumberRequired} sx={{ mt: 1.5, width: '100%' }}>
                            <div className={`phone-input-container  ${focused || contactNumber ? 'focused' : ''} ${contactNumber ? 'filled' : ''} ${contactNumberRequired ? 'error' : ''} ${contactNumber && 'has-value'}`} style={{ width: '100%' }}>
                                <label htmlFor="contact-input" className="ml-5 pl-10">Contact Number *</label>
                                <PhoneInput
                                    country={'in'}
                                    value={contactNumber}
                                    onChange={changeContactNumber}  // Use updated handler
                                    onBlur={checkContactNumber}
                                    onFocus={showArrow}
                                    placeholder=""
                                    inputProps={{
                                        id: 'contact-input',
                                        required: true,
                                    }}
                                    containerClass={contactNumberRequired ? 'phone-input-error' : ''}
                                    containerStyle={{ width: '100%' }}
                                    inputStyle={{ width: '100%' }}
                                />

                                <span style={{ opacity: contactNumberRequired ? 1 : 0 }} className="text-12 font-1 align-self-start m-l-25 col-error msg-height">{contactNumberErMsg}</span>
                            </div>
                        </FormControl>

                        <TextField
                            className={`inputs ${whatDoYouNeed && 'has-value'}`}
                            sx={{ mt: 1.5 }}
                            required
                            id="outlined-basic"
                            label="Why Partner With Us?"
                            variant="outlined"
                            size="medium"  // Larger text field
                            type="text"
                            value={whatDoYouNeed}
                            onChange={changeWhatDoYouNeed}
                            onBlur={checkWhatDoYouNeed}
                            error={whatDoYouNeedRequired ? true : false}
                            multiline
                            minRows={2}
                          
                        />
                        <span
                            style={{ opacity: whatDoYouNeedRequired ? 1 : 0 }}
                            className="mb-2 text-12 font-1 align-self-start m-l-25 col-error msg-height"
                        >
                            {whatDoYouNeedErMsg}
                        </span>



                        <BaseBtn isOn={isOn} type="submit" className='btn-4 rounded-9 text-16 font-weight-6 demo-btn w-px-150 align-self-center m-t-15 m-b-10'>Get Started</BaseBtn>

                        {isLoading && <div className="align-self-center"><TailSpin visible={true} height="30" width="30" color="#f45600" ariaLabel="tail-spin-loading" radius="1" /></div>}
                        <Star className="position-absolute big-star" />
                        <Star style={{ width: 20, height: 20 }} className="position-absolute small-star" />
                    </form>}
                    {!success && <
                        div className="d-flex flex-column justify-content-center align-items-center"
                    >
                        <div className='d-flex flex-row justify-content-center align-items-center mb-18  w-100 autoplay-carousel'>
                            <AutoPlay data={data} settings={settings} />

                        </div>
                       <div className="d-flex gap-3 ">
                       <div className="mr-5">
                         <Android />
                       </div>
                       <div>
                         <Apple />
                       </div>
                     </div>
                     
                     
                    </div>

                    }
                </div>
            </div>
        </>

    );
}

export default FormGrow;
