import "./index.scss"
import { Link, useParams } from "react-router-dom"
import { motion } from 'framer-motion';
import {ReactComponent as Logo} from "../../../assets/logos/logo.svg"
import {ReactComponent as Star} from '../../../assets/logos/star.svg'
import {ReactComponent as  Apple} from '../../../assets/logos/apple.svg';
import {ReactComponent as Android} from '../../../assets/logos/android.svg';
import {ReactComponent as TopDesign} from '../../../assets/logos/top-signup-design.svg';
import {ReactComponent as BottomDesign} from '../../../assets/logos/bottom-signup-design.svg';
import {ReactComponent as Soc} from '../../../assets/logos/soc.svg';
import {ReactComponent as Iso} from '../../../assets/logos/iso.svg';
import {ReactComponent as Gdpr} from '../../../assets/logos/gdpr.svg';
import Avatar from "../../../assets/images/avatar.png"
import { data2 } from "../../product-menu/product-menu";
import HeroSection from "../../payroll/banner-components/banner-main"
import { useContext, useEffect, useState } from "react";
import { MenuContext } from "../../../app";
import { VERIFY_ACCOUNT } from "../../../graphql/queries"
import { TailSpin } from "react-loader-spinner"

const url = `${process.env.REACT_APP_WZ_URL}`;

const status = {
    initial:"Initial",
    inProgress:"Progress",
    success:"Success",
    failed:"Failed"
}

const AccountVerified = () => {

    const {setIsOpen, setIsProductOpen, setModules} = useContext(MenuContext)
    const params = useParams()
    const token = params.id

    const [isVerified, setVerify] = useState(false)
    const [msg, setMsg] = useState('')
    const [progress, setProgress] = useState(status.initial)

    const clickLogo = () => {
        setIsOpen(false)
        setIsProductOpen(false)
        setModules(data2)
    }


    useEffect(() => {
        const verifyAccount = async() => {
            setProgress(status.inProgress)
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                    query: VERIFY_ACCOUNT,
                    variables: { token },
                    }),
                })
    
                if (response.ok) {
                    
                    const res = await response.json();
                    const {data, errors} = res
                    if(data){
                        setProgress(status.success)
                        console.log(data)
                    }
                    if(errors){
                        setProgress(status.success)
                        setVerify(true)
                        setMsg(errors[0].token[0])
                    }
                    return res;
                }
            }
            catch(error:any){
                console.error(`error in verify account: ${error.message}`)
            }    
        }
        verifyAccount()
    }, [token])


    return(
        <>
        <div  className="w-100 signup-flex-container d-flex flex-row align-items-start">
        <div  className="d-flex flex-column justify-content-start align-items-center  sign-up-first-cont  p-b-10 bg-col-container-bg">
              <div className="top-design">
                <TopDesign className="top-design-svg" />
              </div>
              <div className="bottom-design">
                <BottomDesign className="bottom-design-svg" />
              </div>
              <div className="d-flex flex-row justify-content-start p-x-15  w-100 align-self-start logo-container-signup">
                <Link to="/" onClick={clickLogo}>
                  <Logo width={"63%"} className="logo-height" />
                </Link>
              </div>
              <div>
                <div style={{ marginTop: '100px' }} className="d-flex flex-column justify-content-center align-items-center">
                  <h4 className="d-flex align-items-center text-12"> Payroll  <span className="col-secondary ms-1">  Management</span> </h4>
                  <h4 className="d-flex text-12 font-1 align-items-center m-l-5 col-gray-900 text-center">Simplified Payroll with Walkzero:</h4>
                  <h4 className="d-flex text-12 font-1 align-items-center m-l-5 col-gray-900 text-center">Smarter, Faster, Easier</h4>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <motion.div className="z-index-1 w-90 d-flex justify-content-center align-items-center" initial={{ opacity: 0 }} animate={{ opacity: [0, 0.2, 0.3, 0.4, 0.6, 0.8, 0.9, 1] }} transition={{ duration: 2, delay: 1, ease: 'easeOut' }}>
                    <HeroSection className='w-100 z-index-1' />
                  </motion.div>
                </div>
              </div>
              <div className="testimonial-section d-flex flex-row justify-content-center align-items-center p-4  rounded-3">
                <img className="testimonial-avatar rounded-circle me-3" src={Avatar} alt="Aravinthan Menon" width="80" height="80" />
                <div className="w-60  p-0">
                  <p className="text-12 font-1 align-self-start m-l-5 col-gray-900">
                    <span className="font-weight-5">Amazing Product with new user Interface -</span> Walkzero has revolutionized the way we handle HR tasks, simplifying complexities and significantly reducing time investments.
                  </p>
                  <p className="text-12 font-1 align-self-start m-l-5 col-gray-900">Rajasekaran Arul</p>
                  <p className="text-muted text-12 font-1 align-self-start m-l-5 col-gray-900">GM / Page Industries</p>
                </div>
              </div>
            </div>
            <div className="center-certificates d-flex justify-content-center align-items-center">
                <div className="d-flex flex-column  p-2 bg-body inner-certificates">
                  <Soc className="certificate-icons"/>
                  <Iso className="certificate-icons"/>
                  <Gdpr className="certificate-icons"/>
                </div>
            </div>
            <div style={{justifyContent:"center"}} className="position-relative sign-up-second-cont1 bg-white d-flex flex-column p-t-30 p-b-10">
            <div style={{ position: 'absolute', top: '20px', right: '20px' }} className="back-to-home">
                    <h4 className="text-12 font-1  m-l-5 col-gray-900">Back to <Link style={{textDecoration:"none"}} className="col-secondary ms-1" to='/'> Home</Link></h4>
                  </div>  
                {progress === status.inProgress  && <div className="align-self-center"><TailSpin  visible={true} height="30" width="30" color="#f45600" ariaLabel="tail-spin-loading" radius="1" /></div>}
                {progress === status.success &&<>
                    {isVerified &&<div className="msg-cont d-flex flex-column align-items-center position-relative">
                    <h2 className="col-secondary text-22 mb-0 align-self-center m-t-10 text-center">{msg}</h2>
                </div>} 
                {!isVerified && <><div className="d-flex flex-column align-items-center signup-form-cont align-self-center position-relative">
                    
                    <h2 className="col-container-font text-22 mb-0 m-t-10">Your account is verified <span className='col-secondary'>successfully</span></h2>
                    <p className="text-13 font-weight-4 font-1 col-copyrights-clr m-t-10 m-b-0 text-center">We’re working on your account activation. You'll be notified to your register email within 24 hrs</p>
                    <p className="font-1 font-weight-6 text-20 col-container-font m-t-10">Thank you for choosing walkzero</p>
                    <Star className="position-absolute top--60 right--30" />
                    <Star style={{width:20, height:20}} className="position-absolute top--30 right--40" />
                </div></>}
                <div style={{position:"absolute", bottom:30, left:0, right:0}} className='d-flex flex-row justify-content-center align-items-center w-100 mt-70 new-download-container gap-3'>
           <Apple />
           <Android/>
          </div>
                </>}
                {isVerified && <>
                <Star className="position-absolute top-60 right-50" />
                <Star style={{width:20, height:20}} className="position-absolute top-90 right-80" /></>}
            </div>
        </div> 
        </>
    )
}

export default AccountVerified