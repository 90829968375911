import { useNavigate } from "react-router-dom";
import {ReactComponent as Login} from "../../assets/logos/walkzero-logIn.svg"
import {ReactComponent as Logo} from "../../assets/logos/logo.svg"
import {ReactComponent as W} from "../../assets/logos/w-login.svg"
import {ReactComponent as Right} from "../../assets/logos/login-right.svg"
import {ReactComponent as  Apple} from '../../assets/logos/apple.svg';
import {ReactComponent as Android} from '../../assets/logos/android.svg';
import {ReactComponent as Soc} from '../../assets/logos/soc.svg';
import {ReactComponent as Iso} from '../../assets/logos/iso.svg';
import {ReactComponent as Gdpr} from '../../assets/logos/gdpr.svg';
import {ReactComponent as TopDesign} from '../../assets/logos/top-signup-design.svg';
import {ReactComponent as BottomDesign} from '../../assets/logos/bottom-signup-design.svg';
// import {TextField}  from '@mui/material';
import { TextField, FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {Link} from "react-router-dom"
import { useState, useEffect , useContext} from "react";
import Carousel from "../signup/carousel-component";
import Avatar from "../../assets/images/avatar.png"
import CryptoJS from "crypto-js"
import './index.scss';
import { DOMAIN_LOGIN ,LOGIN_MUTATION,FORGOT_PASSWORD,SET_PASSWORD} from "../../graphql/queries";
import { MenuContext } from "../../app";
import { data2 } from "../product-menu/product-menu";
import AutoPlay from "../layout/industry-slide";
import { data, settings } from "../landing-page/home";
import {ReactComponent as Star} from '../../assets/logos/star.svg'


// let domains: Record<string, string>[] = [];



const LogIn = ()=> {
    

    const navigate = useNavigate();

    const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const [email, setEmail] = useState("")
    const [err, setErr] = useState("")
    const [isError, setIsError] = useState(false)
    const [isButton, setIsButton] = useState(true)
    const [showDomain, setShowDomain] = useState(false)
    const [domains, setDomains] = useState<any[]>([])
    const [selectedDomain, setSelectedDomain] = useState("")
    const [domainId, setDomainId] = useState("")
    const[password,setPassword]=useState("");
    const[url,checkUrl]=useState("");
    const[isChangePassword,setIsChangePassword]=useState(false)
    const[resetId,setResetId]=useState<string | null>(null);
    const [resetToken, setResetToken] = useState<string | null>(null);
    const [confirmPassword,setConfirmPassword]=useState<string | null>(null);
    // const [isPasswordReset, setIsPasswordReset] = useState(false);

     const {setIsOpen, isOn, setIsProductOpen, setModules} = useContext(MenuContext)

     const [showPassword, setShowPassword] = useState(false);

     const handleClickShowPassword = () => {
       setShowPassword((prev) => !prev);
     };
     const handleMouseDownPassword = (event: { preventDefault: () => void; }) => {
        event.preventDefault(); // Prevents focus loss when clicking the icon
      };
    interface EmailInput{
        email:string
    }
  let isPasswordReset: boolean=false;
    // useEffect(() => {
    //     if (domains.length === 1) {
    //         setShowDomain(false)
    //         const token = encodeURIComponent(
    //             CryptoJS.AES.encrypt(email+";"+domainId,`${process.env.REACT_APP_SECRETKEY}`).toString()
    //         );
    //         console.log(token,"stored token");
    //         // https://zealzoft.walkzero.com/
    //         // window.location.href = `${domains[0].url}/login?token=${token}`;
    //     } 
    //     else if (domains.length > 1) {
    //         setShowDomain(true)
    //     }
    // }, [domains, email, domainId,selectedDomain])
    // useEffect(()=>{

    // },[isPasswordReset])

    const checkDomain = async(input:EmailInput) =>{
        const url = `${process.env.REACT_APP_WZ_URL}`
        const response = await fetch(url,{
            method: 'POST', 
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                query: DOMAIN_LOGIN,
                variables: {input},
            })
        })
        if(response.ok){
    
            const res = await response.json();
            const {data, errors} = res
            if(data){
                // domains = data.preLogin.domains
                setDomains(data.preLogin.domains)
                setDomainId(data.preLogin.id)
            }
            if(errors){
                setIsError(true)
                if(errors[0].email){
                    setErr(errors[0].email[0])
                }else{
                    setErr(errors[0].Enter[0])
                }
            }
        }
    }
   
    const workEmail = (event:React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
        const mail = event.target.value

        if (mail === ""){
            setIsButton(true)
            setIsError(true)
            setErr("This field is required.")
        }
        else if(pattern.test(mail)){
            setIsButton(false)
            setIsError(false)
        }
        else{
            setIsButton(true)
            setIsError(true)
            setErr("Enter a valid email address.")
        }
    }
    

    const logIn:React.FormEventHandler<HTMLFormElement> =(events)=>{
        events.preventDefault();
        if(isError){
            setShowDomain(false)
        }
        else if(!domains){
            const input = {"email":email}
            checkDomain(input)
        }
        else{
           
            const input = {"email":email}
            checkDomain(input)
            setShowDomain(true)
        }    
    }
                    
    const handleOptions = (event:any) =>{
        setSelectedDomain(event.target.value)
        setShowDomain(false)

    }
 
    const logInWithPassword: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();

        const input = { email: email, password:password };
        const url = `${process.env.REACT_APP_WZ_URL}`
        const response = await fetch(url,{
            method: 'POST', 
            headers: {
                'content-type': 'application/json',
                'x-account-id': domainId,
            },
            body: JSON.stringify({
                query:LOGIN_MUTATION ,
                variables: {input},
            })
        })
        if(response.ok){
            const res = await response.json();
            const {data, errors} = res
            if(data){
                console.log(data,"auth data")
                localStorage.setItem("authToken", JSON.stringify(data));
                let subDomainUrl;
                if(selectedDomain){
                    subDomainUrl= selectedDomain
                }
               else{
                subDomainUrl=  domains[0].url
               }
                // let subDomainWindow= window.open(subDomainUrl,'_self');

                // if(subDomainUrl){
                //     subDomainWindow?.postMessage({token:data},subDomainUrl);
                // }
               
              console.log(subDomainUrl,"url");
              const dataToEncrypt = JSON.stringify({ data, domainId });
              const encryptedData = CryptoJS.AES.encrypt(dataToEncrypt,`${process.env.REACT_APP_SECRETKEY}`).toString();
              const encryptedToken = encodeURIComponent(encryptedData);
            
              window.location.href = `${subDomainUrl}?token=${encryptedToken}`;
            // window.open(`${subDomainUrl}?token=${encryptedToken}`, "_blank");

            }
            if(errors){
                
                    setIsError(true)
                    setErr(errors[0].error[0]);
                }
        }
      
    };
    
        const clickLogo = () => {
            setIsOpen(false)
            setIsProductOpen(false)
            setModules(data2)
        }
        
        const handleForgetPassword: React.MouseEventHandler<HTMLParagraphElement>  = async (event) =>{
            event.preventDefault();
            const input = { email: email};
            const url = `${process.env.REACT_APP_WZ_URL}`
            const response = await fetch(url,{
                method: 'POST', 
                headers: {
                    'content-type': 'application/json',
                    'x-account-id': domainId,
                },
                body: JSON.stringify({
                    query:FORGOT_PASSWORD ,
                    variables: {input},
                })
            })
            if(response.ok){
                const res = await response.json();
                const {data, errors} = res
                if(data && data.forgotPassword){
                    setIsChangePassword(true)
                    const { id, token } = data.forgotPassword;
                    setResetId(id);
                    setResetToken(token);
                
       
                }
                if (errors && errors.length > 0) {
                    setIsError(true);
        
                    if (errors[0].email && errors[0].email.length > 0) {
                        setErr(errors[0].email[0]); 
                    } 
                }
            }
        }
   const handleResetPassword: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    const input = { password:password,confirm_password:confirmPassword,uid:resetId,token:resetToken};
    const url = `${process.env.REACT_APP_WZ_URL}`
    const response = await fetch(url,{
        method: 'POST', 
        headers: {
            'content-type': 'application/json',
            'x-account-id': domainId,
        },
        body: JSON.stringify({
            query:SET_PASSWORD ,
            variables: {input},
        })
    })
    if(response.ok){
        const res = await response.json();
        const {data, errors} = res
        if(data){
            console.log(data,"success password change")
            localStorage.setItem("authToken", JSON.stringify(data));
                let subDomainUrl;
                if(selectedDomain){
                    subDomainUrl= selectedDomain
                }
               else{
                subDomainUrl=  domains[0].url
               }
                // let subDomainWindow= window.open(subDomainUrl,'_self');

                // if(subDomainUrl){
                //     subDomainWindow?.postMessage({token:data},subDomainUrl);
                // }
        // }
        //    window.location.href = `${subDomainUrl}?token=${encodeURIComponent(data)}`;
        window.location.href = `${subDomainUrl}?token=${encodeURIComponent(data)}&domainId=${encodeURIComponent(domainId)}`;
            }

        if (errors && errors.length > 0) {
            setIsError(true);

            if (errors[0].error && errors[0].error.length > 0) {
                setErr(errors[0].error[0]); 
            } 
        }
    }
   }
    return(
        <div className="signup-flex-container d-flex flex-row align-items-start">
        <div className="d-flex flex-column justify-content-start align-items-center bg-col-white sign-up-first-cont p-b-10 bg-col-container-bg">
            <div className="top-design">
                <TopDesign className="top-design-svg" />
            </div>
            <div className="bottom-design" style={{ zIndex: 0 }}>
                                <BottomDesign className="bottom-design-svg" />
            </div>
            <div className="d-flex flex-row justify-content-start p-x-15 w-100 align-self-start logo-container-signup">
                <Link to="/" onClick={clickLogo}>
                    <Logo width={"63%"} className="logo-height" />
                </Link>
            </div>
            <div className="w-100 carousel-book-component">
                <Carousel />
            </div>
    
            <div className="testimonial-section d-flex flex-row justify-content-center align-items-center p-4 rounded-3">
                <img className="testimonial-avatar rounded-circle me-3" src={Avatar} alt="Rajasekaran Arul" width="80" height="80" />
                <div className="w-60 p-0">
                    <p className="text-12 font-1 align-self-start m-l-5 col-gray-900">
                        <span className="font-weight-5">Amazing Product with new user Interface -</span> Walkzero has revolutionized the way we handle HR tasks, simplifying complexities and significantly reducing time investments.
                    </p>
                    <p className="text-12 font-1 align-self-start m-l-5 col-gray-900">Rajasekaran Arul</p>
                    <p className="text-muted text-12 font-1 align-self-start m-l-5 col-gray-900">GM / Page Industries</p>
                </div>
            </div>
        </div>
    
        <div className="center-certificates d-flex justify-content-center align-items-center">
            <div className="d-flex flex-column p-2 bg-body inner-certificates">
                <Soc className="certificate-icons" />
                <Iso className="certificate-icons" />
                <Gdpr className="certificate-icons" />
            </div>
        </div>
        {isChangePassword ? (
    <div style={{ justifyContent: "center", alignItems: "center" }} className="position-relative sign-up-second-cont d-flex flex-column p-t-30 p-b-10">
        <div className="position-absolute top-30 right-30">
            <h4 className="text-12 font-1 align-self-start m-l-5 col-gray-900 d-flex justify-content-end align-items-end">
            Don't have an account?
                <Link style={{ textDecoration: "none" }} className="col-secondary ms-1" to='/sign-up'> Get Started</Link>
            </h4>
        </div>

        <Star className="big-star-reset" />
        <Star style={{ width: 20, height: 20 }} className="small-star-reset" />
        <p className="text-24 col-secondary font-weight-6">Reset Your Password</p>

        <form onSubmit={handleResetPassword} className="login-form" noValidate>
            <TextField 
                required 
                className="inputs w-100" 
                margin="normal" 
                label="New Password" 
                variant="outlined" 
                size="small" 
                type="password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)
                }
            />

            <TextField 
                required 
                className="inputs w-100" 
                margin="normal" 
                label="Confirm Password" 
                variant="outlined" 
                size="small" 
                type="password" 
                value={confirmPassword} 
                onChange={(e) => setConfirmPassword(e.target.value)} 
            />

            {isError && (
                <span style={{ opacity: isError ? 1 : 0 }} className="d-flex text-12 font-1 align-self-start col-error msg-height-login m-l-10 m-t--5">
                    {err}
                </span>
            )}

            <button style={{backgroundColor:"#F45600"}} type="submit" className="login-button w-100 text-14">Change Password</button>
        </form>
        
        <div className="position-absolute not-success-download-container">
                <div className='d-flex flex-row justify-content-center align-items-center mb-50  autoplay-carousel'>
                    <AutoPlay data={data} settings={settings} />
                </div>
                    <div className='d-flex flex-row justify-content-center align-items-center w-100 new-download-container gap-3'>
                        <div className="mr-5">
                            <Android />
                        </div>
                        <div>
                            <Apple />
                        </div>
                    </div>
                </div>
    </div>
) :showDomain && domains.length > 1 ? (
            <div style={{ justifyContent: "center", alignItems: "center" }} className="position-relative sign-up-second-cont d-flex flex-column p-t-30 p-b-10">
                 <div className="position-absolute top-30 right-30">
                      <h4  className="text-12 font-1 align-self-start m-l-5 col-gray-900 d-flex justify-content-end align-items-end">Don't have an account? <Link style={{textDecoration:"none"}} className="col-secondary ms-1" to='/sign-up'> Get Started</Link></h4>
                    </div>
                    <Star className="big-star-domain" />
                    <Star style={{ width: 20, height: 20 }} className="small-star-domain" />
                    <div className="center-login-container">
                    <p className="text-24 col-primary font-weight-6 login-font-domain">

<span className="col-secondary">Login</span> Into <span className="col-secondary">your</span> account
</p>

<form style={{display:'flex',flexDirection:'column', justifyContent: "center"}}  className="login-form pt-0" noValidate>
{/* <div className="d-flex flex-column justify-content-center align-items-center"><Logo /></div>
<p className="text-center text-13 name">
    Login as {email} <span className="col-secondary change" onClick={() => { setShowDomain(false); setDomains([]); setDomainId(""); setEmail(""); setIsButton(true); setSelectedDomain("") }}>change</span>
</p> */}
<div className="d-flex flex-row justify-content-between align-items-center mb-10">
    <hr className="line-log w-55" />
    <p className="text-12 text-center p-0 m-0 continue">Continue with</p>
    <hr className="line-log w-55" />
</div>
<ul className="p-0">
    {domains.map((each, i) =>
        <li className="domain-list" key={i}>
            <label className="radio-field-url" htmlFor={each['url']}>
                <input className="m-r-5 radio" type="radio" value={each['url']} name="Domain" checked={selectedDomain === each['url']} onChange={handleOptions} id={each['url']} />
                {each['url']}
            </label>
        </li>
    )}
</ul>
<div className="d-flex justify-content-center align-items-center">
<button type="button" disabled={isButton} style={{ backgroundColor: isOn ? isButton ? "#1A2D483A" : "#1A2D48" : isButton ? "#F456003A" : "#F45600" }} className="login-button w-100 text-14">Continue</button>
</div>

<p className="text-center text-12 support">
    Having problem in login please reach out <span className="col-secondary">
        <a href="mailto:support@walkzero.com?Subject=Hello%20" target="_top" className="col-secondary change">Support Team</a>
    </span>
</p>
</form>
                        </div>
            
              
                <div className="position-absolute  not-success-login-download-container w-100">
                <div className='d-flex flex-row justify-content-center align-items-center mb-50 w-100 autoplay-carousel'>
                    <AutoPlay data={data} settings={settings} />
                </div>
                    <div className='d-flex flex-row justify-content-center align-items-center w-100 new-download-container gap-3'>
                        <div className="mr-5">
                            <Android />
                        </div>
                        <div>
                            <Apple />
                        </div>
                    </div>
                </div>
            </div>
        ) :( domains.length > 0  || selectedDomain ||isPasswordReset )?  (
            <div style={{ justifyContent: 'center', alignItems: 'center' }} className="position-relative sign-up-second-cont d-flex flex-column p-t-30 p-b-10">
                 <div className="position-absolute top-30 right-30">
                      <h4  className="text-12 font-1 align-self-start m-l-5 col-gray-900 d-flex justify-content-end align-items-end">Don't have an account? <Link style={{textDecoration:"none"}} className="col-secondary ms-1" to='/sign-up'>Get Started</Link></h4>
                    </div>
            <Star className="big-star-password" />
            <Star style={{ width: 20, height: 20 }} className="small-star-password" />
            <div className="center-login-container">
            <p className="text-24 col-secondary font-weight-6"> Login to Continue</p>
            <form style={{display:'flex',flexDirection:'column', justifyContent: "center", alignItems: "center"}} onSubmit={logInWithPassword} className="login-form pt-0 pb-0 mb-10" noValidate>
                <TextField required className="inputs w-100" margin="normal" label="Email" variant="outlined" size="small" type="email" value={email} disabled />
                {/* <TextField required className="inputs w-100" margin="normal" label="Password" variant="outlined" size="small" type="password" value={password} onChange={(e) => setPassword(e.target.value)} /> */}
                <FormControl className="inputs w-100" margin="normal" variant="outlined" size="small">
      <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility />:<VisibilityOff /> }
            </IconButton>
          </InputAdornment>
        }
        label="Password"
      />
    </FormControl>
                {isError && <span style={{ opacity: isError ? 1 : 0 }} className="d-flex text-12 font-1 align-self-start col-error msg-height-login m-l-10 m-t--5">{err}</span>}

                {/* <p style={{cursor:'pointer'}} className="text-14 col-secondary font-weight-3 ml-8" onClick={handleForgetPassword}>Forget Password</p> */}
              
                <button type="submit" style={{backgroundColor:"#F45600"}}  className="login-button  mt-15 w-100 text-14 w-px-150">Login</button>
            </form>
        
                </div>
              
                <div className="position-absolute not-success-login-download-container w-100">
                <div className='d-flex flex-row justify-content-center align-items-center mb-50   autoplay-carousel'>
                    <AutoPlay data={data} settings={settings} />
                </div>
                    <div className='d-flex flex-row justify-content-center align-items-center w-100 new-download-container gap-3'>
                        <div className="mr-5">
                            <Android />
                        </div>
                        <div>
                            <Apple />
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div style={{ justifyContent: 'center', alignItems: 'center' }} className="position-relative sign-up-second-cont d-flex flex-column p-t-30 p-b-10">
                       <div className="position-absolute top-30 right-30">
                      <h4  className="text-12 font-1 align-self-start m-l-5 col-gray-900 d-flex justify-content-end align-items-end">Don't have an account? <Link style={{textDecoration:"none"}} className="col-secondary ms-1" to='/sign-up'>Get Started</Link></h4>
                    </div>
                <Star className="big-star-login" />
                <Star style={{ width: 20, height: 20 }} className="small-star-login" />
                <div className="center-login-container">
                <p className="text-24 col-secondary font-weight-6 p-0 m-0"> Login to Continue</p>
                <form style={{display:'flex',flexDirection:'column', justifyContent: "center", alignItems: "center",padding:0 }} onSubmit={logIn} className="login-form" noValidate>
                    <TextField required className="inputs w-100" margin="normal" label="Work Email" variant="outlined" size="small" type="email" value={email} onChange={workEmail} error={isError ? true : false} />
                    {isError && <span style={{ opacity: isError ? 1 : 0 }} className="d-flex text-12 font-1 align-self-start col-error msg-height-login m-l-10 m-t--5">{err}</span>}
                    <button type="submit" className="login-button  w-100 text-14 w-px-150 mt-3" disabled={isButton} style={{ backgroundColor: isOn ? isButton ? "#1A2D483A" : "#1A2D48" : isButton ? "#F456003A" : "#F45600" ,
            }}>Continue</button>
                    <p className="text-center text-12 support">Having problem in login please reach out <span className="col-secondary"><a href="mailto:support@walkzero.com?Subject=Hello%20" target="_top" className="col-secondary change">Support Team</a></span></p>
                </form>
                
                </div>
             
                <div className="position-absolute not-success-login-download-container w-100">
              
                <div className='d-flex flex-row justify-content-center align-items-center mb-50  autoplay-carousel'>
                <AutoPlay data={data} settings={settings} />
                    </div>
                        <div className='d-flex flex-row justify-content-center align-items-center w-100 new-download-container gap-3'>
                            <div className="mr-5">
                                <Android />
                            </div>
                            <div>
                                <Apple />
                            </div>
                        </div>
                    </div>
    
               
            </div>
        )}
    </div>
    
    )
}
export default LogIn



