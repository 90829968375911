import './index.scss'
import { useState, useEffect } from 'react'
import { TailSpin } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { child1, child2 } from '../../../variants';
import {motion} from "framer-motion"

type BlogData = {
    id: string
    title: string 
    short_description: any 
    photo: string
    slug: string
}

const initialData = {
    id: '',
    title: '',
    short_description: '',
    photo: '',
    slug: ''
}


const TopBlog = () => {

    const [data, setData] = useState<BlogData>(initialData);
    const [isLoading, setIsLoading] = useState(true)

    const getBlog = async() => {
        setIsLoading(true)
        const url = `${process.env.REACT_APP_API_URL}/blogs/top-blog`;
        const options = {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            }
        }

        try{
            const response = await fetch(url, options)
            if(response.ok){
                setIsLoading(false)
                const blog = await response.json()
                setData(blog)
            }
        }
        catch(error:any){
            console.error(`error in fetching blog: ${error.message}`)
        }
    }

    useEffect(() => {
        getBlog();
    }, [])

    const {title, short_description, photo, slug} = data

    return(
        <>
        {isLoading===false ? <Link to={`/blog/${slug}`} className='d-flex flex-row justify-content-between align-items-center top-blog-cont align-self-center decoration'>
            <motion.img variants={child1} initial="hidden" animate="visible" src={photo} alt="blog" className='top-blog-img' />
            <motion.div variants={child2} initial="hidden" animate="visible" className='tob-blog-content'>
                <h1 className='font-weight-6 h2-size col-head-clr'>{title}</h1>
                <p className='font text-12 font-weight-3 p-size'>{short_description}</p>
                <div className='d-flex flex-row justify-content-between align-items-center'>
                    <p className='font text-12 font-weight-3 p-size'>2 mins to Read..</p>
                    <span className='font-1 text-12 font-weight-6 col-secondary decoration'>Read More...</span>
                </div>
            </motion.div>
        </Link> : <div className="align-self-center m-t-100 m-b-100"><TailSpin  visible={true} height="30" width="30" color="#f45600" ariaLabel="tail-spin-loading" radius="1" /></div>}
        </>
    )
}

export default TopBlog