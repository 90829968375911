import "./index.scss"
import { ReactComponent as ButtonLogo } from "../../../assets/logos/button-logo.svg";
import { ReactComponent as Left } from "../../../assets/logos/logo-left.svg"
import { ReactComponent as TopW } from "../../../assets/logos/top-w-curve.svg"
import { ReactComponent as BottomW } from "../../../assets/logos/bottom-w-curve.svg"
import { motion } from "framer-motion"
import { useState, useEffect } from "react"
import { Link } from 'react-router-dom'
import { BaseBtn} from "../../../styled-components"
import { MenuContext } from "../../../app"
import { useContext } from "react"
// import ProfilePoster as React Component
import { ReactComponent as ProfilePoster } from "../../../assets/images/profile_poster.svg"
// Assuming the union image is located in the 'src/assets/images/union.svg'
const Profile = () => {
 const props = useContext(MenuContext);
    const {isOn} = props
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setScrollPosition(prevPosition => prevPosition - 30);
        }, 2000);

        return () => clearInterval(interval);
    }, []);

    const childTop = {
        visible: {
            x: 0,
            y: 0,
            transition: {
                duration: 1,
                delay: 1,
                type: 'tween'
            }
        },
        hidden: {
            x: 0,
            y: -500,
        }
    }

    const childBottom = {
        visible: {
            x: 0,
            y: 0,
            transition: {
                duration: 1,
                delay: 1,
                type: 'tween'
            }
        },
        hidden: {
            x: 0,
            y: 210,
        }
    }

    const child2 = {
        visible: {
            x: 0,
            y: 0,
            rotate: 0,
            transition: {
                duration: 1.5,
                type: 'tween'
            }
        },
        hidden: {
            x: -1700,
            y: 350,
            rotate: 90,
        }
    }

    const buttonAnimation = {
      visible: {
        opacity: 1,
        transition: {
          delay: 3.4, // Delay for the button to appear after the text
          duration: 1,
          type: "tween",
          ease: "easeInOut",
        },
      },
      hidden: {
        opacity: 0,
      }
    };
  
    return (
        <motion.div className="position-relative bg-col-container-bg d-flex flex-column justify-content-center align-items-center w-100 pb-5">
            <div className="d-flex flex-row justify-content-center align-items-center align-self-center pt-50 pb-50 res">
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 1, type: "tween", ease: "easeInOut" } }} className="w-55 mt-4 first z-index-1">
                    <motion.div className="col-quaternary font-weight-5 text-12 m-b-8 text-uppercase cent topspace mb-1">#Join Us.Achieve More.Grow With Us.</motion.div>
                    <motion.h2 className="col-container-font m-b-5  h1-size">Partner with the Leading,</motion.h2>
                    <motion.h2 className="col-container-font mb-0 flow  h1-size"> <span className="col-secondary">HR Automation Platform </span></motion.h2>
                   <motion.p className="font font-weight-3 m-t-16 m-b-20 p-size">
                   Join the Walkzero Partner Network—where innovation meets<br/> opportunity. Expand your business, earn recurring revenue, and revolutionize<br/> HR operations with a leading automation platform.</motion.p>

                   {/* Add the image with z-index -10 and position it before the button */}
       
               <Link to="/partnership/get-started" className="decoration d-flex flex-row justify-content-center align-items-center  btn-together btn-cont">
               
               <BaseBtn isOn={isOn} 
  className="position-relative border-0 p-y-10 rounded-10 d-flex flex-row justify-content-center align-items-center w-100 btn-4"
>
  <ButtonLogo className="me-1 logo-align" />
  <span className="col-white font-bold text-14 font-weight-700 align-middle">Let's Grow Together</span>
</BaseBtn> </Link> </motion.div>

                <div className="d-flex flex-column justify-content-center align-items-center position-relative w-45 second">
                    <motion.div className="z-index-2" initial={{ opacity: 0 }} animate={{ opacity: [0, 0.2, 0.3, 0.4, 0.6, 0.8, 0.9, 1] }} transition={{ duration: 2, delay: 2, ease: 'easeOut' }}>
                        {/* Here we use ProfilePoster as React component */}
                        <ProfilePoster className='profimg' />
                    </motion.div>
                    <div className="position-absolute bgnd z-index-0 rounded-circle">
                    </div>
                    <motion.div variants={childTop} initial="hidden" animate="visible" className="position-absolute top-logo">
                        <TopW style={{ opacity: 0.7 }} />
                    </motion.div>
                    <motion.div variants={childBottom} initial="hidden" animate="visible" className="position-absolute p-0 bottom-logo">
                        <BottomW style={{ opacity: 0.7 }} />
                    </motion.div>
                    <motion.div variants={child2} initial="hidden" animate="visible" className="position-absolute p-0 z-index-0">
                        <Left style={{ opacity: 0.7 }} />
                    </motion.div>

                </div>
             
        <div className="custom-shape-divider-bottom-1740645462">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
          </svg>
        </div>

            </div>
        </motion.div>
    )
}

export default Profile
